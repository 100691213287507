import React, { Component } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Helmet } from "react-helmet";
import Footer from "../components/sections/Footer";
import Header from "../components/sections/Header";
import SimpleReactValidator from "simple-react-validator";
import { Link, Navigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import ContentLoader from "react-content-loader";
import {
  API_CREATE_PROJECT,
  API_GET_CATEGORIES,
  API_UPLOAD_IMAGE,
  API_VIEW_PROJECT,
  API_UPDATE_PROJECT,
  API_KYC,
  API_KYC_STATUS,
  API_USER_PROFILE,
} from "../config/endpoints";
import {
  getLocalData,
  getLocalDataObject,
  getUserData,
  setLocalDataObject,
} from "../helpers/Storage";
import { ShowToast } from "../helpers/Helpers";
import Autocomplete from "react-google-autocomplete";
import Files from "react-files";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const userData = getUserData();
const defaultArtist = getLocalDataObject("defaultArtist");
let kycInterval = 0;
let localUserData = { user: {} };
const initialState = {
  redirect: false,
  fields: {
    name: "",
    description: "",
    short_description: "",
    category: "",
    tags: "",
    video: "",
    campaign_end_method: "",
    start_date: "",
    end_date: "",
    min_amount: "",
    max_amount: "",
    target_goal: "",
    predefined_amounts: "",
    contributor_anonymous: "",
    datesStartEnd: [],
    lat: "",
    lng: "",
    address: "",
    selected_categories: "",
    street_address: "",
    city: "",
    state: "",
    zip: "",
    artist_ids: defaultArtist ? [defaultArtist.id] : null,
    event_type: "project",
    media_id: "",
  },
  uploadFile: false,
  filePreview: false,
  userProfile: userData.userProfile,
  isLoggedIn: userData.isLoggedIn,
  isLoading: false,
  defaultArtist: defaultArtist,
  myProjectsList: [],
  categoriesList: [],
  rewardValues: [],
  src: null,
  crop: {
    unit: "%",
    width: 30,
    aspect: 16 / 9,
  },
  cropModel: false,
  activeTab: "basics",
  defaultCurrencyCode: {
    code: "INR",
    symbol: "Rs",
  },
  isUpdateLoading: false,
  kycAccountStatus: 0,
};
export default class UpdateProjectNew extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }
  componentDidMount() {
    const defaultArtist = getLocalDataObject("defaultArtist");
    const editID = getLocalData("pkeditid");
    localUserData = getUserData();
    this.getKycAccountStatus();
    this.setState({ isLoading: true });
    fetch(API_VIEW_PROJECT + "?id=" + editID, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          console.log("projectDetails", data.data);
          let project = data.data;
          let existingRewards = project.projectRewards;
          existingRewards.map((value, index) => {
            console.log(value);
            if (value.media.id != 0) {
              existingRewards[index].media_id = value.media.id;
              existingRewards[index].mediaPreview = value.media.originalImage;
            }
          });
          this.setState({
            fields: project,
            rewardValues: existingRewards,
            croppedImageUrl: project.avatar.originalImage
              ? project.avatar.originalImage
              : null,
          });
          this.setState((prevState) => ({
            fields: {
              ...prevState.fields,
              target_goal: project.targetGoal,
              category: Object.keys(project.categories)[0],
              selected_categories: Object.keys(project.categories)[0],
              start_date: project.eventDates[0].dateTimeStart.split(" ")[0],
              end_date: project.eventDates[0].dateTimeEnd.split(" ")[0],
              datesStartEnd: [],
              event_type: "project",
            },
            isLoading: false,
          }));
          console.log("updated", this.state.fields);
        }
      });
    fetch(API_GET_CATEGORIES, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          this.setState({
            categoriesList: data.output.data,
          });
        }
      });
    this.setState({
      defaultArtist: defaultArtist,
      artist_ids: defaultArtist ? [defaultArtist.id] : null,
    });
    if (defaultArtist) {
      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          artist_ids: [defaultArtist.id],
        },
        defaultCurrencyCode: defaultArtist.user.currency,
      }));
    }
  }

  onOpenCropModal = () => {
    this.setState({ cropModel: true });
  };

  onCloseCropModal = () => {
    this.setState({ cropModel: false });
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result, cropModel: true })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  saveCropImage = () => {
    this.setState({ cropModel: false });
    const formData = new FormData();
    var file = new File([this.state.blobCropped], "uploaded_file.jpeg", {
      type: "image/jpeg",
      lastModified: Date.now(),
    });
    formData.append("file", file);

    fetch(API_UPLOAD_IMAGE, {
      method: "POST",
      body: formData,
    })
      .then((r) => r.json())
      .then((data) => {
        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            media_id: data.data[0].id,
          },
        }));
        console.log(this.state);
      });
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const blobCropped = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      const croppedImageUrl = window.URL.createObjectURL(blobCropped);
      this.setState({ croppedImageUrl, blobCropped });
    }
  }

  blobToFile = (theBlob, fileName) => {
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(),
      type: "image/jpeg",
    });
  };
  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          console.log(blob);
          resolve(blob);
        },
        "image/jpeg",
        1
      );
    });
  }

  onFilesChange = (files) => {
    console.log(files[0]);
    this.setState({ uploadFile: files[0], filePreview: files[0].preview.url });
    //upload media
    const formData = new FormData();
    var file = new File([this.state.blobCropped], "uploaded_file.jpeg", {
      type: "image/jpeg",
      lastModified: Date.now(),
    });
    console.log(this.state.croppedImageUrl);
    console.log(file);
    // formData.append("file", files[0]);
    formData.append("file", file);

    fetch(API_UPLOAD_IMAGE, {
      method: "POST",
      body: formData,
    })
      .then((r) => r.json())
      .then((data) => {
        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            media_id: data.data[0].id,
          },
        }));
        console.log(this.state);
      });
  };

  handleChangeFile = (files, i) => {
    console.log(files);
    this.setState({ uploadFile: files[0] });
    let rewardValues = this.state.rewardValues;
    rewardValues[i]["mediaPreview"] = files[0].preview.url;
    this.setState({ rewardValues });
    //upload media
    const formData = new FormData();
    formData.append("file", files[0]);

    fetch(API_UPLOAD_IMAGE, {
      method: "POST",
      body: formData,
    })
      .then((r) => r.json())
      .then((data) => {
        let rewardValues = this.state.rewardValues;
        rewardValues[i]["media_id"] = data.data[0].id;
        this.setState({ rewardValues });
        console.log(this.state.rewardValues);
      });
  };

  onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  handleChangeForm(i, e) {
    let rewardValues = this.state.rewardValues;
    rewardValues[i][e.target.name] = e.target.value;
    this.setState({ rewardValues });
    console.log(this.state.rewardValues);
  }
  addFormFields() {
    this.setState({
      rewardValues: [
        ...this.state.rewardValues,
        {
          title: "",
          description: "",
          noOfRewards: "",
          amount: "",
          delivery: "",
          media_id: "",
          mediaPreview: "",
        },
      ],
    });
  }
  removeFormFields(i) {
    let rewardValues = this.state.rewardValues;
    rewardValues.splice(i, 1);
    this.setState({ rewardValues });
  }

  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  handlePlaceChange = (place) => {
    console.log(place);
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        street_address: place.formatted_address,
        city: place.address_components[0].short_name,
        state: place.address_components[2].long_name,
        country: place.address_components[3].long_name,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      },
    }));
    console.log(this.state);
  };

  handleDescriptionChange = (value, editor) => {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        description: value,
      },
    }));
  };
  handleShortDescriptionChange = (value, editor) => {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        short_description: value,
      },
    }));
  };

  changeStep = (e, tab) => {
    console.log(tab);
    if (tab === "funding") {
      //validate step 1
      if (
        this.validator.fieldValid("name") &&
        this.validator.fieldValid("selected_categories") &&
        this.validator.fieldValid("start_date") &&
        this.validator.fieldValid("end_date")
      ) {
        this.setState({ activeTab: tab });
        console.log(this.state.activeTab);
        this.validator.hideMessages();
        this.saveDraft();
      } else {
        this.validator.showMessages();
      }
    }
    if (tab === "rewards") {
      //validate step 2
      if (this.validator.fieldValid("target_goal")) {
        this.setState({ activeTab: tab });
        console.log(this.state.activeTab);
        this.validator.hideMessages();
        this.saveDraft();
      } else {
        this.validator.showMessages();
      }
    }
    if (tab === "story") {
      //step optional
      this.setState({ activeTab: tab });
      this.validator.hideMessages();
      console.log(this.state.activeTab);
      this.saveDraft();
    }
    if (tab === "payment") {
      //step desc
      if (this.validator.fieldValid("description")) {
        this.setState({ activeTab: tab });
        console.log(this.state.activeTab);
        this.validator.hideMessages();
        this.saveDraft();
      } else {
        this.validator.showMessages();
      }
    }
    if (tab === "basics") {
      this.setState({ activeTab: tab });
      console.log(this.state.activeTab);
      this.saveDraft();
      this.validator.hideMessages();
    }
  };

  saveDraftStep = (e, tab) => {
    console.log(tab);
    if (tab === "funding") {
      //validate step 1
      if (
        this.validator.fieldValid("name") &&
        this.validator.fieldValid("selected_categories") &&
        this.validator.fieldValid("start_date") &&
        this.validator.fieldValid("end_date")
      ) {
        this.validator.hideMessages();
        this.saveDraft(e, true);
      } else {
        this.validator.showMessages();
      }
    }
    if (tab === "rewards") {
      //validate step 2
      if (this.validator.fieldValid("target_goal")) {
        this.validator.hideMessages();
        this.saveDraft(e, true);
      } else {
        this.validator.showMessages();
      }
    }
    if (tab === "story") {
      //step optional
      console.log(this.state.activeTab);
      this.saveDraft(e, true);
    }
    if (tab === "payment") {
      //step desc
      if (this.validator.fieldValid("description")) {
        this.validator.hideMessages();
        this.saveDraft(e, true);
      } else {
        this.validator.showMessages();
      }
    }
    if (tab === "basics") {
      this.saveDraft(e, true);
      this.validator.hideMessages();
    }
  };

  getKycLink = (e) => {
    let userData = getUserData();
    // console.log(userData.user.email);
    this.setState((prevState) => ({
      isBtnLoading: true,
    }));
    fetch(API_KYC + "?email=" + userData.user.email, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          console.log(data);
          this.setState((prevState) => ({
            isBtnLoading: false,
          }));
          kycInterval = setInterval(this.checkKycStatus, 3000);
          //   this.checkKycStatus();
          if (data.data.link) {
            window.open(data.data.link, "_blank");
          }
        }
      });
  };

  checkKycStatus = (e) => {
    console.log("checking");
    fetch(API_USER_PROFILE, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          console.log(data);
          if (data.data.stripe_account_id) {
            this.getKycAccountStatus();
            console.log("KYC Updated");
            //update user
            setLocalDataObject("pkUser", data.data);
            setLocalDataObject("pkUserProfile", data.data.profile);
            clearInterval(kycInterval);
            kycInterval = 0;
            this.setState((prevState) => ({
              kycDone: true,
            }));
          }
        }
      });
  };

  getKycAccountStatus = (e) => {
    fetch(API_KYC_STATUS + "?user_id=" + userData.user.id, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          console.log(data);
          this.setState((prevState) => ({
            isBtnLoading: false,
            kycAccountStatus: data.data.stripeAccountStatus,
          }));
        }
      });
  };

  saveDraft = (e, draftBtn) => {
    //
    console.log("in");
    let startDate = parseInt(
      (new Date(this.state.fields.start_date).getTime() / 1000).toFixed(0)
    );
    let endDate = parseInt(
      (new Date(this.state.fields.end_date).getTime() / 1000).toFixed(0)
    );
    const { fields, rewardValues } = this.state;
    fields.datesStartEnd[0] = startDate + ", " + endDate;
    if (draftBtn) {
      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          status: 4,
        },
        isDraftBtnLoading: true,
      }));
    }
    this.setState(
      (prevState) => ({
        fields: {
          ...prevState.fields,
          datesStartEnd: fields.datesStartEnd,
          hour_date_start: startDate,
          hour_date_end: endDate,
          rewards: rewardValues,
        },
      }),
      () => {
        console.log("sent fields", this.state.fields);
        fetch(API_UPDATE_PROJECT, {
          method: "POST",
          body: JSON.stringify(this.state.fields),
        })
          .then((response) => response.json())
          .then((data) => {
            if (!data.success) {
              let errorList = data.error.fieldErrors;
              if (errorList) {
                errorList.forEach((element) => {
                  ShowToast("error", element.message);
                });
                this.setState((prevState) => ({
                  isUpdateLoading: false,
                }));
              } else {
                ShowToast("error", "Something went wrong!");
              }
            } else {
              this.setState((prevState) => ({
                isUpdateLoading: false,
              }));
              if (draftBtn) {
                this.setState({
                  isDraftBtnLoading: false,
                  redirect: "/mycampaigns",
                });
              }
              if (this.state.updateCompleted) {
                ShowToast("success", "Project Updated");
                this.setState((prevState) => ({
                  redirect: "/mycampaigns",
                }));
              }
            }
          });
      }
    );
  };

  updateProject = (e) => {
    e.preventDefault();
    this.setState(
      (prevState) => ({
        fields: {
          ...prevState.fields,
          status: 1,
        },
        isUpdateLoading: true,
        updateCompleted: true,
      }),
      () => {
        this.saveDraft();
      }
    );
    // if (!this.validator.allValid()) {
    //   //
    //   console.log("in");
    //   let startDate = parseInt(
    //     (new Date(this.state.fields.start_date).getTime() / 1000).toFixed(0)
    //   );
    //   let endDate = parseInt(
    //     (new Date(this.state.fields.end_date).getTime() / 1000).toFixed(0)
    //   );
    //   const { fields, rewardValues } = this.state;
    //   fields.datesStartEnd[0] = startDate + ", " + endDate;
    //   this.setState(
    //     (prevState) => ({
    //       fields: {
    //         ...prevState.fields,
    //         datesStartEnd: fields.datesStartEnd,
    //         hour_date_start: startDate,
    //         hour_date_end: endDate,
    //         rewards: rewardValues,
    //       },
    //     }),
    //     () => {
    //       console.log("sent fields", this.state.fields);
    //       this.setState({ isUpdateLoading: true });
    //       fetch(API_UPDATE_PROJECT, {
    //         method: "POST",
    //         body: JSON.stringify(this.state.fields),
    //       })
    //         .then((response) => response.json())
    //         .then((data) => {
    //           if (!data.success) {
    //             let errorList = data.error.fieldErrors;
    //             if (errorList) {
    //               errorList.forEach((element) => {
    //                 ShowToast("error", element.message);
    //               });
    //               this.setState((prevState) => ({
    //                 isUpdateLoading: false,
    //               }));
    //             } else {
    //               ShowToast("error", "Something went wrong!");
    //             }
    //           } else {
    //             ShowToast("success", "Project Updated");
    //             this.setState((prevState) => ({
    //               initialState,
    //               isUpdateLoading: false,
    //             }));
    //             this.setState((prevState) => ({
    //               redirect: "/mycampaigns",
    //             }));
    //           }
    //         });
    //     }
    //   );
    // } else {
    //   console.log("issue");
    //   this.validator.showMessages();
    //   this.forceUpdate();
    // }
  };

  render() {
    const { crop, croppedImageUrl, src } = this.state;
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <>
        <Helmet>
          <title>Pickfunder | Update Project</title>
        </Helmet>

        <section className="header" id="header">
          <div className="back-img">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 offset-lg-1">
                  <div className="img-log-pcf pt-4 pt-lg-0">
                    <Link to="/">
                      <img src="/images/logo-2.png" alt="PickFunder" />
                    </Link>
                    <div className="img-log-pcf-text">
                      <h1>Update Your Project!</h1>
                      <div className="music-text-man">
                        <p>
                          Just complete the form below to get your project live,
                          then let your fans know where to find you by
                          publishing your link on social media and via email!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <Header homepage={true} />
                </div>
              </div>
            </div>
          </div>
        </section>

        {this.state.defaultArtist ? (
          this.state.isLoading ? (
            <>
              <div className="container">
                <div className="row mt-4 mb-5">
                  <ContentLoader
                    speed={2}
                    width={800}
                    height={500}
                    viewBox="0 0 800 500"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
                    <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
                    <rect x="0" y="56" rx="3" ry="3" width="607" height="9" />
                    <rect x="0" y="80" rx="3" ry="3" width="562" height="9" />
                    <circle cx="20" cy="20" r="20" />
                    <rect
                      x="608"
                      y="202"
                      rx="3"
                      ry="3"
                      width="607"
                      height="9"
                    />
                    <rect
                      x="608"
                      y="178"
                      rx="3"
                      ry="3"
                      width="562"
                      height="9"
                    />
                    <rect
                      x="608"
                      y="155"
                      rx="3"
                      ry="3"
                      width="263"
                      height="9"
                    />
                    <rect x="-5" y="128" rx="3" ry="3" width="607" height="9" />
                    <rect x="-5" y="152" rx="3" ry="3" width="502" height="9" />
                    <rect x="-5" y="175" rx="3" ry="3" width="600" height="9" />
                    <rect x="1" y="105" rx="3" ry="3" width="450" height="9" />
                    <rect x="-1" y="204" rx="3" ry="3" width="607" height="9" />
                    <rect x="-1" y="228" rx="3" ry="3" width="562" height="9" />
                    <rect x="-6" y="276" rx="3" ry="3" width="607" height="9" />
                    <rect x="-6" y="300" rx="3" ry="3" width="502" height="9" />
                    <rect x="-6" y="323" rx="3" ry="3" width="600" height="9" />
                    <rect x="0" y="253" rx="3" ry="3" width="450" height="9" />
                  </ContentLoader>
                </div>
              </div>
            </>
          ) : (
            <>
              <section className="highlights bg-grey outer">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-10">
                      <div className="outer position-relative">
                        <ul
                          className="nav nav-tabs project-tabs"
                          id="myTab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              className={
                                this.state.activeTab === "basics"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              id="basics-tab"
                              //   data-bs-toggle="tab"
                              data-bs-target="#basics"
                              type="button"
                              role="tab"
                              aria-controls="basics"
                              aria-selected="true"
                            >
                              Basics
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className={
                                this.state.activeTab === "funding"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              id="funding-tab"
                              //   data-bs-toggle="tab"
                              data-bs-target="#funding"
                              type="button"
                              role="tab"
                              aria-controls="funding"
                              aria-selected="false"
                            >
                              Funding
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className={
                                this.state.activeTab === "rewards"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              id="rewards-tab"
                              //   data-bs-toggle="tab"
                              data-bs-target="#rewards"
                              type="button"
                              role="tab"
                              aria-controls="rewards"
                              aria-selected="false"
                            >
                              Rewards
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className={
                                this.state.activeTab === "story"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              id="story-tab"
                              //   data-bs-toggle="tab"
                              data-bs-target="#story"
                              type="button"
                              role="tab"
                              aria-controls="story"
                              aria-selected="false"
                            >
                              Story
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className={
                                this.state.activeTab === "payment"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              id="payment-tab"
                              // data-bs-toggle="tab"
                              data-bs-target="#payment"
                              type="button"
                              role="tab"
                              aria-controls="payment"
                              aria-selected="false"
                            >
                              Payment
                            </button>
                          </li>
                        </ul>
                        <div className="tab-content mt-4" id="myTabContent">
                          <div
                            className={
                              this.state.activeTab === "basics"
                                ? "tab-pane fade show active"
                                : "tab-pane fade"
                            }
                            id="basics"
                            role="tabpanel"
                            aria-labelledby="basics-tab"
                          >
                            <div className="row">
                              <div className="form-frame">
                                <label htmlFor="title">Title</label>
                                <input
                                  type="text"
                                  name="name"
                                  value={this.state.fields.name}
                                  onChange={this.handleChange}
                                />
                              </div>
                              <span className="error">
                                {this.validator.message(
                                  "name",
                                  this.state.fields.name,
                                  "required"
                                )}
                              </span>
                              <div className="form-frame">
                                <label htmlFor="">Category</label>
                                <select
                                  name="selected_categories"
                                  onChange={this.handleChange}
                                  value={this.state.fields.selected_categories}
                                >
                                  <option>Select Cateogry</option>
                                  {this.state.categoriesList.map(
                                    (category, i) => (
                                      <option key={i} value={category.id}>
                                        {category.name}
                                      </option>
                                    )
                                  )}
                                </select>
                                <span className="error">
                                  {this.validator.message(
                                    "selected_categories",
                                    this.state.fields.selected_categories,
                                    "required"
                                  )}
                                </span>
                                <div className="text">
                                  <span>Select your campaign category</span>
                                </div>
                              </div>
                              <div className="form-frame">
                                <label htmlFor="tog">Tags</label>
                                <input
                                  type="text"
                                  placeholder="Tag"
                                  name="tags"
                                  value={this.state.fields.tags}
                                  onChange={this.handleChange}
                                />
                                <div className="text">
                                  <span>
                                    Separate tags with commas eg: tag1,tag2
                                  </span>
                                </div>
                              </div>
                              <div className="form-frame">
                                <div className="row">
                                  <label htmlFor="images">Feature Image</label>
                                  <div className="col-md-12">
                                    <div>
                                      <input
                                        type="file"
                                        accept="image/*"
                                        onChange={this.onSelectFile}
                                        style={{ padding: "8px" }}
                                      />
                                    </div>
                                    {croppedImageUrl && (
                                      <img
                                        alt="Croppped Image"
                                        style={{ maxWidth: "200px" }}
                                        src={croppedImageUrl}
                                      />
                                    )}
                                    <Modal
                                      open={this.state.cropModel}
                                      onClose={this.onCloseCropModal}
                                      closeOnOverlayClick={false}
                                    >
                                      {this.state.src && (
                                        <>
                                          <ReactCrop
                                            src={this.state.src}
                                            crop={this.state.crop}
                                            onImageLoaded={this.onImageLoaded}
                                            onComplete={this.onCropComplete}
                                            onChange={this.onCropChange}
                                          />
                                        </>
                                      )}
                                      <div className="text-center file-form ">
                                        <a
                                          className="btn"
                                          onClick={this.saveCropImage}
                                        >
                                          Crop Image
                                        </a>
                                      </div>
                                    </Modal>

                                    {/* <Files
                    className="files-dropzone"
                    onChange={this.onFilesChange}
                    onError={this.onFilesError}
                    accepts={["image/png", "image/jpeg", "image/jpg"]}
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                  >
                    Select Featured Image
                  </Files> */}
                                  </div>
                                  <div className="col-md-12">
                                    {this.state.filePreview ? (
                                      <>
                                        <img
                                          style={{ width: 200 }}
                                          src={this.state.filePreview}
                                        ></img>
                                      </>
                                    ) : null}
                                  </div>

                                  <div className="form-frame">
                                    <span>Upload a campaign feature image</span>
                                  </div>
                                </div>
                              </div>
                              <div className="form-frame">
                                <label htmlFor="">Video</label>
                                <input
                                  type="text"
                                  name="video"
                                  placeholder="https://"
                                  value={this.state.fields.video}
                                  onChange={this.handleChange}
                                />
                                <span>Put the campaign video URL here</span>
                              </div>
                              <div className="form-frame">
                                <div className="row">
                                  <div className="col-md-6">
                                    <label htmlFor="datemax">Start Date</label>
                                    <input
                                      type="date"
                                      id="datemax"
                                      name="start_date"
                                      max=""
                                      value={this.state.fields.start_date}
                                      onChange={this.handleChange}
                                    />
                                    <span className="error">
                                      {this.validator.message(
                                        "start_date",
                                        this.state.fields.start_date,
                                        "required"
                                      )}
                                    </span>
                                    <span>
                                      Campaign start date (dd/mm/yyyy)
                                    </span>
                                  </div>

                                  <div className="col-md-6">
                                    <label htmlFor="datemax">End Date</label>
                                    <input
                                      type="date"
                                      id="datemax"
                                      name="end_date"
                                      value={this.state.fields.end_date}
                                      onChange={this.handleChange}
                                    />
                                    <span className="error">
                                      {this.validator.message(
                                        "end_date",
                                        this.state.fields.end_date,
                                        "required"
                                      )}
                                    </span>
                                    <span>
                                      Campaign start date (dd/mm/yyyy)
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-md-12 action-btns justify-content-end d-flex">
                                <a
                                  className="btn btn-blue"
                                  onClick={(e) =>
                                    this.saveDraftStep(e, "funding")
                                  }
                                >
                                  Save Draft
                                  {this.state.isDraftBtnLoading ? (
                                    <>
                                      &nbsp;
                                      <i className="fa-solid fa-spinner fa-spin"></i>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </a>
                                <a
                                  className="theme-btn"
                                  onClick={(e) => this.changeStep(e, "funding")}
                                >
                                  Next
                                </a>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              this.state.activeTab === "funding"
                                ? "tab-pane fade show active"
                                : "tab-pane fade"
                            }
                            id="funding"
                            role="tabpanel"
                            aria-labelledby="funding-tab"
                          >
                            <div className="form-frame">
                              <div className="row">
                                <div className="col-lg-6">
                                  <label>
                                    Funding Goal (
                                    {this.state.defaultCurrencyCode.symbol})
                                  </label>
                                  <input
                                    type="number"
                                    min=""
                                    max=""
                                    name="target_goal"
                                    value={this.state.fields.target_goal}
                                    onChange={this.handleChange}
                                  />
                                  <span className="error">
                                    {this.validator.message(
                                      "target_goal",
                                      this.state.fields.target_goal,
                                      "required"
                                    )}
                                  </span>
                                  <span>
                                    Recommended campaign funding amount
                                  </span>
                                </div>
                                <div className="col-lg-6">
                                  <label htmlFor="">
                                    Predefined Pledge Amount (
                                    {this.state.defaultCurrencyCode.symbol})
                                  </label>
                                  <input
                                    type="text"
                                    name="predefined_amounts"
                                    value={this.state.fields.predefined_amounts}
                                    onChange={this.handleChange}
                                  />
                                  <div className="text">
                                    <span>
                                      Predefined amount allow you to place the
                                      amount in donate box by click, price
                                      should separated by comma (,), example:{" "}
                                      <small> 25,50,100</small>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-md-12 action-btns justify-content-end d-flex">
                                <a
                                  className="btn btn-blue"
                                  onClick={(e) =>
                                    this.saveDraftStep(e, "basics")
                                  }
                                >
                                  Save Draft
                                  {this.state.isDraftBtnLoading ? (
                                    <>
                                      &nbsp;
                                      <i className="fa-solid fa-spinner fa-spin"></i>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </a>
                                <a
                                  className="btn btn-grey"
                                  onClick={(e) => this.changeStep(e, "basics")}
                                >
                                  Back
                                </a>
                                <a
                                  className="btn btn-primary"
                                  onClick={(e) => this.changeStep(e, "rewards")}
                                >
                                  Next
                                </a>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              this.state.activeTab === "rewards"
                                ? "tab-pane fade show active"
                                : "tab-pane fade"
                            }
                            id="rewards"
                            role="tabpanel"
                            aria-labelledby="rewards-tab"
                          >
                            {this.state.rewardValues.map((element, index) => (
                              <div className="row" key={index}>
                                <div className="col-md-12">
                                  <div className="form-frame">
                                    <label htmlFor="title">Reward Title</label>
                                    <input
                                      type="text"
                                      name="title"
                                      value={element.title || ""}
                                      onChange={(e) =>
                                        this.handleChangeForm(index, e)
                                      }
                                    />
                                  </div>
                                  <span className="error">
                                    {this.validator.message(
                                      "title",
                                      this.state.rewardValues[index].title,
                                      "required"
                                    )}
                                  </span>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-frame">
                                    <label htmlFor="title">Description</label>
                                    <textarea
                                      name="description"
                                      value={element.description || ""}
                                      onChange={(e) =>
                                        this.handleChangeForm(index, e)
                                      }
                                    ></textarea>
                                  </div>
                                  <span className="error">
                                    {this.validator.message(
                                      "description",
                                      this.state.rewardValues[index]
                                        .description,
                                      "required"
                                    )}
                                  </span>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-frame">
                                    <label htmlFor="title">No of Rewards</label>
                                    <input
                                      type="number"
                                      name="no_of_rewards"
                                      value={element.no_of_rewards}
                                      onChange={(e) =>
                                        this.handleChangeForm(index, e)
                                      }
                                    />
                                  </div>
                                  <span className="error">
                                    {this.validator.message(
                                      "noOfRewards",
                                      this.state.rewardValues[index]
                                        .noOfRewards,
                                      "required"
                                    )}
                                  </span>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-frame">
                                    <label htmlFor="title">
                                      Amount (
                                      {this.state.defaultCurrencyCode.symbol})
                                    </label>
                                    <input
                                      type="number"
                                      name="amount"
                                      value={element.amount || ""}
                                      onChange={(e) =>
                                        this.handleChangeForm(index, e)
                                      }
                                    />
                                  </div>
                                  <span className="error">
                                    {this.validator.message(
                                      "amount",
                                      this.state.rewardValues[index].amount,
                                      "required"
                                    )}
                                  </span>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-frame">
                                    <label htmlFor="title">Delivery</label>
                                    <input
                                      type="date"
                                      name="delivery"
                                      value={element.delivery || ""}
                                      onChange={(e) =>
                                        this.handleChangeForm(index, e)
                                      }
                                    />
                                  </div>
                                  <span className="error">
                                    {this.validator.message(
                                      "delivery",
                                      this.state.rewardValues[index].delivery,
                                      "required"
                                    )}
                                  </span>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-frame">
                                    <label htmlFor="title">Media</label>
                                    <Files
                                      className="files-dropzone"
                                      onChange={(e) =>
                                        this.handleChangeFile(e, index)
                                      }
                                      onError={this.onFilesError}
                                      accepts={[
                                        "image/png",
                                        "image/jpeg",
                                        "image/jpg",
                                      ]}
                                      maxFileSize={10000000}
                                      minFileSize={0}
                                      value={element.media_id || ""}
                                      clickable
                                    >
                                      Select Featured Image
                                    </Files>
                                    {element.mediaPreview != "" ? (
                                      <>
                                        <img
                                          style={{ width: 200 }}
                                          src={element.mediaPreview}
                                        ></img>
                                      </>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  {/* {index ? ( */}
                                  <button
                                    type="button"
                                    className="btn btn-danger remove"
                                    onClick={() => this.removeFormFields(index)}
                                  >
                                    Remove
                                  </button>
                                  {/* ) : null} */}
                                </div>
                              </div>
                            ))}
                            <div className="button-section">
                              <button
                                className="btn btn-primary add mt-4"
                                type="button"
                                onClick={() => this.addFormFields()}
                              >
                                Add Rewards
                              </button>
                            </div>
                            <div className="row mt-4">
                              <div className="col-md-12 action-btns justify-content-end d-flex">
                                <a
                                  className="btn btn-blue"
                                  onClick={(e) =>
                                    this.saveDraftStep(e, "funding")
                                  }
                                >
                                  Save Draft
                                  {this.state.isDraftBtnLoading ? (
                                    <>
                                      &nbsp;
                                      <i className="fa-solid fa-spinner fa-spin"></i>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </a>
                                <a
                                  className="btn btn-grey"
                                  onClick={(e) => this.changeStep(e, "funding")}
                                >
                                  Back
                                </a>
                                <a
                                  className="btn btn-primary"
                                  onClick={(e) => this.changeStep(e, "story")}
                                >
                                  Next
                                </a>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              this.state.activeTab === "story"
                                ? "tab-pane fade show active"
                                : "tab-pane fade"
                            }
                            id="story"
                            role="tabpanel"
                            aria-labelledby="story-tab"
                          >
                            <div className="row">
                              <div className="form-frame">
                                <label>Description</label>
                                <Editor
                                  // initialValue={this.state.fields.description}
                                  init={{
                                    height: 200,
                                    menubar: false,
                                    plugins: [
                                      "advlist autolink lists link image charmap print preview anchor",
                                      "searchreplace visualblocks code fullscreen",
                                      "insertdatetime media table paste code help wordcount",
                                    ],
                                    toolbar:
                                      "undo redo | formatselect | " +
                                      "bold italic backcolor | alignleft aligncenter " +
                                      "alignright alignjustify | bullist numlist outdent indent | " +
                                      "removeformat | help",
                                    content_style:
                                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                  }}
                                  value={this.state.fields.description}
                                  onEditorChange={this.handleDescriptionChange}
                                />
                                {/* <textarea
                name="description"
                id=""
                cols="30"
                rows="10"
                value={this.state.fields.description}
                onChange={this.handleChange}
              ></textarea> */}
                              </div>
                              <span className="error">
                                {this.validator.message(
                                  "description",
                                  this.state.fields.description,
                                  "required"
                                )}
                              </span>
                              <div className="form-frame">
                                <label>Short-Description</label>
                                <Editor
                                  // initialValue={this.state.fields.short_description}
                                  init={{
                                    height: 200,
                                    menubar: false,
                                    plugins: [
                                      "advlist autolink lists link image charmap print preview anchor",
                                      "searchreplace visualblocks code fullscreen",
                                      "insertdatetime media table paste code help wordcount",
                                    ],
                                    toolbar:
                                      "undo redo | formatselect | " +
                                      "bold italic backcolor | alignleft aligncenter " +
                                      "alignright alignjustify | bullist numlist outdent indent | " +
                                      "removeformat | help",
                                    content_style:
                                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                  }}
                                  onEditorChange={
                                    this.handleShortDescriptionChange
                                  }
                                />
                                {/* <textarea
                name="short_description"
                id=""
                cols="30"
                rows="5"
                value={this.state.fields.short_description}
                onChange={this.handleChange}
              ></textarea> */}
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-md-12 action-btns justify-content-end d-flex">
                                <a
                                  className="btn btn-blue"
                                  onClick={(e) =>
                                    this.saveDraftStep(e, "rewards")
                                  }
                                >
                                  Save Draft
                                  {this.state.isDraftBtnLoading ? (
                                    <>
                                      &nbsp;
                                      <i className="fa-solid fa-spinner fa-spin"></i>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </a>
                                <a
                                  className="btn btn-grey"
                                  onClick={(e) => this.changeStep(e, "rewards")}
                                >
                                  Back
                                </a>
                                <a
                                  className="btn btn-primary"
                                  onClick={(e) => this.changeStep(e, "payment")}
                                >
                                  Next
                                </a>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              this.state.activeTab === "payment"
                                ? "tab-pane fade show active"
                                : "tab-pane fade"
                            }
                            id="payment"
                            role="tabpanel"
                            aria-labelledby="payment-tab"
                          >
                            {this.state.kycAccountStatus == 2 ||
                            this.state.kycAccountStatus == 3 ? (
                              <>
                                <h3 className="mt-4">KYC is completed</h3>
                              </>
                            ) : (
                              <>
                                <button
                                  className="btn btn-primary mt-4"
                                  onClick={this.getKycLink}
                                >
                                  Complete KYC
                                  {this.state.isBtnLoading ? (
                                    <>
                                      {" "}
                                      <i class="fa-solid fa-spinner fa-spin"></i>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </button>
                              </>
                            )}
                            <div className="row mt-4">
                              <div className="col-md-12 action-btns justify-content-end d-flex">
                                <a
                                  className="btn btn-blue"
                                  onClick={(e) =>
                                    this.saveDraftStep(e, "story")
                                  }
                                >
                                  Save Draft
                                  {this.state.isDraftBtnLoading ? (
                                    <>
                                      &nbsp;
                                      <i className="fa-solid fa-spinner fa-spin"></i>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </a>
                                <a
                                  className="btn btn-grey"
                                  onClick={(e) => this.changeStep(e, "story")}
                                >
                                  Back
                                </a>
                                {this.state.kycAccountStatus == 2 ||
                                this.state.kycAccountStatus == 3 ? (
                                  <button
                                    className="btn btn-primary"
                                    disabled={this.state.isUpdateLoading}
                                    onClick={this.updateProject}
                                  >
                                    Update Project
                                    {this.state.isUpdateLoading ? (
                                      <>
                                        &nbsp;
                                        <i className="fa-solid fa-spinner fa-spin"></i>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </button>
                                ) : (
                                  <button className="btn btn-primary" disabled>
                                    Update Project
                                  </button>
                                )}
                              </div>
                            </div>
                            {/* <form id="from-back">
                    <div className="file-form d-flex justify-content-between">
                      <a href="#!">Cancel</a>
                      <button className="btn" onClick={this.createProject}>
                        Create Project
                      </button>
                    </div>
                  </form> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )
        ) : (
          <section className="container">
            <h2 className="text-center">
              Please create an Artist Profile from mobile app.
            </h2>
            <div className="row difind mt-4">
              <div className="col-md-6 text-center">
                <a
                  href="https://apps.apple.com/us/app/picklejar-live/id1517697776"
                  target={"_blank"}
                >
                  <img
                    src="images/App-Store.png"
                    alt="App-Store"
                    className="img-fluid"
                    width="200px"
                  />
                </a>
              </div>
              <div className="col-md-6 text-center">
                <a
                  href="https://play.google.com/store/apps/details?id=com.Reatro.picklejar"
                  target={"_blank"}
                >
                  <img
                    src="images/google-play.png"
                    alt="google-play"
                    className="img-fluid"
                    width="200px"
                  />
                </a>
              </div>
            </div>
          </section>
        )}

        <Footer />
      </>
    );
  }
}
