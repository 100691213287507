import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/sections/Footer";
import Header from "../components/sections/Header";
import SimpleReactValidator from "simple-react-validator";
import {Link, Navigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import {
  API_USER_UPDATE_PROFILE,
  API_USER_PROFILE,
  API_STRIPE_PAYMENT_METHODS,
} from "../config/endpoints";
import {
  getLocalDataObject,
  getUserData,
  setUserData,
} from "../helpers/Storage";
import { ShowToast } from "../helpers/Helpers";
import { loadStripe } from "@stripe/stripe-js";

import PaymentMethod from "../components/PaymentMethod";
import ShippingAddress from "../components/ShippingAddress";
import ContentLoader from "react-content-loader";

const userData = getUserData();
const defaultArtist = getLocalDataObject("defaultArtist");
const initialState = {
  redirect: false,
  fields: {
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    phone_1: "",
  },
  userProfile: userData.userProfile,
  isLoggedIn: userData.isLoggedIn,
  defaultArtist: defaultArtist,
  myProjectsList: [],
  addCard: false,
  savedCards: [],
  isCardLoading: false,
};
export default class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }
  componentDidMount() {
    const defaultArtist = getLocalDataObject("defaultArtist");
    this.setState({
      isLoading: true,
    });
    fetch(API_USER_PROFILE, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          this.setState((prevState) => ({
            fields: {
              ...prevState.fields,
              first_name: data.data.profile.firstName,
              last_name: data.data.profile.lastName,
              username: data.data.userName,
              phone_1: data.data.profile.phone1,
              email: data.data.email,
            },
            isLoading: false,
          }));
        }
      });

    this.getSavedCards();
  }

  addPaymentMethod = (e) => {
    if (!userData.user.customer_stripe_id) {
      //create stripe customer
      this.setState((prevState) => ({
        isBtnLoading: false,
      }));
      fetch("https://api.stripe.com/v1/customers", {
        method: "POST",
        body: JSON.stringify({
          email: userData.user.email,
          name: userData.userProfile.fullName,
          phone: userData.userProfile.phone1,
        }),
        headers: {
          token: process.env.REACT_APP_STRIPE_SK_KEY,
          Authorization: "Bearer " + process.env.REACT_APP_STRIPE_SK_KEY,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          fetch(API_USER_UPDATE_PROFILE, {
            method: "POST",
            body: JSON.stringify({ customer_stripe_id: data.id }),
          })
            .then((response) => response.json())
            .then((data) => {
              if (!data.success) {
                let errorList = data.error.fieldErrors;
                if (errorList) {
                  errorList.forEach((element) => {
                    ShowToast("error", element.message);
                  });
                  this.setState((prevState) => ({
                    isLoading: false,
                  }));
                } else {
                  ShowToast("error", "Something went wrong!");
                }
              } else {
                setUserData(data);
                userData = getUserData();
                // ShowToast("success", "Profile Updated");
                this.setState((prevState) => ({
                  isLoading: false,
                  addCard: true,
                }));
                //show card insert container
              }
            });
        });
    } else {
      this.setState((prevState) => ({
        addCard: true,
      }));
    }
  };

  getSavedCards = (e) => {
    this.setState({ isCardLoading: true });
    fetch(
      "https://api.stripe.com/v1/customers/" +
        userData.user.customer_stripe_id +
        "/sources" +
        "?object=card",
      {
        method: "GET",
        // body: JSON.stringify({ u: process.env.REACT_APP_STRIPE_SK_KEY }),
        headers: {
          "Content-Type": "application/json",
          token: process.env.REACT_APP_STRIPE_SK_KEY,
          Authorization: "Bearer " + process.env.REACT_APP_STRIPE_SK_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("cards", data);
        if (!data.error) {
          this.setState({
            savedCards: data.data,
            isCardLoading: false,
          });
        } else {
          this.setState({
            isCardLoading: false,
          });
        }
      });
  };

  deleteSavedCard = (e, cardid) => {
    if (window.confirm("Are you sure to delete this record?")) {
      fetch(
        "https://api.stripe.com/v1/customers/" +
          userData.user.customer_stripe_id +
          "/sources/" +
          cardid,
        {
          method: "DELETE",
          // body: new URLSearchParams({
          //   source: token.token.id,
          // }),
          headers: {
            token: process.env.REACT_APP_STRIPE_SK_KEY,
            Authorization: "Bearer " + process.env.REACT_APP_STRIPE_SK_KEY,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          this.getSavedCards();
        });
    }
  };

  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  updateProfile = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      //
      this.setState({
        isLoading: true,
      });
      fetch(API_USER_UPDATE_PROFILE, {
        method: "POST",
        body: JSON.stringify(this.state.fields),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            let errorList = data.error.fieldErrors;
            if (errorList) {
              errorList.forEach((element) => {
                ShowToast("error", element.message);
              });
              this.setState((prevState) => ({
                isLoading: false,
              }));
            } else {
              ShowToast("error", "Something went wrong!");
            }
          } else {
            setUserData(data);
            ShowToast("success", "Profile Updated");
            this.setState((prevState) => ({
              isLoading: false,
            }));
          }
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <>
        <Helmet>
          <title>Pickfunder | Create Project</title>
        </Helmet>

        <section className="header" id="header">
          <div className="back-img">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 offset-lg-1">
                  <div className="img-log-pcf pt-4 pt-lg-0">
                    <Link to="/">
                      <img src="/images/logo-2.png" alt="PickFunder" />
                    </Link>
                    <div className="img-log-pcf-text">
                      <h1>My Profile</h1>
                      <div className="music-text-man">
                        <p>Update your profile.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <Header homepage={true} />
                </div>
              </div>
            </div>
          </div>
        </section>

        {this.state.isLoading ? (
          <>
            <section className="container mt-5">
              <div className="row">
                <div className="col-12">
                  <ContentLoader
                    speed={2}
                    width={800}
                    height={500}
                    viewBox="0 0 800 500"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
                    <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
                    <rect x="0" y="56" rx="3" ry="3" width="607" height="9" />
                    <rect x="0" y="80" rx="3" ry="3" width="562" height="9" />
                    <circle cx="20" cy="20" r="20" />
                    <rect
                      x="608"
                      y="202"
                      rx="3"
                      ry="3"
                      width="607"
                      height="9"
                    />
                    <rect
                      x="608"
                      y="178"
                      rx="3"
                      ry="3"
                      width="562"
                      height="9"
                    />
                    <rect
                      x="608"
                      y="155"
                      rx="3"
                      ry="3"
                      width="263"
                      height="9"
                    />
                    <rect x="-5" y="128" rx="3" ry="3" width="607" height="9" />
                    <rect x="-5" y="152" rx="3" ry="3" width="502" height="9" />
                    <rect x="-5" y="175" rx="3" ry="3" width="600" height="9" />
                    <rect x="1" y="105" rx="3" ry="3" width="450" height="9" />
                    <rect x="-1" y="204" rx="3" ry="3" width="607" height="9" />
                    <rect x="-1" y="228" rx="3" ry="3" width="562" height="9" />
                    <rect x="-6" y="276" rx="3" ry="3" width="607" height="9" />
                    <rect x="-6" y="300" rx="3" ry="3" width="502" height="9" />
                    <rect x="-6" y="323" rx="3" ry="3" width="600" height="9" />
                    <rect x="0" y="253" rx="3" ry="3" width="450" height="9" />
                  </ContentLoader>
                </div>
              </div>
            </section>
          </>
        ) : (
          <>

              <section className="highlights bg-grey">
                <div className="container">
                  <div className="row">
                    <div className="offset-lg-1 col-lg-10">
                      <div className="outer position-relative">
                        {/* Tab List */}
                        <ul className="nav profile-tabs border-bottom" id="myTab" role="tablist">
                          <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true">
                              Profile
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button className="nav-link" id="payment-tab" data-bs-toggle="tab" data-bs-target="#payment" type="button" role="tab" aria-controls="payment" aria-selected="false">
                              Payment Methods
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button className="nav-link" id="shipping-tab" data-bs-toggle="tab" data-bs-target="#shipping" type="button" role="tab" aria-controls="shipping" aria-selected="false">
                              Shipping Methods
                            </button>
                          </li>
                        </ul>

                        {/* Tab Content */}
                        <div className="tab-content mt-5" id="myTabContent">
                          <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <form>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="form-frame">
                                    <label htmlFor="title">First Name</label>
                                    <input
                                      type="text"
                                      name="first_name"
                                      value={this.state.fields.first_name}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                  <span className="error">
                                    {this.validator.message(
                                      "first_name",
                                      this.state.fields.first_name,
                                      "required"
                                    )}
                                  </span>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-frame">
                                    <label htmlFor="title">Last Name</label>
                                    <input
                                      type="text"
                                      name="last_name"
                                      value={this.state.fields.last_name}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                  <span className="error">
                                    {this.validator.message(
                                      "last_name",
                                      this.state.fields.last_name,
                                      "required"
                                    )}
                                  </span>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-frame">
                                    <label htmlFor="title">Username</label>
                                    <input
                                      type="text"
                                      name="username"
                                      value={this.state.fields.username}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                  <span className="error">
                                    {this.validator.message(
                                      "username",
                                      this.state.fields.username,
                                      "required"
                                    )}
                                  </span>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-frame">
                                    <label htmlFor="title">Phone</label>
                                    <input
                                      type="text"
                                      name="phone_1"
                                      value={this.state.fields.phone_1}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                  <span className="error">
                                    {this.validator.message(
                                      "phone_1",
                                      this.state.fields.phone_1,
                                      "required"
                                    )}
                                  </span>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-frame">
                                    <label htmlFor="title">Email</label>
                                    <input
                                      type="text"
                                      name="email"
                                      value={this.state.fields.email}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                  <span className="error">
                                    {this.validator.message(
                                      "email",
                                      this.state.fields.email,
                                      "required"
                                    )}
                                  </span>
                                </div>
                              </div>

                              <div className="file-form text-center mt-3">
                                <button className="theme-btn" onClick={this.updateProfile}>
                                  Update Profile
                                </button>
                              </div>
                            </form>
                          </div>
                          <div className="tab-pane fade" id="payment" role="tabpanel" aria-labelledby="payment-tab">
                            <div className="row">
                              <div className="col-md-8">
                                <h4>
                                  {this.state.addCard ? (
                                    <>
                                      <h4>Add Card</h4>
                                      <PaymentMethod />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </h4>
                              </div>
                              <div className="col-md-4">
                                <button className="btn btn-primary pull-right" onClick={this.addPaymentMethod}>Add Payment Method</button>
                              </div>
                              <div className="col-12">
                              <div>
                                  {this.state.savedCards.length > 0 ? (
                                    <h3 className="mt-4">Saved Cards</h3>
                                  ) : null}

                                  <div className="row">
                                    {this.state.isCardLoading ? (
                                      <>
                                        <div className="coutom-spinner d-flex justify-content-center align-items-center">
                                          <div className="spinner-border text-light">
                                            <span className="visually-hidden">
                                              Loading...
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      this.state.savedCards.map((card, index) => (
                                        <div className="col-md-6">
                                          <div
                                            className={`credit-card selectable ${card.brand.replace(
                                              /\s+/g,
                                              "-"
                                            )}`}
                                            key={index}
                                          >
                                            <div className="credit-card-last4">
                                              {card.last4}
                                            </div>
                                            <div className="credit-card-expiry">
                                              {card.exp_month}/{card.exp_year}
                                            </div>
                                            <div className="card-actions">
                                              <a
                                                className="pull-right"
                                                onClick={(e) =>
                                                  this.deleteSavedCard(e, card.id)
                                                }
                                              >
                                                Delete
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      ))
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="tab-pane fade" id="shipping" role="tabpanel" aria-labelledby="shipping-tab">
                            <ShippingAddress></ShippingAddress>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
          </>
        )}
        <Footer />
      </>
    );
  }
}
