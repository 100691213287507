import React, { useState, useEffect, useRef } from "react";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  getLocalDataObject,
  getUserData,
  setProjectReward,
} from "../helpers/Storage";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_KEY);
let userData = getUserData();

function PaymentMethod() {
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [savedCards, setSavedCards] = useState(false);
  const CheckoutForm = (project) => {
    const stripe = useStripe();
    const elements = useElements();
    const handleSubmit = async (event) => {
      event.preventDefault();

      if (elements == null) {
        return;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (paymentMethod) {
        const cardElement = elements.getElement(CardElement);
        let token = await stripe.createToken(cardElement, { name: "Dama" });
        setIsLoading(true);
        //create stripe user
        fetch(
          "https://api.stripe.com/v1/customers/" +
            userData.user.customer_stripe_id +
            "/sources",
          {
            method: "POST",
            body: new URLSearchParams({
              source: token.token.id,
            }),
            headers: {
              token: process.env.REACT_APP_STRIPE_SK_KEY,
              Authorization: "Bearer " + process.env.REACT_APP_STRIPE_SK_KEY,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            window.location = "";
          });
      }
    };

    return (
      <form onSubmit={handleSubmit} className="mt-5">
        <CardElement
          className="payment-card"
          options={{
            style: {
              base: {
                backgroundColor: "white",
              },
            },
          }}
        />
        <button
          type="submit"
          className="pay-button"
          disabled={!stripe || !elements}
        >
          {/* Pay ${tipPrice} */}
          Add Card
        </button>
      </form>
    );
  };
  return (
    <div className="App">
      <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    </div>
  );
}
export default PaymentMethod;
