import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/sections/Footer";
import Header from "../components/sections/Header";
import { getLocalDataObject, getUserData } from "../helpers/Storage";
import SimpleReactValidator from "simple-react-validator";
import { API_CONTACT } from "../config/endpoints";
import { ShowToast } from "../helpers/Helpers";
let userData = getUserData();
let defaultArtist = getLocalDataObject("defaultArtist");
const initialState = {
  redirect: false,
  userProfile: userData.userProfile,
  isLoggedIn: userData.isLoggedIn,
  defaultArtist: defaultArtist,
  isLoading: false,
  allProjectsList: [],
  fields: {
    email: "",
    name: "",
    message: "",
    subject: "",
  },
};
export default class Contact extends Component {
  constructor(props) {
    super(props);
    let userData = getUserData();
    let defaultArtist = getLocalDataObject("defaultArtist");
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }
  componentDidMount() {}

  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      //
      this.setState({
        isLoading: true,
      });
      fetch(API_CONTACT, {
        method: "POST",
        body: JSON.stringify(this.state.fields),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            ShowToast("error", "Something went wrong.");
          } else {
            ShowToast(
              "success",
              "Thanks for contacting us, Will get back to you soon."
            );
            this.setState(initialState);
            this.validator.hideMessages();
          }
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  render() {
    return (
      <>
        <Helmet>
          <title>Pickfunder</title>
        </Helmet>
        <Header />
        {this.state.isLoading ? (
          <>
            <div className="coutom-spinner d-flex justify-content-center align-items-center">
              <div className="spinner-border text-light">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </>
        ) : (
          <div className="Connect-noe">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="text-me">
                    <ul>
                      <li>
                        <span>Connect Now</span>
                      </li>
                    </ul>
                    <h1>How can we help?</h1>
                    <p>
                      We are here to listen to, and support, artists and
                      creators all over the world. Let us know what we can do
                      for you!
                    </p>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-sm-6 col-xs-12">
                      <input
                        type="text"
                        placeholder="Your Name"
                        name="name"
                        value={this.state.fields.name}
                        onChange={this.handleChange}
                      />
                      <span className="error">
                        {this.validator.message(
                          "name",
                          this.state.fields.name,
                          "required"
                        )}
                      </span>
                    </div>

                    <div className="col-sm-6 col-xs-12">
                      <input
                        type="text"
                        placeholder="Your Email"
                        name="email"
                        value={this.state.fields.email}
                        onChange={this.handleChange}
                      />
                      <span className="error">
                        {this.validator.message(
                          "email",
                          this.state.fields.email,
                          "required"
                        )}
                      </span>
                    </div>
                    <div className="col-md-12">
                      <input
                        type="text"
                        placeholder="Your Subject"
                        name="subject"
                        value={this.state.fields.subject}
                        onChange={this.handleChange}
                      />
                      <span className="error">
                        {this.validator.message(
                          "subject",
                          this.state.fields.subject,
                          "required"
                        )}
                      </span>
                    </div>
                    <textarea
                      cols="40"
                      rows="10"
                      placeholder="Your message"
                      name="message"
                      value={this.state.fields.message}
                      onChange={this.handleChange}
                    ></textarea>
                    <span className="error">
                      {this.validator.message(
                        "message",
                        this.state.fields.message,
                        "required"
                      )}
                    </span>
                  </div>
                  <button className="but" onClick={this.handleSubmit}>
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </>
    );
  }
}
