import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { ShowToast } from "../helpers/Helpers";
import { setUserData, setLocalData } from "../helpers/Storage";
import { API_SIGNUP_STEP_1, API_LOGIN_STEP_2 } from "../config/endpoints";
const initialState = {
  fields: {
    username: "",
    phone_1: "",
    phone_number: "",
    first_name: "",
    last_name: "",
    email: "",
    country: "US",
    timezone: "America/New_York",
    language: "en-US",
    password: "picklejar",
    repeat_password: "picklejar",
    confirmation_token: "",
    confirmation_numbers: "",
  },
  isLoading: false,
  redirect: false,
  currentStep: 1,
};
export default class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  handleChangePhone = (value) => {
    var username = value.replace("+", "");
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        phone_1: value,
        username: username,
        phone_number: value,
      },
    }));
  };
  signupStepOne = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({
        isLoading: true,
      });
      fetch(API_SIGNUP_STEP_1, {
        method: "POST",
        body: JSON.stringify(this.state.fields),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            let errorList = data.error.fieldErrors;
            errorList.forEach((element) => {
              ShowToast("error", element.message);
            });
            this.setState((prevState) => ({
              isLoading: false,
            }));
          } else {
            ShowToast("success", "OTP sent to your phone number.");
            this.setState((prevState) => ({
              fields: {
                ...prevState.fields,
                confirmation_token: data.data.confirmationToken,
              },
              currentStep: 2,
              isLoading: false,
            }));

            console.log(this.state);
          }
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  signupStepTwo = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({
        isLoading: true,
      });
      fetch(API_LOGIN_STEP_2, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(this.state.fields),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            let errorList = data.error.fieldErrors;
            errorList.forEach((element) => {
              ShowToast("error", element.message);
            });
            this.setState((prevState) => ({
              isLoading: false,
            }));
          } else {
            //save user here
            setUserData(data);
            ShowToast("success", "Logged in.");
            this.setState((prevState) => ({
              currentStep: 1,
              redirect: "/mycampaigns",
              isLoading: false,
            }));
            console.log("user", data);
          }
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  render() {
    if (this.state.redirect) {
      window.location = this.state.redirect;
    }
    return (
      <>
        <Helmet>
          <title>Pickfunder - Signup</title>
        </Helmet>
        {this.state.isLoading ? (
          <>
            <div className="coutom-spinner d-flex justify-content-center align-items-center">
              <div className="spinner-border text-light">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </>
        ) : (
          <>
            <section className="login-form">
              <div className="text-titie-back">
                <Link to={"/"}>
                  <span>back</span>
                </Link>
              </div>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-5">
                    <div className="design d-flex align-items-center justify-content-center flex-column animate__animated animate__bounce animate__backInDown">
                      <div className="logo">
                        <Link to="/">
                          <img
                            src="/images/logo1.png"
                            alt=""
                            className="img-fluid"
                            width="330px"
                          />
                        </Link>
                      </div>
                      {this.state.currentStep === 1 ? (
                        <form id="loginForm">
                          <label htmlFor="Fname" className="label">
                            First Name
                          </label>
                          <div className="first mt-1 mb-3 d-flex">
                            <input
                              type="text"
                              id="first_name"
                              name="first_name"
                              value={this.state.fields.first_name}
                              onChange={this.handleChange}
                            />
                          </div>
                          <span className="error">
                            {this.validator.message(
                              "first_name",
                              this.state.fields.first_name,
                              "required"
                            )}
                          </span>
                          <label htmlFor="Fname" className="label">
                            Last Name
                          </label>
                          <div className="first mt-1 mb-3 d-flex">
                            <input
                              type="text"
                              id="last_name"
                              name="last_name"
                              value={this.state.fields.last_name}
                              onChange={this.handleChange}
                            />
                          </div>
                          <span className="error">
                            {this.validator.message(
                              "last_name",
                              this.state.fields.last_name,
                              "required"
                            )}
                          </span>
                          <label htmlFor="Phone_number" className="label">
                            Mobile Number
                          </label>
                          <div className="phone-number mt-1 mb-3 d-flex">
                            <PhoneInput
                              name="phone_1"
                              international
                              countryCallingCodeEditable={false}
                              defaultCountry="US"
                              value={this.state.fields.phone_1}
                              onChange={this.handleChangePhone}
                            />
                          </div>
                          <label htmlFor="email" className="label">
                            Email
                          </label>
                          <div className="email mt-1 mb-3 d-flex">
                            <input
                              type="email"
                              id="email"
                              name="email"
                              value={this.state.fields.email}
                              onChange={this.handleChange}
                            />
                          </div>
                          <span className="error">
                            {this.validator.message(
                              "email",
                              this.state.fields.email,
                              "required"
                            )}
                          </span>
                          <button
                            type="submit"
                            form="loginForm"
                            className="sign-in-button"
                            onClick={this.signupStepOne}
                          >
                            SIGNUP
                          </button>
                          <Link className="signup-button" to="/login">
                            Back to login
                          </Link>
                        </form>
                      ) : (
                        <form id="loginForm">
                          <label htmlhtmlFor="phone_number">Enter OTP</label>
                          <div className="phone-number mt-1 mb-3 d-flex">
                            <input
                              name="confirmation_numbers"
                              onChange={this.handleChange}
                              value={this.state.fields.confirmation_numbers}
                            />
                          </div>
                          <label className="error">
                            {this.validator.message(
                              "OTP",
                              this.state.fields.confirmation_numbers,
                              "required"
                            )}
                          </label>
                          <button
                            type="submit"
                            form="loginForm"
                            className="submit-button"
                            onClick={this.signupStepTwo}
                          >
                            Signup
                          </button>

                          <label className="label">
                            Already have an account?
                          </label>
                          <Link to="/login" className="signup-button">
                            Back to login
                          </Link>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </>
    );
  }
}
