import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/sections/Footer";
import Header from "../components/sections/Header";
import { convertToSlug } from "../helpers/Helpers";

import {
  API_USER_CARD_ADD,
  API_SEND_TIP,
  API_SAVE_REWARD_ORDER,
  API_VIEW_PROJECT,
  API_GET_REWARD,
} from "../config/endpoints";
import {
  getLocalDataObject,
  getUserData,
  setProjectReward,
  deleteLocalDataObject,
} from "../helpers/Storage";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { ShowToast } from "../helpers/Helpers";
import GetCardList from "../components/GetCardList";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import ShippingAddress from "../components/ShippingAddress";
import ReCAPTCHA from "react-google-recaptcha";

let userData = getUserData();
let defaultArtist = getLocalDataObject("defaultArtist");
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_KEY);

export default function Rewards() {
  const [projectRq, setProjectRq] = useState({
    projectDetails: {
      name: "",
      avatar: { originalImage: false },
      backerDetails: { totalAmount: 0, totalBackers: 0, goalCompleted: 0 },
      projectRewards: [],
      address: { formatted_address: "" },
      predefined_amounts: "",
      projectUpdates: [],
      eventAdmins: [
        {
          user: {
            currency: { code: "" },
          },
        },
      ],
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [tipPrice, setTipPrice] = useState(0);
  const [extraPrice, setExtraPrice] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const rewards =
    getLocalDataObject("projectRewards") &&
    getLocalDataObject("projectRewards").length > 0
      ? getLocalDataObject("projectRewards")
      : [];
  const [projectRewards, setProjectRewards] = useState(rewards);
  const [rewardDetails, setRewardDetails] = useState();

  const [userShippingAddress, setUserShippingAddress] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  // const [captchaValue, setCaptchaValue] = useState();

  let { id } = useParams();
  let captchaValue = false;

  const CheckoutForm = (project) => {
    const stripe = useStripe();
    const elements = useElements();
    const handleSubmit = async (event) => {
      event.preventDefault();

      if (tipPrice <= 0) {
        ShowToast("error", "Please select rewards from project.");
        return;
      }

      if (elements === null) {
        return;
      }

      if (!captchaValue) {
        ShowToast("error", "Captcha is required!");
        return;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      });

      if (paymentMethod) {
        const cardElement = elements.getElement(CardNumberElement);
        let token = await stripe.createToken(cardElement, {});

        setIsLoading(true);
        //create stripe user
        fetch(API_USER_CARD_ADD, {
          method: "POST",
          body: JSON.stringify({ token: token.token.id }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (!data.success) {
              let errorList = data.error.fieldErrors;
              if (errorList) {
                errorList.forEach((element) => {
                  ShowToast("error", element.message);
                });
              } else {
                ShowToast("error", "Something went wrong!");
              }
              setIsLoading(false);
            } else {
              //send payment
              fetch(API_SEND_TIP, {
                method: "POST",
                body: JSON.stringify({
                  sender_id: userData.user.id,
                  amount_tipped: tipPrice,
                  event_id: project.project,
                  message: "",
                  card_id: data.data.card_id,
                  reCaptcha: captchaValue,
                }),
              })
                .then((response) => response.json())
                .then((data) => {
                  if (!data.success) {
                    let errorList = data.error.fieldErrors;
                    if (errorList) {
                      errorList.forEach((element) => {
                        ShowToast("error", element.message);
                      });
                    } else {
                      ShowToast("error", "Something went wrong!");
                    }
                    setIsLoading(false);
                  } else {
                    //insert rewards
                    fetch(API_SAVE_REWARD_ORDER, {
                      method: "POST",
                      body: JSON.stringify({
                        rewards: projectRewards[0],
                        reward_id: projectRewards[0].id,
                        tip_id: data.data.id,
                        event_id: project.project,
                        user_id: userData.user.id,
                        amount: tipPrice,
                        shipping_address: "test",
                      }),
                    })
                      .then((response) => response.json())
                      .then((data) => {
                        if (!data.success) {
                          let errorList = data.error.fieldErrors;
                          if (errorList) {
                            errorList.forEach((element) => {
                              ShowToast("error", element.message);
                            });
                          } else {
                            ShowToast("error", "Something went wrong!");
                          }
                          setIsLoading(false);
                        } else {
                          console.log("rewards", data);
                          ShowToast("success", "Payment completed.");
                          setIsLoading(false);
                          deleteLocalDataObject("projectRewards");
                          // window.location = "";
                          setIsSuccess(true);
                        }
                      });
                  }
                });
            }
          });
      }
    };

    const onCaptchaChange = (value) => {
      console.log("Captcha value:", value);
      // setCaptchaValue(value);
      captchaValue = value;
    };
    return (
      <>
        <GetCardList onCardClick={onCardPayment} />
        <h5>Or</h5>
        <form onSubmit={handleSubmit}>
          {tipPrice > 0 ? (
            <>
              <div className="row">
                <div className="col-md-12">
                  <CardNumberElement className="payment-card" />
                </div>
                <div className="col-md-6">
                  <CardExpiryElement className="payment-card" />
                </div>
                <div className="col-md-6">
                  <CardCvcElement className="payment-card" />
                </div>
                <div className="col-md-12">
                  <input
                    type={"text"}
                    className="payment-card"
                    placeholder="Zip Code"
                  />
                </div>
              </div>
              {/* <CardElement
              className="payment-card"
              options={{
                style: {
                  base: {
                    backgroundColor: "white",
                  },
                },
              }}
            /> */}
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                onChange={onCaptchaChange}
                className="mt-4"
              />
              <button
                type="submit"
                className="pay-button"
                disabled={!stripe || !elements}
              >
                Pay {projectDetails.eventAdmins[0].user.currency.code}{" "}
                {projectDetails.eventAdmins[0].user.currency.symbol}
                {tipPrice}
              </button>
            </>
          ) : null}
        </form>
      </>
    );
  };

  const onCardPayment = async (cardId) => {
    console.log(cardId);
    setIsLoading(true);
    //send payment
    fetch(API_SEND_TIP, {
      method: "POST",
      body: JSON.stringify({
        sender_id: userData.user.id,
        amount_tipped: tipPrice,
        event_id: id,
        message: "",
        card_id: cardId,
        reCaptcha: captchaValue,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          let errorList = data.error.fieldErrors;
          if (errorList) {
            errorList.forEach((element) => {
              ShowToast("error", element.message);
            });
          } else {
            ShowToast("error", "Something went wrong!");
          }
          setIsLoading(false);
        } else {
          //insert rewards
          fetch(API_SAVE_REWARD_ORDER, {
            method: "POST",
            body: JSON.stringify({
              rewards: projectRewards[0],
              reward_id: projectRewards[0].id,
              tip_id: data.data.id,
              event_id: id,
              user_id: userData.user.id,
              amount: tipPrice,
              shipping_address: "test",
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              if (!data.success) {
                let errorList = data.error.fieldErrors;
                if (errorList) {
                  errorList.forEach((element) => {
                    ShowToast("error", element.message);
                  });
                } else {
                  ShowToast("error", "Something went wrong!");
                }
                setIsLoading(false);
              } else {
                console.log("rewards", data);
                ShowToast("success", "Payment completed.");
                setIsLoading(false);
                deleteLocalDataObject("projectRewards");
                // window.location = "";
                setIsSuccess(true);
              }
            });
        }
      });
  };

  useEffect(() => {
    setIsLoading(true);
    fetch(API_VIEW_PROJECT + "?id=" + id, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          setProjectRq({
            projectDetails: data.data,
          });
          setIsLoading(false);
        }
      });
    let reward_id = searchParams.get("reward");
    fetch(API_GET_REWARD + "?id=" + reward_id, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          setProjectRewards([data.data]);
          setIsLoading(false);
        }
      });
    setCurrency(getLocalDataObject("allCurrencyConversion"));
  }, []);

  const deleteReward = (reward) => {
    if (window.confirm("Delete the item?")) {
      let updatedProjectRewards;
      updatedProjectRewards = projectRewards.filter(function (item, i) {
        if (item.id !== reward.id) {
          return projectRewards[i];
        }
      });
      setProjectRewards(updatedProjectRewards); //on state
      setProjectReward(updatedProjectRewards, true); //on storage
    }
  };
  const CheckoutDetails = () => {
    const numbers = projectRewards;
    console.log("numbers", numbers);
    let total = 0;
    if (numbers && numbers.length > 0) {
      total = numbers.reduce((totalHolder, m) => totalHolder + m.amount, 0);
    }

    let amount = searchParams.get("amt");
    let extra = 0;
    if (amount >= total) {
      extra = amount - total;
      setExtraPrice(extra);
    }
    setTipPrice(amount);

    return (
      <>
        {projectRewards.map((reward, index) => (
          <tr>
            <td className="p-4">
              <div className="align-items-center">
                <div className="row">
                  <div className="col-md-2">
                    {reward.media.originalImage ? (
                      <>
                        <img
                          src={reward.media.originalImage}
                          className="d-block w-100 ui-bordered mr-4"
                          alt="..."
                        />
                      </>
                    ) : (
                      <>
                        <img
                          src="/images/reward-1.jpg"
                          alt="reward-1"
                          className="d-block w-100 ui-bordered mr-4"
                        />
                      </>
                    )}
                  </div>
                  <div className="col-md-8">
                    <div className="media-body">
                      {reward.title}
                      <p className="text-muted">{reward.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td className="text-right font-weight-semibold align-middle p-4">
              {projectDetails.eventAdmins[0].user.currency.code}{" "}
              {projectDetails.eventAdmins[0].user.currency.symbol}
              {reward.amount}
            </td>
            {/* <td className="text-center align-middle px-0">
              <a
                href="#!"
                className="shop-tooltip close float-none text-danger remove"
                title=""
                data-original-title="Remove"
                onClick={(e) => deleteReward(reward)}
              >
                x
              </a>
            </td> */}
          </tr>
        ))}
      </>
    );
  };
  const { projectDetails } = projectRq;

  return (
    <>
      <Helmet>
        <title>Pickfunder | Rewards</title>
      </Helmet>

      <section className="header" id="header">
        <div className="back-img">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 offset-lg-1">
                <div className="img-log-pcf pt-4 pt-lg-0">
                  <Link to="/">
                    <img src="/images/logo-2.png" alt="PickFunder" />
                  </Link>
                  <div className="img-log-pcf-text">
                    <h1>Your Support</h1>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <Header homepage={true} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {isLoading ? (
        <>
          <div className="coutom-spinner d-flex justify-content-center align-items-center">
            <div className="spinner-border text-light">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </>
      ) : (
        <>
          <section className="highlights bg-grey outer">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div className="outer position-relative">
                    <div className="card-body">
                      {isSuccess ? (
                        <>
                          <div className="text-center">
                            <i className="fas fa-check-circle success-icon"></i>
                            <h3>Successfully Pledged</h3>
                            <Link
                              className="btn btn-primary mt-5"
                              to={
                                `/project/${projectDetails.id}-` +
                                convertToSlug(projectDetails.name)
                              }
                            >
                              Back To Project
                            </Link>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="table-responsive">
                            <table className="table table-bordered m-0">
                              <thead>
                                <tr>
                                  <th className="text-center py-3 px-4">
                                    Reward Details
                                  </th>
                                  <th className="text-right py-3 px-4">
                                    Amount
                                  </th>
                                  {/* <th className="text-center align-middle py-3 px-0"></th> */}
                                </tr>
                              </thead>
                              <tbody>
                                <CheckoutDetails />
                                {extraPrice > 0 ? (
                                  <>
                                    <tr>
                                      <td>Additional Pledged Amount</td>
                                      <td>
                                        {
                                          projectDetails.eventAdmins[0].user
                                            .currency.code
                                        }{" "}
                                        {
                                          projectDetails.eventAdmins[0].user
                                            .currency.symbol
                                        }
                                        {extraPrice}
                                      </td>
                                    </tr>
                                  </>
                                ) : null}
                              </tbody>
                            </table>
                          </div>
                          <div className="row">
                            <div className="col-md-2 offset-md-10">
                              <div className="d-flex flex-wrap justify-content-between align-items-center pb-4">
                                <div className="d-flex">
                                  <div className="text-right mt-4">
                                    <label className="text-muted font-weight-normal m-0">
                                      Total price
                                    </label>
                                    <div className="text-large">
                                      <strong>
                                        {
                                          projectDetails.eventAdmins[0].user
                                            .currency.code
                                        }{" "}
                                        {
                                          projectDetails.eventAdmins[0].user
                                            .currency.symbol
                                        }
                                        {tipPrice}
                                      </strong>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 mt-5 mb-5">
                              <hr></hr>
                              <ShippingAddress />
                            </div>
                            <hr></hr>
                            <div className="col-md-6 mt-5 offset-md-3">
                              <Elements stripe={stripePromise}>
                                <CheckoutForm
                                  project={id}
                                  tipPrice={tipPrice}
                                />
                              </Elements>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      <Footer />
    </>
  );
}
