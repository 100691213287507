import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getLocalDataObject, getUserData } from "../../helpers/Storage";
import {
  convertToSlug,
  convertCurrency,
  totalGoalAchived,
} from "../../helpers/Helpers";
import { API_UPDATE_PROJECT } from "../../config/endpoints";
import { setLocalData } from "../../helpers/Storage";
import { ShowToast } from "../../helpers/Helpers";

export default class ProjectCard extends Component {
  constructor(props) {
    super(props);
    let userData = getUserData();
    let defaultArtist = getLocalDataObject("defaultArtist");
    this.state = {
      redirect: false,
      userProfile: userData.userProfile,
      isLoggedIn: userData.isLoggedIn,
      userArtistList: [],
      defaultArtist: defaultArtist,
      project: this.props.project,
      edit: this.props.edit,
      isEdit: false,
      isDeleteLoading: false,
    };
  }

  componentDidMount() {}
  editProject = (e, projectid) => {
    e.preventDefault();
    console.log("edit", projectid);
    setLocalData("pkeditid", projectid);
    this.setState({ isEdit: true });
    console.log(this.state);
  };
  deleteProject = (e, projectid) => {
    e.preventDefault();
    console.log("delete", projectid);
    let projectDetails = this.state.project;
    projectDetails.status = 3;
    this.setState({
      isDeleteLoading: true,
    });
    fetch(API_UPDATE_PROJECT, {
      method: "POST",
      body: JSON.stringify(projectDetails),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          let errorList = data.error.fieldErrors;
          if (errorList) {
            errorList.forEach((element) => {
              ShowToast("error", element.message);
            });
            this.setState((prevState) => ({
              isUpdateLoading: false,
            }));
          } else {
            ShowToast("error", "Something went wrong!");
          }
        } else {
          this.setState((prevState) => ({
            isDeleteLoading: false,
          }));

          ShowToast("success", "Project Deleted");
          this.setState((prevState) => ({
            redirect: "/mycampaigns",
          }));
        }
      });
  };
  render() {
    let project = this.state.project;
    if (this.state.isEdit) {
      window.location = "/editProject";
    }
    if (this.state.redirect) {
      window.location = this.state.redirect;
    }
    return (
      <>
        <Link
          className="nolink"
          to={`/project/${project.id}-` + convertToSlug(project.name)}
        >
          <div className="card">
            <div class="img-bottom-card-link">
              {project.avatar.originalImage ? (
                <>
                  <img
                    src={project.avatar.originalImage}
                    className="card-img-top img-fluid p-2"
                    alt="..."
                  />
                </>
              ) : (
                <>
                  <img
                    src="/images/card10.jpg"
                    className="card-img-top img-fluid p-2"
                    alt="..."
                  />
                </>
              )}
              <div class="card-img-overlay">
                {Object.entries(project.categories).map(
                  ([key, category], i) => (
                    <a href="#!" key={i}>
                      {category}
                    </a>
                  )
                )}
                <a href="javascript:void(0)">
                  <span> {project.daysLeft} Days Left</span>
                </a>
              </div>
            </div>

            <div className="card-body">
              <div className="card-title">
                <h4>{project.name}</h4>
                <article
                // dangerouslySetInnerHTML={{
                //   __html: project.short_description,
                // }}
                >
                  {project.short_description
                    ? project.short_description
                        .replace(/(<([^>]+)>)/gi, "")
                        .replace(/((&nbsp;))*/gim, "")
                        .slice(0, 120)
                    : ""}
                </article>
              </div>
              <div className="card-text mt-4">
                <p>
                  <small>
                    {totalGoalAchived(
                      project.eventAdmins[0].user.currency.code,
                      project.targetGoal,
                      project.backerDetails.totalAmount
                    )}
                    %
                  </small>
                </p>
                <div className="progress mb-3">
                  <div
                    className="progress-bar"
                    style={{
                      width:
                        totalGoalAchived(
                          project.eventAdmins[0].user.currency.code,
                          project.targetGoal,
                          project.backerDetails.totalAmount
                        ) + "%",
                    }}
                  ></div>
                </div>
                <span className="text-secondary">Raised: &nbsp;</span>
                <span className="text-success">
                  {project.eventAdmins[0].user.currency.symbol}
                  {/* {project.backerDetails.totalAmount} */}
                  {convertCurrency(
                    project.eventAdmins[0].user.currency.code,
                    project.backerDetails.totalAmount
                  )}
                </span>
                <span class="deo text-theme">
                  {project.eventAdmins[0].user.currency.symbol}
                  {project.targetGoal}
                </span>
                <span className="deo text-secondary">Goal: &nbsp;</span>

                {this.state.edit ? (
                  <>
                    <i
                      className="edit fa fa-pencil"
                      onClick={(e) => this.editProject(e, project.id)}
                    ></i>

                    {project.status == 4 ? (
                      <>
                        <span className="draft-status">Draft</span>
                      </>
                    ) : null}

                    {project.status === 4 ||
                    project.backerDetails.totalAmount === 0 ? (
                      <>
                        {this.state.isDeleteLoading ? (
                          <i
                            className="delete fa-solid fa-spinner fa-spin"
                            // onClick={(e) => this.deleteProject(e, project.id)}
                          ></i>
                        ) : (
                          <i
                            className="delete fa fa-trash"
                            onClick={(e) => this.deleteProject(e, project.id)}
                          ></i>
                        )}
                      </>
                    ) : null}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Link>
      </>
    );
  }
}
