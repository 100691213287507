import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  getLocalDataObject,
  getUserData,
  setLocalDataObject,
} from "../helpers/Storage";
import Autocomplete from "react-google-autocomplete";
import {
  API_CREATE_PROJECT,
  API_GET_CATEGORIES,
  API_UPLOAD_IMAGE,
  API_USER_PROFILE,
  API_ARTIST_CREATE,
  API_GET_MY_ARTISTS,
  API_GET_SOCIAL_LINKS,
  API_CREATE_SOCIAL_LINK,
  API_KYC,
} from "../config/endpoints";
import SimpleReactValidator from "simple-react-validator";
import { ShowToast } from "../helpers/Helpers";
import { Navigate } from "react-router-dom";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Switch from "react-switch";

const userData = getUserData();
const defaultArtist = getLocalDataObject("defaultArtist");
let initialState = {
  redirect: false,
  userProfile: userData.userProfile,
  isLoggedIn: userData.isLoggedIn,
  defaultArtist: defaultArtist,
  userArtistList: [],
  isLoading: false,
  profileFields: {
    fullname: "",
    avatar: "",
    hometown: "",
    about: "",
    stagename: "",
  },
  src: null,
  crop: {
    unit: "%",
    width: 30,
    aspect: 16 / 9,
  },
  cropModel: false,
  cropModelBanner: false,
  croppedImageUrl: false,
  croppedBannerUrl: false,
  social: {},
  socialLinks: [],
  socialLinksCheckboxes: [],
  addSocialLink: false,
  artistCreated: false,
};
export default class CreateArtist extends Component {
  constructor(props) {
    super(props);
    let userData = getUserData();
    let defaultArtist = getLocalDataObject("defaultArtist");
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.validator2 = new SimpleReactValidator({ autoForceUpdate: this });
  }

  componentDidMount() {
    window.addEventListener("storage", (e) => {
      // console.log("updated", e.key);
      if (e.key === "defaultArtist") {
        this.setState({
          defaultArtist: e.newValue,
        });
      }
    });
    const defaultArtist = getLocalDataObject("defaultArtist");
    let localUserData = getUserData();
    // console.log("default", defaultArtist);
    this.setState({
      defaultArtist: defaultArtist,
    });
    if (defaultArtist) {
      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          artist_ids: [defaultArtist.id],
        },
      }));
    }

    fetch(API_USER_PROFILE, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          this.setState((prevState) => ({
            profileFields: {
              ...prevState.fields,
              first_name: data.data.profile.firstName,
              last_name: data.data.profile.lastName,
              username: data.data.userName,
              handle: data.data.userName,
              stage_name: data.data.userName,
              phone_1: data.data.profile.phone1,
              email: data.data.email,
              bio: "",
              hometown: "",
              user_id: data.data.id,
            },
            isLoading: false,
          }));
        }
      });

    fetch(API_GET_SOCIAL_LINKS, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          this.setState((prevState) => ({
            socialLinks: data.data,
            isLoading: false,
          }));
        }
      });
  }
  handlePlaceChangeHomeTown = (place) => {
    console.log(place);
    this.setState((prevState) => ({
      profileFields: {
        ...prevState.profileFields,
        hometown: place.formatted_address,
      },
    }));
    console.log(this.state);
  };
  handleChangeProfile = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      profileFields: {
        ...prevState.profileFields,
        [e.target.name]: value,
      },
    }));
  };

  onOpenCropModal = () => {
    this.setState({ cropModel: true });
  };

  onCloseCropModal = (e, image) => {
    if (image == "image") {
      this.setState({ cropModel: false });
    } else {
      this.setState({ cropModelBanner: false });
    }
  };

  onSelectFile = (e, image) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      if (image == "image") {
        reader.addEventListener("load", () =>
          this.setState({ [image]: reader.result, cropModel: true })
        );
      } else {
        reader.addEventListener("load", () =>
          this.setState({ [image]: reader.result, cropModelBanner: true })
        );
      }

      reader.readAsDataURL(e.target.files[0]);
    }
  };
  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop, image) => {
    this.makeClientCrop(crop, image);
  };

  async makeClientCrop(crop, image) {
    if (this.imageRef && crop.width && crop.height) {
      const blobCropped = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      const croppedImageUrl = window.URL.createObjectURL(blobCropped);
      if (image == "image") {
        this.setState({ croppedImageUrl, blobCropped });
      } else {
        this.setState({ croppedBannerUrl: croppedImageUrl, blobCropped });
      }
    }
  }

  blobToFile = (theBlob, fileName) => {
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(),
      type: "image/jpeg",
    });
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          console.log(blob);
          resolve(blob);
        },
        "image/jpeg",
        1
      );
    });
  }

  saveCropImage = (e, image) => {
    if (image == "image") {
      this.setState({ cropModel: false });
    } else {
      this.setState({ cropModelBanner: false });
    }

    const formData = new FormData();
    var file = new File([this.state.blobCropped], "uploaded_file.jpeg", {
      type: "image/jpeg",
      lastModified: Date.now(),
    });
    formData.append("file", file);

    fetch(API_UPLOAD_IMAGE, {
      method: "POST",
      body: formData,
    })
      .then((r) => r.json())
      .then((data) => {
        if (image == "image") {
          this.setState((prevState) => ({
            profileFields: {
              ...prevState.profileFields,
              avatar: data.data[0].id,
            },
          }));
        } else {
          this.setState((prevState) => ({
            profileFields: {
              ...prevState.profileFields,
              banner_image: data.data[0].id,
            },
          }));
        }

        console.log(this.state);
      });
  };
  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  createArtist = (e) => {
    e.preventDefault();
    console.log("cliecked");
    if (this.validator.allValid()) {
      //
      console.log(this.state.profileFields);
      this.setState({ isLoading: true });
      fetch(API_ARTIST_CREATE, {
        method: "POST",
        body: JSON.stringify(this.state.profileFields),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            let errorList = data.error.fieldErrors;
            if (errorList) {
              errorList.forEach((element) => {
                ShowToast("error", element.message);
              });
              this.setState((prevState) => ({
                isLoading: false,
                // redirect: "/createproject",
              }));
            } else {
              ShowToast("error", "Something went wrong!");
            }
          } else {
            this.fetchArtistsListAfterCreate();
            this.setState((prevState) => ({
              // redirect: "/createproject",
              isLoading: false,
              artistCreated: true,
              artistCreatedDetails: data.data,
            }));
            console.log("created", data.data);
            console.log("created", this.state);
          }
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  fetchArtistsListAfterCreate() {
    fetch(API_GET_MY_ARTISTS, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          // this.fetchArtistsList();
        } else {
          console.log("arti", data.data);
          this.setState({
            userArtistList: data.data,
          });
          if (data.data.length > 0 && !this.state.defaultArtist) {
            // setLocalDataObject("defaultArtist", data.data[0]);
            ShowToast("success", "Profile Completed");
            this.setState((prevState) => ({ initialState }));
            this.setState({
              defaultArtist: data.data[0],
              // redirect: "/createproject",
              isLoading: false,
            });
          }
        }
      });
  }

  handleSocialCheck(e, id, social) {
    // console.log(social);
    let socialLinksCheckboxes = [...this.state.socialLinksCheckboxes];
    socialLinksCheckboxes[id] = e;
    this.setState({ socialLinksCheckboxes, addSocialLink: social }, () => {
      // console.log(this.state);
      //open model
      this.setState({ socialLinkModel: true });
    });
  }
  onCloseSocialModel(e, social) {
    console.log(social);
    console.log(this.state.socialLinksCheckboxes);
    let socialLinksCheckboxes = [...this.state.socialLinksCheckboxes];
    socialLinksCheckboxes[social.id] = false;
    this.setState({ socialLinksCheckboxes, addSocialLink: false }, () => {
      // console.log(this.state);
      //open model
      this.setState({ socialLinkModel: false });
      this.validator2.showMessageFor("social");
    });
  }
  handleChangeSocial = (e) => {
    this.validator2.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      social: {
        ...prevState.social,
        value: value,
      },
    }));
  };
  saveSocialLink = (e) => {
    if (this.validator2.fieldValid("social")) {
      this.validator2.hideMessages();
      let postData = {
        user_social_link: this.state.social.value,
        social_link_id: this.state.addSocialLink.id,
        is_active: 1,
        artist_id: this.state.artistCreatedDetails.id,
      };
      this.setState({ isSocialLinkSave: true });
      fetch(API_CREATE_SOCIAL_LINK, {
        method: "POST",
        body: JSON.stringify(postData),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            let errorList = data.error.fieldErrors;
            if (errorList) {
              errorList.forEach((element) => {
                ShowToast("error", element.message);
              });
              this.setState((prevState) => ({
                isSocialLinkSave: false,
                // redirect: "/createproject",
              }));
            } else {
              ShowToast("error", "Something went wrong!");
            }
          } else {
            ShowToast("success", "Social link saved.");
            this.setState((prevState) => ({
              isSocialLinkSave: false,
              socialLinkModel: false,
              // redirect: "/createproject",
            }));
            this.setState((prevState) => ({
              social: {
                ...prevState.social,
                value: "",
              },
            }));
          }
        });
      // this.saveDraft(e, true);
    } else {
      this.validator2.showMessageFor("social");
    }
  };
  render() {
    const { crop, croppedImageUrl, croppedBannerUrl } = this.state;
    if (this.state.redirect) {
      window.location = this.state.redirect;
    }
    return (
      <section className="complete-profile">
        {!this.state.artistCreated ? (
          <>
            <div className="row">
              <div className="col-12">
                <div className="form-frame">
                  <div className="row">
                    <label htmlFor="images">Avatar</label>
                    <div className="col-12">
                      <div>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => this.onSelectFile(e, "image")}
                          style={{ padding: "8px" }}
                        />
                      </div>
                      {croppedImageUrl && (
                        <img
                          alt="Avatar Image"
                          style={{ maxWidth: "200px" }}
                          src={croppedImageUrl}
                        />
                      )}
                      <Modal
                        open={this.state.cropModel}
                        onClose={(e) => this.onCloseCropModal(e, "image")}
                        closeOnOverlayClick={false}
                      >
                        {this.state.image && (
                          <>
                            <ReactCrop
                              src={this.state.image}
                              crop={this.state.crop}
                              onImageLoaded={this.onImageLoaded}
                              onComplete={(e) =>
                                this.onCropComplete(e, "image")
                              }
                              onChange={this.onCropChange}
                            />
                          </>
                        )}
                        <div className="text-center file-form ">
                          <a
                            className="btn"
                            onClick={(e) => this.saveCropImage(e, "image")}
                          >
                            Crop Image
                          </a>
                        </div>
                      </Modal>
                    </div>

                    <div className="form-frame">
                      <span>Upload avatar image</span>
                    </div>
                  </div>
                </div>

                <div className="form-frame">
                  <div className="row">
                    <label htmlFor="images">Banner</label>
                    <div className="col-12">
                      <div>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => this.onSelectFile(e, "banner")}
                          style={{ padding: "8px" }}
                        />
                      </div>
                      {croppedBannerUrl && (
                        <img
                          alt="Banner Image"
                          style={{ maxWidth: "200px" }}
                          src={croppedBannerUrl}
                        />
                      )}
                      <Modal
                        open={this.state.cropModelBanner}
                        onClose={(e) => this.onCloseCropModal(e, "banner")}
                        closeOnOverlayClick={false}
                      >
                        {this.state.banner && (
                          <>
                            <ReactCrop
                              src={this.state.banner}
                              crop={this.state.crop}
                              onImageLoaded={this.onImageLoaded}
                              onComplete={(e) =>
                                this.onCropComplete(e, "banner")
                              }
                              onChange={this.onCropChange}
                            />
                          </>
                        )}
                        <div className="text-center file-form ">
                          <a
                            className="btn"
                            onClick={(e) => this.saveCropImage(e, "banner")}
                          >
                            Crop Image
                          </a>
                        </div>
                      </Modal>
                    </div>
                    <div className="form-frame">
                      <span>Upload a banner image</span>
                    </div>
                  </div>
                </div>
                <div className="form-frame">
                  <label htmlFor="title">First Name</label>
                  <input
                    type="text"
                    name="first_name"
                    value={this.state.profileFields.first_name}
                    onChange={(e) => this.handleChangeProfile(e)}
                  />
                </div>
                <span className="error">
                  {this.validator.message(
                    "first_name",
                    this.state.profileFields.first_name,
                    "required"
                  )}
                </span>
              </div>
              <div className="col-12">
                <div className="form-frame">
                  <label htmlFor="title">Last Name</label>
                  <input
                    type="text"
                    name="last_name"
                    value={this.state.profileFields.last_name}
                    onChange={(e) => this.handleChangeProfile(e)}
                  />
                </div>
                <span className="error">
                  {this.validator.message(
                    "last_name",
                    this.state.profileFields.last_name,
                    "required"
                  )}
                </span>
              </div>
              <div className="col-12">
                <div className="form-frame">
                  <label htmlFor="title">Handle</label>
                  <input
                    type="text"
                    name="handle"
                    readOnly
                    value={this.state.profileFields.username}
                    // value={this.state.profileFields.handle}
                    onChange={(e) => this.handleChangeProfile(e)}
                  />
                </div>
                <div className="text-muted">
                  Please contact admin if you want to get your username updated.
                </div>
              </div>
              <div className="col-12">
                <div className="form-frame">
                  <label htmlFor="title">About</label>
                  <textarea
                    type="text"
                    name="bio"
                    value={this.state.profileFields.bio}
                    onChange={(e) => this.handleChangeProfile(e)}
                    rows={5}
                  ></textarea>
                </div>
                <span className="error">
                  {this.validator.message(
                    "bio",
                    this.state.profileFields.bio,
                    "required"
                  )}
                </span>
              </div>
              <div className="col-12">
                <div className="form-frame">
                  <label htmlFor="title">Hometown</label>
                  {/* <input
                type="text"
                name="hometown"
                value={this.state.profileFields.hometown}
                onChange={(e) => this.handleChangeProfile(e)}
              /> */}
                  <Autocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_API}
                    onPlaceSelected={(place) =>
                      this.handlePlaceChangeHomeTown(place)
                    }
                  />
                </div>
                <span className="error">
                  {this.validator.message(
                    "hometown",
                    this.state.profileFields.hometown,
                    "required"
                  )}
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-4 mb-5">
              <a href="/mycampaigns" className="btn-danger btn rounded-0">Cancel</a>
              <button
                className="theme-btn"
                onClick={this.createArtist}
                disabled={this.state.isLoading}
              >
                Complete Profile
                {this.state.isLoading ? (
                  <>
                    &nbsp;
                    <i className="fa-solid fa-spinner fa-spin"></i>
                  </>
                ) : (
                  <></>
                )}
              </button>
            </div>
          </>
        ) : (
          <>
            <h4>Update Social Links</h4>
            <div className="social-links-container mb-4 mt-4">
              {this.state.socialLinks.map((link, i) => (
                <>
                  <div className="row mt-2 align-items-center">
                    <div className="col-4">
                      <img src={link.media.largeImage} />
                    </div>
                    <div className="col-2">
                      <Switch
                        onChange={(e) =>
                          this.handleSocialCheck(e, link.id, link)
                        }
                        checked={this.state.socialLinksCheckboxes[link.id]}
                        uncheckedIcon={false}
                      />
                    </div>
                  </div>
                </>
              ))}
            </div>
            <div className="text-center mt-3 mb-4">
              <Link
                to={"/mycampaigns"}
                onClick={(e) =>
                  setLocalDataObject("defaultArtist", this.state.defaultArtist)
                }
                className="btn btn-grey"
              >
                Go to Dashboard
              </Link>
              &nbsp;
              <Link
                to={"/createproject"}
                onClick={(e) =>
                  setLocalDataObject("defaultArtist", this.state.defaultArtist)
                }
                className="btn btn-primary"
              >
                Create Project
              </Link>
            </div>
          </>
        )}

        <Modal
          open={this.state.socialLinkModel}
          onClose={(e) => this.onCloseSocialModel(e, this.state.addSocialLink)}
          closeOnOverlayClick={false}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="form-frame">
                <label>{this.state.addSocialLink.name}</label>
                <input
                  type={"text"}
                  className="form-control"
                  placeholder="Enter complete url"
                  name="social"
                  value={this.state.social.value}
                  onChange={(e) => this.handleChangeSocial(e)}
                />
                <span className="error">
                  {this.validator2.message(
                    "social",
                    this.state.social.value,
                    "required|url"
                  )}
                </span>
                <button
                  className="btn btn-primary mt-3"
                  onClick={this.saveSocialLink}
                  disabled={this.state.isSocialLinkSave}
                >
                  Save
                  {this.state.isSocialLinkSave ? (
                    <>
                      &nbsp;
                      <i className="fa-solid fa-spinner fa-spin"></i>
                    </>
                  ) : (
                    <></>
                  )}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </section>
    );
  }
}
