import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  getLocalDataObject,
  getUserData,
  setLocalDataObject,
} from "../helpers/Storage";

const userData = getUserData();
const defaultArtist = getLocalDataObject("defaultArtist");
let initialState = {
  redirect: false,
  userProfile: userData.userProfile,
  isLoggedIn: userData.isLoggedIn,
  defaultArtist: defaultArtist,
  userArtistList: [],
  isLoading: false,
};
export default class HomePageMenu extends Component {
  constructor(props) {
    super(props);
    let userData = getUserData();
    let defaultArtist = getLocalDataObject("defaultArtist");
    this.state = initialState;
  }

  render() {
    return (
      <div className="navbar-side">
        <input type="checkbox" id="click" />
        <label for="click" className="menu-btn">
          <i className="fa fa-bars"></i>
        </label>
        <div className="listing">
          <nav>
            <ul>
              <li>
                <Link to="/projects">Explore</Link>
              </li>
              <li>
                <Link to="/login">Raise Funding</Link>
              </li>
              <li>
                <Link to="/login">Login</Link>
              </li>
              <li>
                <Link to="/login">Search</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}
