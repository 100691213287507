import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Footer from "../components/sections/Footer";
import Header from "../components/sections/Header";
import { API_SEARCH_PROJECTS } from "../config/endpoints";
import { getLocalDataObject, getUserData } from "../helpers/Storage";
import ProjectCard from "../components/cards/ProjectCard";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ContentLoader from "react-content-loader";
import HomePageMenu from "../components/HomePageMenu";
import HomeLoginMenu from "../components/HomeLoginMenu";
export default class Index extends Component {
  constructor(props) {
    super(props);
    let userData = getUserData();
    let defaultArtist = getLocalDataObject("defaultArtist");
    this.state = {
      redirect: false,
      userProfile: userData.userProfile,
      isLoggedIn: userData.isLoggedIn,
      defaultArtist: defaultArtist,
      allProjectsList: [],
      isLoading: false,
    };
  }
  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    fetch(
      API_SEARCH_PROJECTS +
        "?event_type=project&page_size=10&sort_by=goal&start_date=" +
        Date.now(),
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          this.setState({
            allProjectsList: data.data,
            isLoading: false,
          });
        }
      });
  }
  render() {
    return (
      <>
        <Helmet>
          <title>pickfunder.com &#8211; Crowdfunding for Creators</title>
        </Helmet>
        <>
          <section className="header" id="header">
            <div className="back-img">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 offset-lg-1">
                    <div className="img-log-pcf pt-4 pt-lg-0">
                      <Link to="/">
                        <img src="images/logo-2.png" alt="PickFunder" />
                      </Link>
                      <div className="img-log-pcf-text">
                        <h1>Fund the Next Big Thing.</h1>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <Header homepage={true} />
                    {/* {this.state.isLoggedIn ? <Header homepage={true} /> : <></>}
                    {this.state.isLoggedIn ? <></> : <HomePageMenu />} */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 offset-lg-1 order-1 order-lg-0 position-relative">
                    <div className="music-img">
                      <img src="images/hero-image.png" width="380" />
                    </div>
                  </div>
                  <div className="col-lg-5 offset-lg-1">
                    <div className="music-text-man">
                      <p>
                        PickFunder, powered by PickleJar, unleashes the power of
                        crowdfunding for Artists and Creators who entertain and
                        delight us on stages and livestreams around the world.
                        Fans can now support their favorite Artists off stage
                        and receive updates along the way! The best and
                        brightest move their dreams forward with PickFunder!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="offset-lg-6 col-lg-5">
                  <div className="music-text-title-man">
                    <h3>Spark the creativity inspiring millions.</h3>
                    <p>
                      Support an Artist project - earn rewards and get more for
                      the music.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div class="stats text-center mt-lg-5 pt-lg-5">
                    <div className="row">
                      <div className="col-sm-6 col-md-4">
                        <div class="list">
                          <h4>61,54,070</h4>
                          <p>PickCoins Earned</p>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4">
                        <div class="list">
                          <h4>7,800</h4>
                          <p>Artists and Creators Supported</p>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4">
                        <div class="list">
                          <h4>$1,230,814</h4>
                          <p>Paid Out To Artists</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="project">
            <div className="container-fluid">
              <div className="container">
                <div className="row">
                  <div className="col-lg-11 offset-lg-1">
                    <div className="heading-text">
                      <h1>Explore Projects</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {this.state.isLoading ? (
                  <>
                    {" "}
                    <ContentLoader
                      speed={2}
                      width={800}
                      height={500}
                      viewBox="0 0 800 500"
                      backgroundColor="#f3f3f3"
                      foregroundColor="#ecebeb"
                    >
                      <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
                      <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
                      <rect x="0" y="56" rx="3" ry="3" width="607" height="9" />
                      <rect x="0" y="80" rx="3" ry="3" width="562" height="9" />
                      <circle cx="20" cy="20" r="20" />
                      <rect
                        x="608"
                        y="202"
                        rx="3"
                        ry="3"
                        width="607"
                        height="9"
                      />
                      <rect
                        x="608"
                        y="178"
                        rx="3"
                        ry="3"
                        width="562"
                        height="9"
                      />
                      <rect
                        x="608"
                        y="155"
                        rx="3"
                        ry="3"
                        width="263"
                        height="9"
                      />
                      <rect
                        x="-5"
                        y="128"
                        rx="3"
                        ry="3"
                        width="607"
                        height="9"
                      />
                      <rect
                        x="-5"
                        y="152"
                        rx="3"
                        ry="3"
                        width="502"
                        height="9"
                      />
                      <rect
                        x="-5"
                        y="175"
                        rx="3"
                        ry="3"
                        width="600"
                        height="9"
                      />
                      <rect
                        x="1"
                        y="105"
                        rx="3"
                        ry="3"
                        width="450"
                        height="9"
                      />
                      <rect
                        x="-1"
                        y="204"
                        rx="3"
                        ry="3"
                        width="607"
                        height="9"
                      />
                      <rect
                        x="-1"
                        y="228"
                        rx="3"
                        ry="3"
                        width="562"
                        height="9"
                      />
                      <rect
                        x="-6"
                        y="276"
                        rx="3"
                        ry="3"
                        width="607"
                        height="9"
                      />
                      <rect
                        x="-6"
                        y="300"
                        rx="3"
                        ry="3"
                        width="502"
                        height="9"
                      />
                      <rect
                        x="-6"
                        y="323"
                        rx="3"
                        ry="3"
                        width="600"
                        height="9"
                      />
                      <rect
                        x="0"
                        y="253"
                        rx="3"
                        ry="3"
                        width="450"
                        height="9"
                      />
                    </ContentLoader>
                  </>
                ) : (
                  <>
                    <OwlCarousel
                      className="owl-carousel owl-theme"
                      // loop
                      margin={10}
                      nav
                      responsive={{
                        0: {
                          items: 1,
                        },
                        600: {
                          items: 2,
                        },
                        1000: {
                          items: 3,
                        },
                        1366: {
                          items: 4,
                        },
                      }}
                    >
                      {this.state.allProjectsList.map((project, index) => (
                        <ProjectCard
                          key={index}
                          project={project}
                        ></ProjectCard>
                      ))}
                    </OwlCarousel>
                  </>
                )}
              </div>
            </div>
          </section>

          <section className="about-pro">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="text-text-pro">
                    <h3>Who We Are</h3>
                    <h4>Artists and Creators make MORE and KEEP 100%!</h4>
                    <p>
                      PickleJar is the intelligent live entertainment app for
                      Artists and Creators to make more money by lighting up
                      their fans like never before!
                    </p>
                  </div>
                </div>
                <div className="col-lg-10 offset-lg-1">
                  <div className="funder-title">
                    <h4>Funding UNLOCKS at 25% of GOAL!</h4>
                    <p>
                      We listened to Artists and Creators who don’t just need
                      funding…they need funding along the way. With PickFunder,
                      they can raise capital for specific projects and have
                      IMMEDIATE access to the funds when they hit 25% of the
                      goal amount. Plus, Artists and Creators pay no fees and
                      Fans always earn PickCoins with every project they
                      support!
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Download App Button links */}

            {/* <div className="container">
                <div className="row">
                  <div className="col-lg-2">
                    <div className="pant-button-2">
                      <a href="https://apps.apple.com/us/app/picklejar-live/id1517697776">
                        <img
                          src="images/app-1.png"
                          className="img-fluid"
                          width="200"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="pant-button-2">
                      <a href="https://play.google.com/store/apps/details?id=com.Reatro.picklejar">
                        <img
                          src="images/google-pay-1.png"
                          className="img-fluid"
                          width="210"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}
          </section>

          <div className="free-funding">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 offset-lg-1">
                  <div className="content">
                    <h3>
                      How our FREE funding platform helps Artists and Creators.
                    </h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="content-text">
                    <div className="icon-bar">
                      <img
                        src="images/creator.png"
                        className="img-fluid"
                        width="50px"
                      />
                      <span></span>
                    </div>
                    <h3>Artists and Creators Keep 100% </h3>
                    <p>
                      No fees are charged to the Artist or Creator for account
                      or transactions.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="content-text">
                    <div className="icon-bar">
                      <img
                        src="images/icon4.png"
                        className="img-fluid"
                        width="50px"
                      />
                      <span></span>
                    </div>
                    <h3>The Funder Dashboard</h3>
                    <p>
                      Funders have their own login where they can stay up to
                      date.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="content-text">
                    <div className="icon-bar">
                      <img
                        src="images/icon3.png"
                        className="img-fluid"
                        width="50px"
                      />
                      <span></span>
                    </div>
                    <h3>The Creator Dashboard</h3>
                    <p>
                      Creators have 24/7 access to their data and can edit or
                      create new projects.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="content-text">
                    <div className="icon-bar">
                      <img
                        src="images/icon5.png"
                        className="img-fluid"
                        width="50px"
                      />
                      <span></span>
                    </div>
                    <h3>Set Deadline Project</h3>
                    <p>
                      Let your funders know what your timeline is for your
                      project.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="content-text">
                    <div className="icon-bar">
                      <img
                        src="images/icon6.png"
                        className="img-fluid"
                        width="50px"
                      />
                      <span></span>
                    </div>
                    <h3>Unlimited Projects</h3>
                    <p>
                      Allows you to be specific about what your needs and goals
                      are.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="content-text stadn-project">
                    <h3>Get Started.</h3>
                    <p>
                      You will never be charged or asked to pay for anything
                      with PickFunder powering your dreams.
                    </p>
                    {this.state.isLoggedIn ? (
                      <Link to="/createproject" className="btn">
                        create new project
                      </Link>
                    ) : (
                      <Link to="/login" className="btn">
                        create new project
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        <Footer />
      </>
    );
  }
}
