import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import {
  API_GET_DEFAULT_CURRENCY,
  API_GET_MY_ARTISTS,
  API_GET_MY_BANDS,
  API_GET_ALL_CURRENCY_CONVERSION,
  API_GET_CATEGORIES,
} from "../../config/endpoints";
import {
  getLocalDataObject,
  getUserData,
  logoutUser,
  setLocalDataObject,
} from "../../helpers/Storage";
import { isArtist } from "../../helpers/Helpers";
import { convertToSlug } from "../../helpers/Helpers";
import { Modal } from "react-responsive-modal";
import ContentLoader from "react-content-loader";

export default class Header extends Component {
  constructor(props) {
    super(props);
    let userData = getUserData();
    let defaultArtist = getLocalDataObject("defaultArtist");
    this.state = {
      redirect: false,
      userProfile: userData.userProfile,
      isLoggedIn: userData.isLoggedIn,
      userArtistList: [],
      userBandList: [],
      defaultArtist: defaultArtist,
      artistModal: false,
      categoriesList: [],
      isLoadingCategory: true,
      showSearchInput: false,
      searchKeyword: "",
      isArtistLoading: false,
      homePageHeader: this.props.homepage,
    };
  }

  componentDidMount() {
    if (this.state.isLoggedIn) {
      this.fetchArtistBandList();
    }
    this.fetchCategoriesList();
    this.fetchCurrencyDetails();
    console.log(isArtist(getLocalDataObject("defaultArtist")));
  }

  fetchCategoriesList() {
    fetch(API_GET_CATEGORIES, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          this.setState({
            categoriesList: data.output.data,
            isLoadingCategory: false,
          });
        }
      });
  }

  fetchCurrencyDetails() {
    fetch(API_GET_DEFAULT_CURRENCY, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          // console.log(data.data);
          setLocalDataObject("defaultCurrency", data.data);
        }
      });
    fetch(API_GET_ALL_CURRENCY_CONVERSION, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          setLocalDataObject("allCurrencyConversion", data.data);
        }
      });
  }
  fetchArtistBandList() {
    this.setState({
      isArtistLoading: true,
    });
    fetch(API_GET_MY_ARTISTS, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          this.setState({
            userArtistList: data.data,
            isArtistLoading: false,
          });
          if (data.data.length > 0 && !this.state.defaultArtist) {
            setLocalDataObject("defaultArtist", data.data[0]);
            this.setState({
              defaultArtist: data.data[0],
            });
          }
        }
      });
    fetch(API_GET_MY_BANDS, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          // console.log("arti", data.data);
          this.setState({
            userBandList: data.data,
            isArtistLoading: false,
          });
          // if (data.data.length > 0 && !this.state.defaultArtist) {
          //   if (!this.state.defaultArtist) {
          //     setLocalDataObject("defaultArtist", data.data[0]);
          //     this.setState({
          //       defaultArtist: data.data[0],
          //     });
          //   }
          // }
        }
      });
  }

  setDefaultArtist = (e, artist) => {
    setLocalDataObject("defaultArtist", artist);
    this.setState({
      defaultArtist: artist,
      artistModal: false,
    });
    window.location = "/mycampaigns";
  };

  handleLogout = (e) => {
    logoutUser();
    this.setState({ redirect: "/", isLoading: false });
  };

  openArtistModal = (e) => {
    this.setState({ artistModal: true });
  };

  closeArtistModal = (e) => {
    this.setState({ artistModal: false });
  };

  handleSearch = (e) => {
    // console.log(this.state.searchKeyword);
    if (this.state.searchKeyword === "") {
      this.setState({
        redirect: "/projects/",
      });
    } else {
      this.setState({
        redirect: "/projectssearch/" + this.state.searchKeyword,
      });
    }
  };

  render() {
    if (this.state.searchRedirect) {
      return <Navigate to={this.state.searchRedirect} />;
    }
    if (this.state.redirect) {
      window.location = this.state.redirect;
    }
    return (
      <>
        {this.state.homePageHeader ? (
          <>
            {this.state.isLoggedIn ? (
              <div className="navbar-side">
                <input type="checkbox" id="click" />
                <label for="click" className="menu-btn">
                  <i className="fa fa-bars"></i>
                </label>
                <div className="listing">
                  <nav>
                    <ul>
                      <li>
                        <Link to="/projects">Explore</Link>
                      </li>
                      <li>
                        <Link to="/createproject">Raise Funding</Link>
                      </li>
                      <li>
                        <Link to="/mycampaigns" className="dropdown-item-1">
                          My Projects
                        </Link>
                      </li>
                      <li>
                        <Link to="/myprofile" className="dropdown-item-1">
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <a
                          href="javascript:void(0)"
                          className="dropdown-item-1 select-artist"
                          onClick={this.openArtistModal}
                        >
                          {isArtist(getLocalDataObject("defaultArtist")) ? (
                            <>
                              Artist:{" "}
                              {this.state.defaultArtist
                                ? this.state.defaultArtist.handle
                                : "Select Artist"}
                            </>
                          ) : (
                            <>
                              <>
                                Band:{" "}
                                {this.state.defaultArtist &&
                                this.state.defaultArtist.band
                                  ? this.state.defaultArtist.band.handle
                                  : "Select Band"}
                              </>
                            </>
                          )}
                        </a>
                      </li>
                      <li>
                        <Link to={""} onClick={this.handleLogout}>
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            ) : (
              <>
                <div className="navbar-side">
                  <input type="checkbox" id="click" />
                  <label for="click" className="menu-btn">
                    <i className="fa fa-bars"></i>
                  </label>
                  <div className="listing">
                    <nav>
                      <ul>
                        <li>
                          <Link to="/projects">Explore</Link>
                        </li>
                        <li>
                          <Link to="/login">Raise Funding</Link>
                        </li>
                        <li>
                          <Link to="/login">Login</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <header>
              <section className="container-fluid">
                <nav className="nav-first">
                  <ul className="d-flex justify-content-between align-items-center">
                    <div className="d-flex d-none d-md-block">
                      <li>
                        <Link to="/projects">Explore</Link>
                      </li>
                      <li>
                        {this.state.isLoggedIn ? (
                          <Link to="/createproject">Raise Funding</Link>
                        ) : (
                          <Link to="/login">Raise Funding</Link>
                        )}
                      </li>
                    </div>
                    <div className="mx-auto">
                      <Link to={"/"}>
                        <img
                          src="/images/brand.png"
                          alt=""
                          className="img-fluid mt-3"
                          width="90"
                        />
                      </Link>
                    </div>
                    <div className="d-flex d-none d-md-block desktop-menu search-icon">
                      {this.state.showSearchInput ? (
                        <>
                          <div className="search-block">
                            <input
                              type={"text"}
                              name="search"
                              className="search-input"
                              placeholder="Search projects here"
                              value={this.state.searchKeyword}
                              onChange={(e) =>
                                this.setState({ searchKeyword: e.target.value })
                              }
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  this.handleSearch();
                                }
                              }}
                            ></input>
                            <button
                              className="search-btn"
                              onClick={(e) => this.handleSearch()}
                            >
                              <i className="fa-solid fa-circle-chevron-right"></i>
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <li>
                            <a
                              href="#"
                              onClick={(e) =>
                                this.setState({ showSearchInput: true })
                              }
                            >
                              Search <i className="fa fa-search hidden"></i>
                            </a>
                          </li>
                        </>
                      )}

                      {this.state.isLoggedIn ? (
                        <>
                          <span className="dropdown">
                            <a
                              href=""
                              type="button"
                              className=" "
                              data-bs-toggle="dropdown"
                            >
                              <i className="fa-solid fa-circle-user"></i>
                            </a>
                            <ul className="drop-menu dropdown-menu">
                              <div className="row">
                                <div className="col-md-5">
                                  <div className="artist-title">
                                    <div className="heading-title-user-form">
                                      <h3>YOUR ACCOUNT</h3>
                                    </div>
                                    <li>
                                      <a
                                        href="javascript:void(0)"
                                        className="dropdown-item-1 select-artist"
                                        onClick={this.openArtistModal}
                                      >
                                        {isArtist(
                                          getLocalDataObject("defaultArtist")
                                        ) ? (
                                          <>
                                            Artist:{" "}
                                            {this.state.defaultArtist
                                              ? this.state.defaultArtist.handle
                                              : "Select Artist"}
                                          </>
                                        ) : (
                                          <>
                                            <>
                                              Band:{" "}
                                              {this.state.defaultArtist &&
                                              this.state.defaultArtist.band
                                                ? this.state.defaultArtist.band
                                                    .handle
                                                : "Select Band"}
                                            </>
                                          </>
                                        )}
                                      </a>
                                    </li>
                                    <li>
                                      <Link
                                        to="/createproject"
                                        className="dropdown-item-1"
                                      >
                                        Raise Funding
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/mycampaigns"
                                        className="dropdown-item-1"
                                      >
                                        My Projects
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/myprofile"
                                        className="dropdown-item-1"
                                      >
                                        My Profile
                                      </Link>
                                    </li>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="modal-abput-titt-3">
                                    <div className="heading-title-user-form">
                                      <h5>CREATED PROJECTS</h5>
                                    </div>
                                    <Link
                                      to={"/createproject"}
                                      className="button-5"
                                    >
                                      <i className="fa fa-plus"></i>
                                      <span>New</span>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <hr className="menu-line-form" />
                              <div
                                className="log-out-parson"
                                onClick={this.handleLogout}
                              >
                                <span>Logout</span>
                              </div>
                            </ul>
                          </span>
                        </>
                      ) : (
                        <>
                          <li>
                            <Link to="/login">Login</Link>
                          </li>
                        </>
                      )}
                    </div>
                  </ul>
                </nav>
              </section>
              <hr />
              <section className="container-fluid">
                <>
                  <nav className="nav1">
                    <ul className="d-flex align-items-center justify-content-between  d-flex d-block d-md-none">
                      <div>
                        {this.state.isLoggedIn ? (
                          <li>
                            <Link to={"/mycampaigns"}>My Campaigns</Link>
                          </li>
                        ) : (
                          <li>
                            <Link to={"/projects"}>Explore Projects</Link>
                          </li>
                        )}
                      </div>
                      <div className="search-icon">
                        {this.state.showSearchInput ? (
                          <>
                            <div className="search-block">
                              <input
                                type={"text"}
                                name="search"
                                className="search-input-mobile"
                                placeholder="Search projects here"
                                value={this.state.searchKeyword}
                                onChange={(e) =>
                                  this.setState({
                                    searchKeyword: e.target.value,
                                  })
                                }
                                onKeyPress={(event) => {
                                  if (event.key === "Enter") {
                                    this.handleSearch();
                                  }
                                }}
                              ></input>
                              <button
                                className="search-btn-mobile"
                                onClick={(e) => this.handleSearch()}
                              >
                                <i className="fa-solid fa-circle-chevron-right"></i>
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <li>
                              <a
                                href="#"
                                onClick={(e) =>
                                  this.setState({ showSearchInput: true })
                                }
                              >
                                Search <i className="fa fa-search hidden"></i>
                              </a>
                            </li>
                          </>
                        )}
                        {this.state.isLoggedIn ? (
                          <>
                            {" "}
                            <a
                              href=""
                              type="button"
                              className="user-meaing-frat"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                            >
                              <i className="fa-solid fa-circle-user"></i>
                            </a>
                            <div
                              className="modal fade"
                              id="staticBackdrop"
                              data-bs-backdrop="false"
                              data-bs-keyboard="false"
                              tabindex="-1"
                              aria-labelledby="staticBackdropLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog modal-fullscreen">
                                <div className="modal-content">
                                  <div className="modal-body">
                                    <section className="container-fluid">
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <div className="modal-title-mo">
                                            <h5
                                              className="modal-title"
                                              id="staticBackdropLabel"
                                            ></h5>
                                            <button
                                              type="button"
                                              className="btn-close float-end"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                            <a href="#">YOUR ACCOUNT</a>
                                          </div>
                                          <h />
                                          <div className="modal-sub">
                                            <ul>
                                              <li>
                                                <a
                                                  href="javascript:void(0)"
                                                  className="select-artist"
                                                  onClick={this.openArtistModal}
                                                  data-bs-dismiss="modal"
                                                  aria-label="Close"
                                                >
                                                  Artist:{" "}
                                                  {this.state.defaultArtist
                                                    ? this.state.defaultArtist
                                                        .handle
                                                    : "Select Artist"}
                                                </a>
                                              </li>
                                              <li>
                                                <Link to="/createproject">
                                                  Raise Funding
                                                </Link>
                                              </li>
                                              <li>
                                                <Link to="/mycampaigns">
                                                  My Projects
                                                </Link>
                                              </li>
                                              <li>
                                                <Link to="/myprofile">
                                                  My Profile
                                                </Link>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="col-lg-6">
                                          <div className="min-abput-titt">
                                            <div className="finsh-title">
                                              <h3>Created Projects</h3>
                                            </div>
                                            <div className="sen-icon-text-title">
                                              <i className="fa fa-plus"></i>
                                              <Link to={"/createproject"}>
                                                New
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                        <hr className="line-mistaking" />
                                        <ul>
                                          <li>
                                            <a
                                              href="javascript:void(0)"
                                              onClick={this.handleLogout}
                                            >
                                              Logout
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <li>
                              <Link to={"/login"}>Login</Link>
                            </li>
                          </>
                        )}
                      </div>
                    </ul>
                  </nav>
                </>
              </section>
              <hr className="d-flex d-block d-md-none" />
              <section className="conatainer-fluid">
                {this.state.isLoadingCategory ? (
                  <>
                    <ContentLoader
                      speed={2}
                      width={"100%"}
                      height={50}
                      viewBox="0 0 500 40"
                      backgroundColor="#f3f3f3"
                      foregroundColor="#ecebeb"
                      // {...props}
                    >
                      <rect x="15" y="5" rx="3" ry="3" width="439" height="6" />
                      <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
                      <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
                      <rect
                        x="16"
                        y="21"
                        rx="3"
                        ry="3"
                        width="439"
                        height="6"
                      />
                    </ContentLoader>
                  </>
                ) : (
                  <div className="thrid">
                    {this.state.categoriesList.map((category, index) => (
                      <a
                        key={index}
                        href={
                          `/projectsfind/${category.id}-` +
                          convertToSlug(category.name)
                        }
                      >
                        {category.name}
                      </a>
                    ))}
                  </div>
                )}
              </section>
              <hr />
            </header>
            <header className="hidden">
              <nav className="menu-back d-lg-none d-md-block">
                <ul className="navbar d-flex justify-content-center">
                  {this.state.isLoggedIn ? (
                    <>
                      <li className="user nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          id="navbarDarkDropdownMenuLink"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="fa-solid fa-circle-user"></i>{" "}
                          {/* {this.state.userProfile.fullName} */}
                          My Account
                        </a>
                        <ul
                          className="dropdown-menu dropdown-menu-dark"
                          aria-labelledby="navbarDarkDropdownMenuLink"
                        >
                          <li>
                            <div
                              className="artist-active select-artist"
                              onClick={this.openArtistModal}
                            >
                              <span>Artist: </span>
                              {this.state.defaultArtist
                                ? this.state.defaultArtist.handle
                                : "Select Artist"}
                            </div>
                          </li>
                          <li>
                            <Link to="/createproject" className="dropdown-item">
                              Raise Funding
                            </Link>
                          </li>
                          <li>
                            <Link to="/mycampaigns" className="dropdown-item">
                              My Projects
                            </Link>
                          </li>
                          <li>
                            <Link to="/myprofile" className="dropdown-item">
                              My Profile
                            </Link>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#!"
                              onClick={this.handleLogout}
                            >
                              Logout
                            </a>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <li className="user">
                      <Link to="/login">
                        <div className="icon-bar">
                          <i className="fa-solid fa-circle-user"></i>
                          My Account
                        </div>
                      </Link>
                    </li>
                  )}
                </ul>
                <hr />
              </nav>
              <nav className="mnav navbar-dark bg-dark d-none d-lg-block fixed-top">
                <ul className="nav-bar d-flex justify-content-center justify-content-evenly ">
                  <li className="user-us"></li>
                  {this.state.isLoggedIn ? (
                    <>
                      <li className="user nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          id="navbarDarkDropdownMenuLink"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="fa-solid fa-circle-user"></i>{" "}
                          {this.state.userProfile.fullName}
                        </a>
                        <ul
                          className="dropdown-menu dropdown-menu-dark"
                          aria-labelledby="navbarDarkDropdownMenuLink"
                        >
                          <li>
                            <div
                              className="artist-active select-artist"
                              onClick={this.openArtistModal}
                            >
                              <span>Artist: </span>
                              {this.state.defaultArtist
                                ? this.state.defaultArtist.handle
                                : "Select Artist"}
                            </div>
                          </li>
                          <li>
                            <Link to="/createproject" className="dropdown-item">
                              Raise Funding
                            </Link>
                          </li>
                          <li>
                            <Link to="/mycampaigns" className="dropdown-item">
                              My Projects
                            </Link>
                          </li>
                          <li>
                            <Link to="/myprofile" className="dropdown-item">
                              My Profile
                            </Link>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#!"
                              onClick={this.handleLogout}
                            >
                              Logout
                            </a>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <li className="user">
                      <Link to="/login">
                        <div className="icon-bar">
                          <i className="fa-solid fa-circle-user"></i>
                          My Account
                        </div>
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
              <nav className="navbar navbar-expand-lg navbar-light bg-white">
                <div className="container">
                  <Link to="/" className="navbar-brand mx-auto">
                    <img
                      src="/images/logo.png"
                      alt=""
                      className="img-fluid"
                      width="450px"
                    />
                  </Link>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                      <li className="nav-item">
                        <Link to="/projects" className="nav-link">
                          Projects
                        </Link>
                      </li>

                      {this.state.isLoggedIn ? (
                        <Link to={"/createproject"} className="butt">
                          Raise Funding
                        </Link>
                      ) : (
                        <Link to={"/login"} className="butt">
                          Raise Funding
                        </Link>
                      )}
                    </ul>
                  </div>
                </div>
              </nav>
            </header>
          </>
        )}

        <Modal
          open={this.state.artistModal}
          onClose={this.closeArtistModal}
          center
        >
          <h3>Select Artist Or Band</h3>
          {this.state.isArtistLoading ? (
            <>
              <i class="fa-solid fa-spinner fa-spin"></i>
            </>
          ) : (
            <>
              <ul className="artist-list">
                {this.state.userArtistList.length ? (
                  this.state.userArtistList.map((artist, index) => (
                    <li key={index}>
                      <div className="row">
                        <div className="col-2">
                          {artist.avatar.originalImage ? (
                            <>
                              <img
                                src={artist.avatar.originalImage}
                                className="card-img-top img-fluid"
                                alt="..."
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src={"/images/PJ_ProfileIcon.png"}
                                className="card-img-top img-fluid"
                                alt="..."
                              />
                            </>
                          )}
                        </div>
                        <div className="col-10 align-items-center d-flex">
                          {" "}
                          <a
                            className={
                              this.state.defaultArtist &&
                              this.state.defaultArtist.id === artist.id
                                ? "active"
                                : ""
                            }
                            href="#!"
                            onClick={(e) => this.setDefaultArtist(e, artist)}
                          >
                            {artist.handle}
                          </a>
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <>
                    <p>Artist profile is not created yet.</p>
                  </>
                )}
                {this.state.userBandList.map((band, index) => (
                  <li key={index}>
                    <div className="row">
                      <div className="col-2">
                        {band.band.avatar.originalImage ? (
                          <>
                            <img
                              src={band.band.avatar.originalImage}
                              className="card-img-top img-fluid"
                              alt="..."
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src={"/images/PJ_ProfileIcon.png"}
                              className="card-img-top img-fluid"
                              alt="..."
                            />
                          </>
                        )}
                      </div>
                      <div className="col-10 align-items-center d-flex">
                        {" "}
                        <a
                          className={
                            this.state.defaultArtist &&
                            this.state.defaultArtist.band &&
                            this.state.defaultArtist.band.id === band.band.id
                              ? "active"
                              : ""
                          }
                          href="#!"
                          onClick={(e) => this.setDefaultArtist(e, band)}
                        >
                          {band.band.handle}
                        </a>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}
        </Modal>
      </>
    );
  }
}
