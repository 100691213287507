import { toast } from "react-toastify";
import { getLocalDataObject } from "./Storage";
export const ShowToast = (type, title) => {
  let options = {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  if (type === "success") {
    toast.success(title, options);
  }

  if (type === "info") {
    toast.info(title, options);
  }

  if (type === "warning") {
    toast.warning(title, options);
  }

  if (type === "error") {
    toast.error(title, options);
  }
};

export const convertToSlug = (text) => {
  return text
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

export const commaSeprated = (string) => {
  let numArray = string.split(",");
  numArray.sort(function (a, b) {
    return a - b;
  });
  numArray = numArray.map((s) => s.trim());

  return numArray;
};

export const convertCurrency = (to, amt) => {
  // to = "CAD";
  // console.log(to);
  if (to == "") {
    to = "USD";
  }
  let allCurrency = getLocalDataObject("allCurrencyConversion");
  let defaultCurrency = getLocalDataObject("defaultCurrency");
  // console.log(allCurrency);
  let rate = 1;
  if (allCurrency && allCurrency.length < 0) {
    let conversion = allCurrency.filter(function (currency) {
      return currency.conversion_currency === to;
    });
    console.log(conversion[0]);
    rate = conversion[0].rate;
  }

  return Math.round(amt * rate);
};

export const totalGoalAchived = (to, goal, amt) => {
  // console.log(to);
  if (to == "") {
    to = "USD";
  }
  let allCurrency = getLocalDataObject("allCurrencyConversion");
  let defaultCurrency = getLocalDataObject("defaultCurrency");
  let rate = 1;
  if (allCurrency && allCurrency.length < 0) {
    let conversion = allCurrency.filter(function (currency) {
      return currency.conversion_currency === to;
    });
    // console.log(conversion[0]);
    rate = conversion[0].rate;
  }
  let convertedAmount = Math.round(amt * rate);
  let goalAchived = (convertedAmount / goal) * 100;
  goalAchived = goalAchived.toFixed(2);
  if (isNaN(goalAchived)) {
    goalAchived = 0;
  }
  return goalAchived;
};

export const isArtist = (data) => {
  if (data) {
    return data.band ? false : true;
  }
};

export const socialIcon = (social) => {
  switch (social) {
    case "Spotify":
      return "fa-brands fa-spotify";
      break;
    case "Facebook":
      return "fa-brands fa-facebook";
      break;
    case "Instagram":
      return "fa-brands fa-instagram";
      break;
    case "Twitter":
      return "fa-brands fa-twitter";
      break;
    case "Reddit":
      return "fa-brands fa-reddit";
      break;
    case "Apple Music":
      return "fa-brands fa-airplay";
      break;
    case "Twitch":
      return "fa-brands fa-twitch";
      break;
    case "Tiktok":
      return "fa-brands fa-tiktok";
      break;

    default:
      break;
  }
};
