import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/sections/Footer";
import Header from "../components/sections/Header";
import { getLocalDataObject, getUserData } from "../helpers/Storage";
import SimpleReactValidator from "simple-react-validator";
import { API_CONTACT } from "../config/endpoints";
import { ShowToast } from "../helpers/Helpers";
let userData = getUserData();
let defaultArtist = getLocalDataObject("defaultArtist");
const initialState = {
  redirect: false,
  userProfile: userData.userProfile,
  isLoggedIn: userData.isLoggedIn,
  defaultArtist: defaultArtist,
  isLoading: false,
  allProjectsList: [],
  fields: {
    email: "",
    name: "",
    message: "",
    subject: "",
  },
};
export default class NotFound extends Component {
  constructor(props) {
    super(props);
    let userData = getUserData();
    let defaultArtist = getLocalDataObject("defaultArtist");
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }
  componentDidMount() {}

  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Pickfunder | Not Found</title>
        </Helmet>
        <Header />
        {this.state.isLoading ? (
          <>
            <div className="coutom-spinner d-flex justify-content-center align-items-center">
              <div className="spinner-border text-light">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </>
        ) : (
          <div className="Connect-noe">
            <div className="container">
              <div className="row">
                <div
                  className="col-md-12 align-items-center text-center"
                  style={{ minHeight: "500px" }}
                >
                  <h1>Opps! 404</h1>
                  <p>Page not found.</p>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </>
    );
  }
}
