import React, { useState, useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Helmet } from "react-helmet";
import Footer from "../components/sections/Footer";
import Header from "../components/sections/Header";
import {
  API_VIEW_PROJECT,
  API_USER_CARD_ADD,
  API_SEND_TIP,
  API_FOLLOW_PROJECT,
  API_UNFOLLOW_PROJECT,
  API_CHECK_FOLLOW_PROJECT,
  API_PROJECT_POST_UPDATE,
  API_PROJECT_DELETE_UPDATE,
} from "../config/endpoints";
import {
  getLocalDataObject,
  getUserData,
  setProjectReward,
  setLocalData,
} from "../helpers/Storage";
import { Link, useParams } from "react-router-dom";
import {
  ShowToast,
  commaSeprated,
  convertCurrency,
  totalGoalAchived,
} from "../helpers/Helpers";
import ReactPlayer from "react-player";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import ShareCard from "../components/cards/ShareCard";
import ContentLoader from "react-content-loader";
import ReCAPTCHA from "react-google-recaptcha";
import GetCardList from "../components/GetCardList";

let userData = getUserData();
let defaultArtist = getLocalDataObject("defaultArtist");
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_KEY);

export default function ProjectView() {
  const editorRef = useRef(null);

  const [projectRq, setProjectRq] = useState({
    projectDetails: {
      name: "",
      avatar: { originalImage: false },
      backerDetails: { totalAmount: 0, totalBackers: 0, goalCompleted: 0 },
      projectRewards: [],
      address: { formatted_address: "" },
      predefined_amounts: "",
      projectUpdates: [],
      eventAdmins: [
        {
          user: {
            currency: { code: "", symbol: "" },
          },
        },
      ],
      eventParticipants: [
        {
          artist: {},
          band: {},
        },
      ],
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
  const [backCampaign, setBackCampaign] = useState(false);
  const [tipPrice, setTipPrice] = useState(10);
  const [followColor, setFollowColor] = useState("white");
  const [projectRewardId, setProjectRewardId] = useState(false);
  const [projectRewardTxt, setProjectRewardTxt] = useState([]);
  const [projectRewardLink, setProjectRewardLink] = useState([]);
  const [projectRewardAmt, setProjectRewardAmt] = useState();
  // const [captchaValue, setCaptchaValue] = useState();

  let { id } = useParams();
  //split id
  id = id.split("-")[0];
  let captchaValue = false;
  const CheckoutForm = (project) => {
    const stripe = useStripe();
    const elements = useElements();
    const handleSubmit = async (event) => {
      event.preventDefault();

      if (elements == null) {
        return;
      }

      if (!captchaValue) {
        ShowToast("error", "Captcha is required!");
        return;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      });

      if (paymentMethod) {
        const cardElement = elements.getElement(CardNumberElement);
        let token = await stripe.createToken(cardElement, { name: "Dama" });
        setIsLoading(true);
        //create stripe user
        fetch(API_USER_CARD_ADD, {
          method: "POST",
          body: JSON.stringify({ token: token.token.id }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (!data.success) {
              let errorList = data.error.fieldErrors;
              if (errorList) {
                errorList.forEach((element) => {
                  ShowToast("error", element.message);
                });
              } else {
                ShowToast("error", "Something went wrong!");
              }
              setIsLoading(false);
            } else {
              //send payment
              fetch(API_SEND_TIP, {
                method: "POST",
                body: JSON.stringify({
                  sender_id: userData.user.id,
                  amount_tipped: tipPrice,
                  event_id: project.project,
                  message: "",
                  card_id: data.data.card_id,
                  reCaptcha: captchaValue,
                }),
              })
                .then((response) => response.json())
                .then((data) => {
                  if (!data.success) {
                    let errorList = data.error.fieldErrors;
                    if (errorList) {
                      errorList.forEach((element) => {
                        ShowToast("error", element.message);
                      });
                    } else {
                      ShowToast("error", "Something went wrong!");
                    }
                    setIsLoading(false);
                  } else {
                    console.log("payment", data);
                    ShowToast("success", "Payment completed.");
                    setIsLoading(false);
                    window.location = "";
                  }
                });
            }
          });
      }
    };

    const onCardPayment = async (cardId) => {
      console.log(cardId);
      setIsLoading(true);
      fetch(API_SEND_TIP, {
        method: "POST",
        body: JSON.stringify({
          sender_id: userData.user.id,
          amount_tipped: tipPrice,
          event_id: project.project,
          message: "",
          card_id: cardId,
          reCaptcha: captchaValue,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            let errorList = data.error.fieldErrors;
            if (errorList) {
              errorList.forEach((element) => {
                ShowToast("error", element.message);
              });
            } else {
              ShowToast("error", "Something went wrong!");
            }
            setIsLoading(false);
          } else {
            console.log("payment", data);
            ShowToast("success", "Payment completed.");
            setIsLoading(false);
            window.location = "";
          }
        });
    };

    return (
      <>
        <GetCardList onCardClick={onCardPayment} />
        <h4>Or</h4>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <CardNumberElement className="payment-card" />
            </div>
            <div className="col-md-6">
              <CardExpiryElement className="payment-card" />
            </div>
            <div className="col-md-6">
              <CardCvcElement className="payment-card" />
            </div>
            <div className="col-md-12">
              <input
                type={"text"}
                className="payment-card"
                placeholder="Zip Code"
              />
            </div>
          </div>

          {/* <CardElement
          className="payment-card"
          options={{
            style: {
              base: {
                backgroundColor: "white",
              },
            },
          }}
        /> */}
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            onChange={onCaptchaChange}
            className="mt-4"
          />
          <button
            type="submit"
            className="pay-button"
            disabled={!stripe || !elements}
          >
            {/* Pay ${tipPrice} */}
            Back Project
          </button>
        </form>
      </>
    );
  };

  const onCaptchaChange = (value) => {
    console.log("Captcha value:", value);
    // setCaptchaValue(value);
    captchaValue = value;
  };

  const saveUpdate = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
      let update = editorRef.current.getContent();
      setIsBtnLoading(true);
      fetch(API_PROJECT_POST_UPDATE, {
        method: "POST",
        body: JSON.stringify({
          update: update,
          event_id: id,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            let errorList = data.error.fieldErrors;
            if (errorList) {
              errorList.forEach((element) => {
                ShowToast("error", element.message);
              });
            } else {
              ShowToast("error", "Something went wrong!");
            }
          } else {
            console.log("updated", data.data);
            setProjectRq({
              projectDetails: data.data,
            });
            setIsBtnLoading(false);

            // let obj = data.data;
            // if (data.data.length === 0) {
            //   setFollowColor("white");
            // } else {
            //   setFollowColor("red");
            // }
          }
        });
    }
  };

  const deleteUpdate = (id) => {
    if (window.confirm("Are you sure to delete this record?")) {
      if (editorRef.current) {
        console.log(editorRef.current.getContent());
        let update = editorRef.current.getContent();
        setIsDeleteBtnLoading(true);
        fetch(API_PROJECT_DELETE_UPDATE, {
          method: "POST",
          body: JSON.stringify({
            id: id,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (!data.success) {
              let errorList = data.error.fieldErrors;
              if (errorList) {
                errorList.forEach((element) => {
                  ShowToast("error", element.message);
                });
              } else {
                ShowToast("error", "Something went wrong!");
              }
            } else {
              console.log("updated", data.data);
              setProjectRq({
                projectDetails: data.data,
              });
              setIsDeleteBtnLoading(false);
            }
          });
      }
    } else {
      return false;
    }
  };
  useEffect(() => {
    setIsLoading(true);
    fetch(API_VIEW_PROJECT + "?id=" + id, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          setProjectRq({
            projectDetails: data.data,
          });
          setIsLoading(false);
        }
      });
    if (userData.isLoggedIn) {
      fetch(
        API_CHECK_FOLLOW_PROJECT +
          "?user_id=" +
          userData.user.id +
          "&event_id=" +
          id,
        {
          method: "POST",
          body: JSON.stringify({
            user_id: userData.user.id,
            event_id: id,
            notify_me: 1,
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            let errorList = data.error.fieldErrors;
            if (errorList) {
              errorList.forEach((element) => {
                ShowToast("error", element.message);
              });
            } else {
              ShowToast("error", "Something went wrong!");
            }
          } else {
            console.log("followed", data.data);
            let obj = data.data;
            if (data.data.length === 0) {
              setFollowColor("white");
            } else {
              setFollowColor("red");
            }
          }
        });
    }
    //set rewards
  }, []);

  const handleFollow = () => {
    if (userData.isLoggedIn) {
      setIsLoading(true);
      if (followColor == "red") {
        //unfollow
        fetch(
          API_UNFOLLOW_PROJECT +
            "?user_id=" +
            userData.user.id +
            "&event_id=" +
            id,
          {
            method: "POST",
            body: JSON.stringify({
              user_id: userData.user.id,
              event_id: id,
              notify_me: 1,
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (!data.success) {
              let errorList = data.error.fieldErrors;
              if (errorList) {
                errorList.forEach((element) => {
                  ShowToast("error", element.message);
                });
              } else {
                ShowToast("error", "Something went wrong!");
              }
              setIsLoading(false);
            } else {
              console.log("followed", data);
              ShowToast("success", "You unfollowed this project.");
              setIsLoading(false);
              setFollowColor("white");
            }
          });
      } else {
        fetch(API_FOLLOW_PROJECT, {
          method: "POST",
          body: JSON.stringify({
            user_id: userData.user.id,
            event_id: id,
            notify_me: 1,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (!data.success) {
              let errorList = data.error.fieldErrors;
              if (errorList) {
                errorList.forEach((element) => {
                  ShowToast("error", element.message);
                });
              } else {
                ShowToast("error", "Something went wrong!");
              }
              setIsLoading(false);
            } else {
              console.log("followed", data);
              ShowToast(
                "success",
                "Great! You are now following this project."
              );
              setIsLoading(false);
              setFollowColor("red");
            }
          });
      }
    } else {
      setLocalData("pk_redirect_login", window.location.href);
      window.location = "/login";
    }
  };

  const RewardBtn = (reward) => {
    let projectRewards = getLocalDataObject("projectRewards");
    let text = projectRewardTxt;
    let link = projectRewardLink;
    if (projectRewards) {
      projectRewards.forEach((element, i) => {
        if (element.id === reward.reward.id) {
          console.log("found");
          text[i] = "Procced";
          link[i] = "/rewards/" + id;
          setProjectRewardTxt(text);
          setProjectRewardLink(link);
        }
      });
    }
    return <></>;
  };
  const tipPriceInput = (e) => {
    console.log(e);
    console.log(e.target.min);
  };
  const addProjectReward = (i, reward) => {
    setProjectReward(reward);
    ShowToast("success", "Reward selected, Procceed to pay.");
    let text = projectRewardTxt;
    let link = projectRewardLink;
    text[i] = "Procced";
    link[i] = "/rewards/" + id;
    setProjectRewardTxt(text);
    setProjectRewardLink(link);
  };
  const selectProjectReward = (i, reward) => {
    console.log(reward.id);
    setProjectRewardId(reward.id);
    setProjectRewardAmt(reward.amount);
  };

  const { projectDetails } = projectRq;
  return (
    <>
      <Helmet>
        <title>Pickfunder | Project</title>
      </Helmet>
      <>
        <Helmet>
          <title>{projectDetails.name} | Pickfunder</title>
          <meta property="og:title" content={projectDetails.name} />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:description"
            content={projectDetails.description}
          />
          <meta property="og:type" content="article" />
        </Helmet>
        {projectDetails.avatar.originalImage ? (
          <>
            <Helmet>
              <meta
                property="og:image"
                content={projectDetails.avatar.originalImage}
              />
            </Helmet>
          </>
        ) : (
          <>
            <Helmet>
              <meta property="og:image" content="/images/card1.jpg" />
            </Helmet>
          </>
        )}

        <section className="header" id="header">
          <div className="back-img">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 offset-lg-1">
                  <div className="img-log-pcf pt-4 pt-lg-0">
                    <Link to="/">
                      <img src="/images/logo-2.png" alt="PickFunder" />
                    </Link>
                    <div className="img-log-pcf-text">
                      <h1>{projectDetails.name}</h1>
                      <div className="music-text-man">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: projectDetails.short_description,
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <Header homepage={true} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="highlights bg-grey">
          <div className="container">
            <div className="row">
              <div className="offset-lg-1 col-lg-10">
                <div className="outer position-relative">
                  <div className="goal">
                    <div className="row">
                      <div className="col-lg-5">
                        <label>Goal</label>
                        <h4 className="text-theme">
                          <em>
                            <span>
                              {
                                projectDetails.eventAdmins[0].user.currency
                                  .symbol
                              }{" "}
                            </span>
                            {projectDetails.targetGoal}
                          </em>
                        </h4>
                      </div>
                      <div className="col-lg-2 col-md-3">
                        <label>Raised</label>
                        <h4 className="raised">
                          {projectDetails.eventAdmins[0].user.currency.symbol}{" "}
                          {convertCurrency(
                            projectDetails.eventAdmins[0].user.currency.code,
                            projectDetails.backerDetails.totalAmount
                          )}
                        </h4>
                      </div>
                      <div className="col-lg-2 offset-lg-1 col-md-3">
                        <label>Backers</label>
                        <h4>{projectDetails.backerDetails.totalBackers}</h4>
                      </div>
                      <div className="col-lg-2 col-md-3">
                        <label>Days Left</label>
                        <h4>{projectDetails.daysLeft}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="media position-relative">
                    <i
                      className={`fa fa-heart hover follow-btn ${followColor}`}
                      onClick={handleFollow}
                    ></i>
                    {projectDetails.avatar.originalImage ? (
                      <>
                        <img
                          src={projectDetails.avatar.originalImage}
                          className="img-fluid"
                        />
                      </>
                    ) : (
                      <>
                        <img src="/images/card3.png" className="img-fluid" />
                      </>
                    )}
                  </div>
                  <a href="#backProject" className="donate-link">
                    Back Project
                  </a>
                  <p className="project-by text-center mt-4">
                    <span>
                      Project By{" : "}
                      <em>
                        {projectDetails.eventParticipants[0].artist ? (
                          <>
                            <Link
                              to={`/artist/${projectDetails.eventParticipants[0].artist.id}`}
                            >
                              {
                                projectDetails.eventParticipants[0].artist
                                  .stageName
                              }
                            </Link>
                          </>
                        ) : (
                          <>
                            <Link
                              to={`/band/${projectDetails.eventParticipants[0].band.id}`}
                            >
                              {
                                projectDetails.eventParticipants[0].band
                                  .stageName
                              }
                            </Link>
                          </>
                        )}
                      </em>
                    </span>
                  </p>
                  <p className="project-by text-center mt-3">
                    <span>
                      Category{" : "}
                      <em>
                        {projectDetails.categories ? (
                          <>
                            {Object.entries(projectDetails.categories).map(
                              ([key, category], i) => (
                                <a href="" key={i}>
                                  {category}
                                </a>
                              )
                            )}
                          </>
                        ) : null}
                        {projectDetails.address.city != "" ? (
                          <>
                            <i
                              className="fa-solid fa-location-pin"
                              style={{ marginLeft: "10px" }}
                            ></i>{" "}
                            {projectDetails.address.city}
                          </>
                        ) : null}
                        <span> {projectDetails.city} </span>
                      </em>
                    </span>
                  </p>
                  <p className="text-center mt-3">
                    <ShareCard />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="story bg-grey">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 offset-lg-1 col-md-3">
                <ul
                  className="nav tab-list d-flex flex-md-column"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Story
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      id="pills-updates-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-updates"
                      type="button"
                      role="tab"
                      aria-controls="pills-updates"
                      aria-selected="false"
                    >
                      Updates
                    </button>
                  </li>
                </ul>
              </div>
              <div className="col-lg-8">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: projectDetails.description,
                      }}
                    ></div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-updates"
                    role="tabpanel"
                    aria-labelledby="pills-updates-tab"
                  >
                    {userData.isLoggedIn &&
                    projectDetails.eventAdmins[0] &&
                    projectDetails.eventAdmins[0].user.id ==
                      userData.user.id ? (
                      <>
                        <h4>Post an update</h4>
                        <br />
                        <Editor
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          initialValue="<p></p>"
                          init={{
                            height: 200,
                            menubar: false,
                            plugins: [
                              "advlist autolink lists link image charmap print preview anchor",
                              "searchreplace visualblocks code fullscreen",
                              "insertdatetime media table paste code help wordcount",
                            ],
                            toolbar:
                              "undo redo | formatselect | " +
                              "bold italic backcolor | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "removeformat | help",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          }}
                        />
                        <button className="theme-btn my-4" onClick={saveUpdate}>
                          Post Update{" "}
                          {isBtnLoading ? (
                            <i className="fa-solid fa-spinner fa-spin"></i>
                          ) : null}
                        </button>
                      </>
                    ) : null}
                    {projectDetails.projectUpdates &&
                    projectDetails.projectUpdates.length > 0 ? (
                      <>
                        <div className="updates-container">
                          {projectDetails.projectUpdates.map((update, i) => (
                            <>
                              <div key={i} className="col-12">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: update.update,
                                  }}
                                ></div>
                                <div className="row border-top py-2">
                                  <div className="col-md-6">
                                    {userData.isLoggedIn &&
                                    projectDetails.eventAdmins[0] &&
                                    projectDetails.eventAdmins[0].user.id ==
                                      userData.user.id ? (
                                      <>
                                        {isDeleteBtnLoading ? (
                                          <i className="fa-solid fa-spinner fa-spin"></i>
                                        ) : (
                                          <button
                                            className="btn"
                                            onClick={(e) =>
                                              deleteUpdate(update.id)
                                            }
                                          >
                                            <i className="fa fa-trash text-danger"></i>
                                          </button>
                                        )}
                                        <button className="btn">
                                          <i className="fa fa-pencil text-primary"></i>
                                        </button>
                                      </>
                                    ) : null}
                                  </div>
                                  <div className="col text-md-end text-muted small">
                                    <span>Posted at: </span>
                                    {update.createdAt}
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </>
                    ) : (
                      <>
                        <p>No updates on this project yet.</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="back-project" id="backProject">
          <div className="container">
            <div className="row">
              <div className="offset-lg-1 col-lg-6">
                <div className="tip">
                  <form className="tip-form">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          {projectDetails.eventAdmins[0].user.currency.symbol}
                        </span>
                      </div>
                      <input
                        type="number"
                        min={
                          projectDetails.min_amount
                            ? projectDetails.min_amount
                            : 10
                        }
                        max={
                          projectDetails.max_amount
                            ? projectDetails.max_amount
                            : 1000
                        }
                        value={tipPrice}
                        onChange={(event) => setTipPrice(event.target.value)}
                      />
                    </div>
                    {userData.isLoggedIn ? (
                      backCampaign ? null : (
                        <a
                          onClick={() => setBackCampaign(true)}
                          className="back-project-btn"
                        >
                          {" "}
                          Back Project{" "}
                        </a>
                      )
                    ) : (
                      <Link
                        to={"/login"}
                        onClick={(e) =>
                          setLocalData(
                            "pk_redirect_login",
                            window.location.href
                          )
                        }
                        className="back-project-btn"
                      >
                        Back Project
                      </Link>
                    )}
                  </form>
                  <div className="tips">
                    {projectDetails.predefined_amounts ? (
                      commaSeprated(projectDetails.predefined_amounts).map(
                        (value, i) => (
                          <a
                            key={i}
                            href="#!"
                            onClick={() => setTipPrice(value)}
                          >
                            {projectDetails.eventAdmins[0].user.currency.symbol}
                            {value}
                          </a>
                        )
                      )
                    ) : (
                      <>
                        <a href="#!" onClick={() => setTipPrice(10)}>
                          {" "}
                          {projectDetails.eventAdmins[0].user.currency.symbol}
                          {"10"}
                        </a>
                        <a href="#!" onClick={() => setTipPrice(20)}>
                          {" "}
                          {projectDetails.eventAdmins[0].user.currency.symbol}
                          {"20"}
                        </a>
                        <a href="#!" onClick={() => setTipPrice(30)}>
                          {" "}
                          {projectDetails.eventAdmins[0].user.currency.symbol}
                          {"30"}
                        </a>
                      </>
                    )}
                  </div>
                  {backCampaign ? (
                    <>
                      <h5 className="mt-4">
                        Pay {projectDetails.eventAdmins[0].user.currency.symbol}
                        {tipPrice}
                      </h5>
                      {/* <h5 className="mt-4">Back Project</h5> */}
                      <Elements stripe={stripePromise}>
                        <CheckoutForm project={id} tipPrice={tipPrice} />
                      </Elements>
                    </>
                  ) : null}
                </div>
              </div>
              <div className="offset-lg-1 col-lg-3">
                <div className="rewards">
                  {projectDetails.projectRewards.length > 0 ? (
                    <>
                      <h3 className="theme-title">Rewards</h3>

                      {projectDetails.projectRewards.map((reward, i) => (
                        <div className="card" key={i}>
                          <div className="image">
                            {reward.media.originalImage ? (
                              <>
                                <img
                                  src={reward.media.originalImage}
                                  className="img-fluid"
                                  alt="..."
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src="/images/reward-1.jpg"
                                  alt="reward-1"
                                  className="img-fluid"
                                />
                              </>
                            )}
                          </div>
                          <div className="content">
                            <h3>
                              <span>
                                {
                                  projectDetails.eventAdmins[0].user.currency
                                    .symbol
                                }{" "}
                                {reward.amount}
                              </span>
                            </h3>
                            <h4>{reward.title}</h4>
                            <p>{reward.description}</p>
                            <ul className="list-unstyled m-0 p-0">
                              <li>
                                <i className="fa-solid fa-calendar"></i>{" "}
                                Estimated Delivery: {reward.delivery}
                              </li>
                              <li>
                                <i className="fa fa-star"></i>0 backers
                              </li>
                              <li>
                                <i className="fa fa-award"></i>
                                {reward.no_of_rewards >= 0
                                  ? reward.no_of_rewards
                                  : 0}{" "}
                                Rewards left
                              </li>
                            </ul>
                          </div>
                          {reward.no_of_rewards > 0 ? (
                            <>
                              {" "}
                              <form className="pledge-form">
                                {projectRewardId === reward.id ? (
                                  <>
                                    <label>Pledge Amount</label>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text"
                                          id="basic-addon1"
                                        >
                                          {
                                            projectDetails.eventAdmins[0].user
                                              .currency.symbol
                                          }
                                        </span>
                                      </div>
                                      <input
                                        className="form-control"
                                        min={reward.amount}
                                        value={projectRewardAmt}
                                        onChange={(e) =>
                                          setProjectRewardAmt(e.target.value)
                                        }
                                      ></input>
                                    </div>
                                    <a
                                      href={`/rewards/${projectDetails.id}?reward=${projectRewardId}&amt=${projectRewardAmt}`}
                                      className="continue-btn"
                                    >
                                      Continue
                                    </a>
                                  </>
                                ) : (
                                  <a
                                    className="select-reward-btn"
                                    onClick={(e) =>
                                      selectProjectReward(i, reward)
                                    }
                                  >
                                    <>Select Reward </>
                                  </a>
                                )}
                              </form>
                            </>
                          ) : null}
                        </div>
                      ))}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>

      <Footer />
    </>
  );
}
