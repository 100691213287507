import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";

import Footer from "../components/sections/Footer";
import Header from "../components/sections/Header";
import { API_SEARCH_PROJECTS } from "../config/endpoints";
import { getLocalDataObject, getUserData } from "../helpers/Storage";
import ProjectCard from "../components/cards/ProjectCard";
import ContentLoader from "react-content-loader";
let userData = getUserData();
export default function ProjectSearch() {
  const [isLoading, setIsLoading] = useState(false);
  const [projectList, setprojectList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [hidePageLoadMore, setHidePageLoadMore] = useState(false);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);
  const [pageDetails, setPageDetails] = useState({
    currentPage: 1,
    pageCount: 0,
    perPage: 10,
    totalCount: 0,
  });

  let { key } = useParams();
  //split id
  useEffect(() => {
    setIsLoading(true);

    fetchProjects();
  }, []);
  const fetchProjects = () => {
    setIsLoadMoreLoading(true);
    fetch(
      API_SEARCH_PROJECTS +
        "?event_type=project&sort_by=goal&page_size=" +
        pageSize +
        "&search_term=" +
        key +
        "&page=" +
        currentPage +
        "&start_date=" +
        Date.now(),
      {
        method: "GET",
      }
    )
      .then((response) => {
        setPageDetails({
          pageDetails: {
            currentPage: parseInt(
              response.headers.get("x-pagination-current-page")
            ),
            pageCount: parseInt(
              response.headers.get("x-pagination-page-count")
            ),
            perPage: parseInt(response.headers.get("x-pagination-per-page")),
            totalCount: parseInt(
              response.headers.get("x-pagination-total-count")
            ),
          },
        });
        return response.json();
      })
      .then((data) => {
        if (!data.success) {
        } else {
          let newData = data.data;

          setIsLoading(false);
          setIsLoadMoreLoading(false);
          setCurrentPage(currentPage + 1);
          //   this.setState({
          //     isLoading: false,
          //     isLoadMoreLoading: false,
          //     currentPage: this.state.currentPage + 1,
          //   });

          newData.forEach((dataIndex) => {
            setprojectList((projectList) => [...projectList, dataIndex]);

            // projectList.push(dataIndex);
            // this.setState((state) => ({
            //   allProjectsList: [...state.allProjectsList, dataIndex],
            // }));
          });
          if (currentPage >= pageDetails.pageCount + 1) {
            setHidePageLoadMore(true);
          }
        }
        console.log(projectList);
      });
  };
  const changePage = (e) => {
    fetchProjects();
  };
  return (
    <>
      <Helmet>
        <title>Pickfunder | Projects</title>
      </Helmet>
      <Header />
      {isLoading ? (
        <>
          <div className="container">
            <div className="row mt-4 mb-5">
              <ContentLoader
                speed={2}
                width={800}
                height={500}
                viewBox="0 0 800 500"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
                <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
                <rect x="0" y="56" rx="3" ry="3" width="607" height="9" />
                <rect x="0" y="80" rx="3" ry="3" width="562" height="9" />
                <circle cx="20" cy="20" r="20" />
                <rect x="608" y="202" rx="3" ry="3" width="607" height="9" />
                <rect x="608" y="178" rx="3" ry="3" width="562" height="9" />
                <rect x="608" y="155" rx="3" ry="3" width="263" height="9" />
                <rect x="-5" y="128" rx="3" ry="3" width="607" height="9" />
                <rect x="-5" y="152" rx="3" ry="3" width="502" height="9" />
                <rect x="-5" y="175" rx="3" ry="3" width="600" height="9" />
                <rect x="1" y="105" rx="3" ry="3" width="450" height="9" />
                <rect x="-1" y="204" rx="3" ry="3" width="607" height="9" />
                <rect x="-1" y="228" rx="3" ry="3" width="562" height="9" />
                <rect x="-6" y="276" rx="3" ry="3" width="607" height="9" />
                <rect x="-6" y="300" rx="3" ry="3" width="502" height="9" />
                <rect x="-6" y="323" rx="3" ry="3" width="600" height="9" />
                <rect x="0" y="253" rx="3" ry="3" width="450" height="9" />
              </ContentLoader>
            </div>
          </div>
        </>
      ) : (
        <>
          <section className="project">
            <div className="container">
              <div className="row mt-4 mb-5">
                {projectList.length > 0 ? (
                  <>
                    {projectList.map((project, index) => (
                      <div className="col-md-4 col-sm-6 col-xs-6">
                        <ProjectCard key={index} project={project} />
                      </div>
                    ))}
                    {hidePageLoadMore ? (
                      <></>
                    ) : (
                      <>
                        <div className="row">
                          <div className="col-md-12 text-center mt-4">
                            <a
                              className="btn btn-orange"
                              onClick={(e) => changePage()}
                            >
                              Load more
                              {isLoadMoreLoading ? (
                                <>
                                  &nbsp;
                                  <i className="fa-solid fa-spinner fa-spin"></i>
                                </>
                              ) : (
                                <></>
                              )}
                            </a>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <h4 className="text-center ">
                      No projects! Check back soon!
                    </h4>
                    {userData.isLoggedIn ? (
                      <>
                        <Link
                          className="theme-btn createproject mt-5"
                          to={"/createproject"}
                        >
                          CREATE A PROJECT
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link
                          className="theme-btn createproject mt-5"
                          to={"/login"}
                        >
                          CREATE A PROJECT
                        </Link>
                      </>
                    )}
                  </>
                )}
                {/* {projectList.map((project, index) => (
                <>
                  <div className="col-md-4 col-sm-6 col-xs-6">
                    <ProjectCard key={index} project={project} />
                  </div>
                </>
              ))} */}
              </div>
            </div>
          </section>
        </>
      )}
      <Footer />
    </>
  );
}
