import React, { Component } from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
  RedditIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

export default class ShareCard extends Component {
  render() {
    return (
      <>
        <WhatsappShareButton url={window.location.href}>
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
        <FacebookShareButton url={window.location.href}>
          <FacebookIcon size={32} round={true}></FacebookIcon>
        </FacebookShareButton>
        <TwitterShareButton url={window.location.href}>
          <TwitterIcon size={32} round={true}></TwitterIcon>
        </TwitterShareButton>
        <PinterestShareButton url={window.location.href}>
          <PinterestIcon size={32} round={true}></PinterestIcon>
        </PinterestShareButton>
        <LinkedinShareButton url={window.location.href}>
          <LinkedinIcon size={32} round={true}></LinkedinIcon>
        </LinkedinShareButton>
        <RedditShareButton url={window.location.href}>
          <RedditIcon size={32} round={true}></RedditIcon>
        </RedditShareButton>
      </>
    );
  }
}
