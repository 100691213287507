import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getLocalDataObject, getUserData } from "../../helpers/Storage";

export default class Footer extends Component {
  constructor(props) {
    super(props);
    let userData = getUserData();
    let defaultArtist = getLocalDataObject("defaultArtist");
    this.state = {
      redirect: false,
      userProfile: userData.userProfile,
      isLoggedIn: userData.isLoggedIn,
      userArtistList: [],
      defaultArtist: defaultArtist,
    };
  }
  render() {
    return (
      <>
        <div className="last-page">
          <div className="container">
            <div className="row">
              <div className="col-sm-3  col-md-3">
                <Link to={"/"}>
                  <img
                    className="footer-logo img-fluid"
                    src="/images/logo-white.png"
                    alt=""
                  />
                </Link>
              </div>
              <div className="col-sm-3 col-md-3  ">
                <ul>
                  <li>
                    <a
                      href="https://picklejar.com/about-picklejar/"
                      target="_blank"
                    >
                      About Us
                    </a>
                  </li>
                  {this.state.isLoggedIn ? (
                    <>
                      <li>
                        <Link to={"/createproject"}>Add Project</Link>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <Link to={"/login"}>Add Project</Link>
                      </li>
                    </>
                  )}
                  <li>
                    <Link to={"/projects"}>Browse</Link>
                  </li>
                  <li>
                    <a href="https://picklejar.com/press/" target="_blank">
                      Press
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-3 col-md-3">
                <ul>
                  <li>
                    <a href="https://picklejar.com/careers/" target="_blank">
                      Jobs
                    </a>
                  </li>
                  <li>
                    <a href="https://support.picklejar.com/" target="_blank">
                      Support
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://picklejar.com/privacy-policy/"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://picklejar.com/terms-of-use/"
                      target="_blank"
                    >
                      Terms of Use
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-3 col-md-3">
                <li className="list-unstyled text-center">
                  <a href="https://apps.apple.com/us/app/picklejar-live/id1517697776">
                    <img
                      src="https://picklejarlive.com/wp-content/uploads/2022/04/buy-apple1.png"
                      className="ijmg-fluid"
                      alt="PickFunder Apple Store"
                    />
                  </a>
                </li>
                <li className="list-unstyled text-center">
                  <a href="https://play.google.com/store/apps/details?id=com.Reatro.picklejar">
                    <img
                      src="https://picklejarlive.com/wp-content/uploads/2022/04/buy-android1.png"
                      className="ijmg-fluid"
                      alt="PickFunder Apple Store"
                    />
                  </a>
                </li>
                <li className="list-unstyled">
                  <div className="footer-widget-social">
                    <a
                      target="_blank"
                      href="https://www.instagram.com/picklejar_live/"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/PickleJarLive/"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a target="_blank" href="https://vm.tiktok.com/ZTds6m4wh/">
                      <i className="fab fa-tiktok"></i>
                    </a>
                    <a target="_blank" href="https://twitter.com/picklejarlive">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </div>
                </li>
                {/* <i className="fa fa-envelope-open email-icon"></i>
                <span className="white-color">
                  <a href="mailto:info@pickfunder.com" className="white-color">
                    info@pickfunder.com
                  </a>
                </span> */}
              </div>
            </div>
            <hr />
            <div className="copy-right">
              <p>© 2022 PickleJar Holdings, Inc. All Rights Reserved. </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}
