import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/sections/Footer";
import Header from "../components/sections/Header";
import { getLocalDataObject, getUserData } from "../helpers/Storage";
import { Link } from "react-router-dom";

export default class About extends Component {
  constructor(props) {
    super(props);
    let userData = getUserData();
    let defaultArtist = getLocalDataObject("defaultArtist");
    this.state = {
      redirect: false,
      userProfile: userData.userProfile,
      isLoggedIn: userData.isLoggedIn,
      defaultArtist: defaultArtist,
      allProjectsList: [],
    };
  }
  componentDidMount() {}
  render() {
    return (
      <>
        <Helmet>
          <title>Pickfunder</title>
        </Helmet>
        <Header />
        <section className="about-pro">
          <div className="container-fluid">
            <div className="row d-flex justify-content-around mt-5">
              <div className="col-lg-5">
                <div className="text-text-pro">
                  <ul>
                    <li>
                      <h4>Who We Are</h4>
                    </li>
                  </ul>
                  <h3>We exist to make Artists & Creators more money.</h3>
                  <p>
                    PickleJar is the intelligent <u>live entertainment app</u>{" "}
                    for artists to make more money by lighting up their fans
                    like never before!
                  </p>
                </div>
                <div className="funder-text-title-circle">
                  <div className="icon-pro-line-icob">
                    <span>
                      <i className="bi bi-check2-circle"></i>
                    </span>
                  </div>
                  <div className="funder-title">
                    <h4>Why crowdfunding?</h4>
                    <p>
                      We listened to Artists and Creators tell their stories and
                      one main issue they have is funding their work. With
                      PickFunder they can raise capital for specific projects,
                      and on the PickleJar App they can be rewarded for
                      performances in real time. The combination contributes to
                      the first Gratitude Economy for Artists and Creators.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="pickfunder-logo">
                  <img
                    src="images/pickwithjar.jpg"
                    className="img-fluid"
                    width="400"
                  />
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-2">
                  <div className="pant-button-2">
                    <a
                      target={"_blank"}
                      href="https://apps.apple.com/us/app/picklejar-live/id1517697776"
                    >
                      <img
                        src="images/app-1.png"
                        className="img-fluid"
                        width="200"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="pant-button-2">
                    <a
                      target={"_blank"}
                      href="https://play.google.com/store/apps/details?id=com.Reatro.picklejar"
                    >
                      <img
                        src="images/google-6.png"
                        className="img-fluid"
                        width="210"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="creators d-flex  align-items-center justify-content-center">
          <div className="sec-text">
            <h4>Get Started Today</h4>
            <h1>Creators keep 100% of funding amount...yes, really!</h1>
            <div className="theem-button">
              {this.state.isLoggedIn ? (
                <>
                  <Link className="submit" to={"/createproject"}>
                    START A PROJECT
                  </Link>
                </>
              ) : (
                <>
                  <Link className="submit" to={"/login"}>
                    START A PROJECT
                  </Link>
                </>
              )}
            </div>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}
