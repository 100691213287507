export function setUserData(data) {
  localStorage.setItem("pkIsLoggedIn", true);
  localStorage.setItem("pkUser", JSON.stringify(data.data));
  localStorage.setItem("pkUserProfile", JSON.stringify(data.data.profile));
  localStorage.setItem("pkToken", data.data.token.accessToken);
  //session expiry
  var minutesToAdd = 2880; // 48 hours
  var currentDate = new Date();
  var futureDate =
    new Date(currentDate.getTime() + minutesToAdd * 60000).getTime() / 1000;
  localStorage.setItem("pkLoginSession", futureDate);
}

export function getUserData() {
  let user = {
    isLoggedIn: localStorage.getItem("pkIsLoggedIn"),
    user: JSON.parse(localStorage.getItem("pkUser")),
    userProfile: JSON.parse(localStorage.getItem("pkUserProfile")),
    token: localStorage.getItem("pkToken"),
  };
  return user;
}

export function logoutUser() {
  localStorage.removeItem("pkIsLoggedIn");
  localStorage.removeItem("pkUser");
  localStorage.removeItem("pkUserProfile");
  localStorage.removeItem("pkToken");
  localStorage.removeItem("pkLoginSession");
  localStorage.removeItem("defaultArtist");
  localStorage.removeItem("pk_redirect_login");
}

export function setLocalDataObject(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
  window.dispatchEvent(new Event("storage"));
}

export function getLocalDataObject(key) {
  return JSON.parse(localStorage.getItem(key));
}

export function deleteLocalDataObject(key) {
  localStorage.removeItem(key);
}

export function setProjectReward(data, all = false, key = "projectRewards") {
  if (all) {
    localStorage.setItem(key, JSON.stringify(data));
  } else {
    const arr = JSON.parse(localStorage.getItem(key)) || [];
    console.log("ex", arr);
    arr.push(data);
    localStorage.setItem(key, JSON.stringify(arr));
  }
}

export function setLocalData(key, data) {
  localStorage.setItem(key, data);
}

export function getLocalData(key) {
  return localStorage.getItem(key);
}
