import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  getLocalDataObject,
  getUserData,
  setLocalDataObject,
} from "../helpers/Storage";

const userData = getUserData();
const defaultArtist = getLocalDataObject("defaultArtist");
let initialState = {
  redirect: false,
  userProfile: userData.userProfile,
  isLoggedIn: userData.isLoggedIn,
  defaultArtist: defaultArtist,
  userArtistList: [],
  isLoading: false,
};
export default class HomeLoginMenu extends Component {
  constructor(props) {
    super(props);
    let userData = getUserData();
    let defaultArtist = getLocalDataObject("defaultArtist");
    this.state = initialState;
  }

  render() {
    return (
      <>
        <div className="login-menu">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <ul className="m-0 p-0 list-unstyled d-flex justify-content-lg-end">
                  <li>
                    <Link to="/projects">Explore</Link>
                  </li>
                  <li>
                    <Link to="/createproject">Raise Funding</Link>
                  </li>
                  <li>
                    <Link to="/mycampaigns" className="dropdown-item-1">
                      My Projects
                    </Link>
                  </li>
                  <li>
                    <Link to="/myprofile" className="dropdown-item-1">
                      My Profile
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
