import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  getLocalDataObject,
  getUserData,
  setLocalDataObject,
} from "../helpers/Storage";
import Autocomplete from "react-google-autocomplete";
import {
  API_GET_SHIPPING_ADDRESS,
  API_CREATE_SHIPPING_ADDRESS,
  API_UPDATE_SHIPPING_ADDRESS,
  API_DELETE_SHIPPING_ADDRESS,
} from "../config/endpoints";
import SimpleReactValidator from "simple-react-validator";
import { ShowToast } from "../helpers/Helpers";
import { Navigate } from "react-router-dom";
import ContentLoader from "react-content-loader";

const userData = getUserData();
const defaultArtist = getLocalDataObject("defaultArtist");
let initialState = {
  redirect: false,
  userProfile: userData.userProfile,
  isLoggedIn: userData.isLoggedIn,
  defaultArtist: defaultArtist,
  userArtistList: [],
  addressList: [],
  fields: {
    name: "",
    street: "",
    city: "",
    state: "",
    country: "",
    phone: "",
    zip: "",
    user_id: userData.user ? userData.user.id : null,
  },
  isShippingAddress: false,
  isLoading: false,
  selectedAddress: null,
  isEdit: true,
  isBtnLoading: false,
};
export default class ShippingAddress extends Component {
  constructor(props) {
    super(props);
    let userData = getUserData();
    let defaultArtist = getLocalDataObject("defaultArtist");
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  componentDidMount() {
    const defaultArtist = getLocalDataObject("defaultArtist");
    let localUserData = getUserData();
    this.updateShippingAddress();
  }

  updateShippingAddress = (e) => {
    this.setState({
      isLoading: true,
    });
    fetch(API_GET_SHIPPING_ADDRESS + "?user_id=" + userData.user.id, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          this.setState({ addressList: data.data, isLoading: false });
        }
      });
  };

  addShippingAddress = (e) => {
    this.setState({
      isShippingAddress: true,
      isEdit: false,
      fields: {
        name: "",
        street: "",
        city: "",
        state: "",
        country: "",
        phone: "",
        zip: "",
        user_id: userData.user ? userData.user.id : null,
      },
    });
  };
  cancelShippingAddress = (e) => {
    this.setState({
      isShippingAddress: false,
      isEdit: false,
    });
  };

  updateShippingAddressSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({
        isBtnLoading: true,
      });
      fetch(API_UPDATE_SHIPPING_ADDRESS, {
        method: "POST",
        body: JSON.stringify(this.state.fields),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            let errorList = data.error.fieldErrors;
            if (errorList) {
              errorList.forEach((element) => {
                ShowToast("error", element.message);
              });
              this.setState((prevState) => ({
                isBtnLoading: false,
              }));
            } else {
              ShowToast("error", "Something went wrong!");
            }
          } else {
            ShowToast("success", "Address Created");
            this.setState((prevState) => initialState);
            this.updateShippingAddress();
          }
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  createShippingAddress = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({
        isBtnLoading: true,
      });
      fetch(API_CREATE_SHIPPING_ADDRESS, {
        method: "POST",
        body: JSON.stringify(this.state.fields),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            let errorList = data.error.fieldErrors;
            if (errorList) {
              errorList.forEach((element) => {
                ShowToast("error", element.message);
              });
              this.setState((prevState) => ({
                isBtnLoading: false,
              }));
            } else {
              ShowToast("error", "Something went wrong!");
            }
          } else {
            ShowToast("success", "Address Created");
            this.setState((prevState) => initialState);
            this.updateShippingAddress();
          }
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  changeSelectedAddress = (e, id) => {
    this.setState((prevState) => ({
      selectedAddress: id,
    }));
  };

  editAddress = (e, address) => {
    this.setState((prevState) => ({
      fields: address,
      isEdit: true,
      isShippingAddress: true,
    }));
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        user_id: userData.user ? userData.user.id : null,
      },
    }));
    console.log(this.state);
  };

  deleteAddress = (e, address) => {
    if (window.confirm("Delete the item?")) {
      this.setState({ deleteId: address.id }, () => {
        fetch(API_DELETE_SHIPPING_ADDRESS + "?id=" + address.id, {
          method: "POST",
          body: JSON.stringify({ id: this.state.deleteId }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (!data.success) {
              let errorList = data.error.fieldErrors;
              if (errorList) {
                errorList.forEach((element) => {
                  ShowToast("error", element.message);
                });
                this.setState((prevState) => ({
                  isBtnLoading: false,
                }));
              } else {
                ShowToast("error", "Something went wrong!");
              }
            } else {
              ShowToast("success", "Address Deleted");
              this.setState((prevState) => initialState);
              this.updateShippingAddress();
            }
          });
      });
    }
    console.log(this.state);
  };

  render() {
    if (this.state.redirect) {
      window.location = this.state.redirect;
    }
    return (
      <>
        {this.state.isLoading ? (
          <>
            <section className="container">
              <div className="row">
                <div className="col-md-12">
                  <ContentLoader
                    speed={2}
                    width={800}
                    height={500}
                    viewBox="0 0 800 500"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
                    <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
                    <rect x="0" y="56" rx="3" ry="3" width="607" height="9" />
                    <rect x="0" y="80" rx="3" ry="3" width="562" height="9" />
                    <circle cx="20" cy="20" r="20" />
                    <rect
                      x="608"
                      y="202"
                      rx="3"
                      ry="3"
                      width="607"
                      height="9"
                    />
                    <rect
                      x="608"
                      y="178"
                      rx="3"
                      ry="3"
                      width="562"
                      height="9"
                    />
                    <rect
                      x="608"
                      y="155"
                      rx="3"
                      ry="3"
                      width="263"
                      height="9"
                    />
                    <rect x="-5" y="128" rx="3" ry="3" width="607" height="9" />
                    <rect x="-5" y="152" rx="3" ry="3" width="502" height="9" />
                    <rect x="-5" y="175" rx="3" ry="3" width="600" height="9" />
                    <rect x="1" y="105" rx="3" ry="3" width="450" height="9" />
                    <rect x="-1" y="204" rx="3" ry="3" width="607" height="9" />
                    <rect x="-1" y="228" rx="3" ry="3" width="562" height="9" />
                    <rect x="-6" y="276" rx="3" ry="3" width="607" height="9" />
                    <rect x="-6" y="300" rx="3" ry="3" width="502" height="9" />
                    <rect x="-6" y="323" rx="3" ry="3" width="600" height="9" />
                    <rect x="0" y="253" rx="3" ry="3" width="450" height="9" />
                  </ContentLoader>
                </div>
              </div>
            </section>
          </>
        ) : (
          <section className="shipping-container">
            <div className="row">
              <div className="col-md-8">
                <h4>Shipping Address</h4>
              </div>
              <div className="col-md-4">
                <button className="btn btn-primary pull-right" onClick={this.addShippingAddress}>New Shipping Address</button>
              </div>
            </div>
            <div className="row">
              {this.state.isShippingAddress ? (
                <>
                    <div className="col-md-6">
                      <div className="col-md-12">
                        <div className="form-frame">
                          <label htmlFor="title">Name</label>
                          <input
                            type="text"
                            name="name"
                            value={this.state.fields.name}
                            onChange={(e) => this.handleChange(e)}
                          />
                        </div>
                        <span className="error">
                          {this.validator.message(
                            "name",
                            this.state.fields.name,
                            "required"
                          )}
                        </span>
                      </div>
                      <div className="col-md-12">
                        <div className="form-frame">
                          <label htmlFor="title">City</label>
                          <input
                            type="text"
                            name="city"
                            value={this.state.fields.city}
                            onChange={(e) => this.handleChange(e)}
                          />
                        </div>
                        <span className="error">
                          {this.validator.message(
                            "city",
                            this.state.fields.city,
                            "required"
                          )}
                        </span>
                      </div>
                      <div className="col-md-12">
                        <div className="form-frame">
                          <label htmlFor="title">Country</label>
                          <input
                            type="text"
                            name="country"
                            value={this.state.fields.country}
                            onChange={(e) => this.handleChange(e)}
                          />
                        </div>
                        <span className="error">
                          {this.validator.message(
                            "country",
                            this.state.fields.country,
                            "required"
                          )}
                        </span>
                      </div>
                      <div className="col-md-12">
                        <div className="form-frame">
                          <label htmlFor="title">Zip</label>
                          <input
                            type="text"
                            name="zip"
                            value={this.state.fields.zip}
                            onChange={(e) => this.handleChange(e)}
                          />
                        </div>
                        <span className="error">
                          {this.validator.message(
                            "zip",
                            this.state.fields.zip,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-12">
                        <div className="form-frame">
                          <label htmlFor="title">Street</label>
                          <input
                            type="text"
                            name="street"
                            value={this.state.fields.street}
                            onChange={(e) => this.handleChange(e)}
                          />
                        </div>
                        <span className="error">
                          {this.validator.message(
                            "street",
                            this.state.fields.street,
                            "required"
                          )}
                        </span>
                      </div>
                      <div className="col-md-12">
                        <div className="form-frame">
                          <label htmlFor="title">State</label>
                          <input
                            type="text"
                            name="state"
                            value={this.state.fields.state}
                            onChange={(e) => this.handleChange(e)}
                          />
                        </div>
                        <span className="error">
                          {this.validator.message(
                            "state",
                            this.state.fields.state,
                            "required"
                          )}
                        </span>
                      </div>
                      <div className="col-md-12">
                        <div className="form-frame">
                          <label htmlFor="title">Phone</label>
                          <input
                            type="text"
                            name="phone"
                            value={this.state.fields.phone}
                            onChange={(e) => this.handleChange(e)}
                          />
                        </div>
                        <span className="error">
                          {this.validator.message(
                            "phone",
                            this.state.fields.phone,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      {this.state.isEdit ? (
                        <button
                          className="btn btn-primary mt-4"
                          onClick={this.updateShippingAddressSubmit}
                        >
                          Update Address
                          {this.state.isBtnLoading ? (
                            <>
                              &nbsp;
                              <i className="fa-solid fa-spinner fa-spin"></i>
                            </>
                          ) : (
                            <></>
                          )}
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary mt-4"
                          onClick={this.createShippingAddress}
                        >
                          Save Address
                          {this.state.isBtnLoading ? (
                            <>
                              &nbsp;
                              <i className="fa-solid fa-spinner fa-spin"></i>
                            </>
                          ) : (
                            <></>
                          )}
                        </button>
                      )}

                      <button
                        className="btn btn-default mt-4"
                        onClick={this.cancelShippingAddress}
                      >
                        Cancel
                      </button>
                    </div>
                </>
              ) : (
                <>
                  {" "}
                  <div className="row shipping mt-4">
                    {this.state.addressList.length > 0 ? (
                      this.state.addressList.map((address, index) => (
                        <>
                          <div
                            className={
                              this.state.selectedAddress === address.id
                                ? "col-md-3 shipping-item active"
                                : "col-md-3 shipping-item"
                            }
                            onClick={(e) =>
                              this.changeSelectedAddress(e, address.id)
                            }
                          >
                            <h6>{address.name}</h6>
                            <p>
                              {address.street}, {address.city}
                            </p>
                            <p>
                              {address.state}, {address.country}
                            </p>
                            <p>{address.zip}</p>
                            <p>{address.phone}</p>
                            <i
                              className="fa fa-pencil shipping-actions edit-address"
                              onClick={(e) => this.editAddress(e, address)}
                            ></i>
                            <i
                              className="fa fa-trash shipping-actions delete-address"
                              onClick={(e) => this.deleteAddress(e, address)}
                            ></i>
                          </div>
                        </>
                      ))
                    ) : (
                      <>
                        <h6 className="text-center mt-5">
                          No Address found, Please create new one.
                        </h6>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </section>
        )}
      </>
    );
  }
}
