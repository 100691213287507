import React, { Component, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

import { Helmet } from "react-helmet";
import Footer from "../components/sections/Footer";
import Header from "../components/sections/Header";
import SimpleReactValidator from "simple-react-validator";
import { Link, Navigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { isArtist } from "../helpers/Helpers";

import {
  API_CREATE_PROJECT,
  API_GET_CATEGORIES,
  API_UPLOAD_IMAGE,
  API_USER_PROFILE,
  API_ARTIST_CREATE,
  API_SPLIT_PERFORMANCE_PROJECT,
  API_KYC,
  API_KYC_STATUS,
  API_UPDATE_PROJECT,
} from "../config/endpoints";
import {
  getLocalDataObject,
  getUserData,
  setLocalDataObject,
} from "../helpers/Storage";
import { ShowToast } from "../helpers/Helpers";
import Autocomplete from "react-google-autocomplete";
import Files from "react-files";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import CreateArtist from "../components/CreateArtist";

const userData = getUserData();
const defaultArtist = getLocalDataObject("defaultArtist");
Date.prototype.toDateInputValue = function () {
  var local = new Date(this);
  local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
};
Date.prototype.toDateInputValue30Days = function () {
  // var local = new Date().setDate(new Date().getDate() + 30);
  var local = new Date(this);
  local.setDate(local.getDate() + 30);
  local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
};
let kycInterval = 0;
let localUserData = { user: {} };
const initialState = {
  redirect: false,
  fields: {
    name: "",
    description: "",
    short_description: "",
    category: "",
    tags: "",
    video: "",
    campaign_end_method: "",
    start_date: new Date().toDateInputValue(),
    end_date: new Date().toDateInputValue30Days(),
    min_amount: "",
    max_amount: "",
    target_goal: "",
    predefined_amounts: "25",
    contributor_anonymous: "",
    datesStartEnd: [],
    lat: "",
    lng: "",
    address: "",
    selected_categories: "",
    street_address: "",
    city: "",
    state: "",
    zip: "",
    artist_ids: defaultArtist ? [defaultArtist.id] : null,
    event_type: "project",
    media_id: "",
    status: 4, //default draft status
  },
  profileFields: {
    fullname: "",
    avatar: "",
    hometown: "",
    about: "",
    stagename: "",
  },
  uploadFile: false,
  filePreview: false,
  userProfile: userData.userProfile,
  isLoggedIn: userData.isLoggedIn,
  defaultArtist: defaultArtist,
  myProjectsList: [],
  categoriesList: [],
  rewardValues: [],
  src: null,
  crop: {
    unit: "%",
    width: 30,
    aspect: 16 / 9,
  },
  cropModel: false,
  showKyc: false,
  isBtnLoading: false,
  kycDone: false,
  xw: false,
  activeTab: "basics",
  defaultCurrencyCode: {
    code: "INR",
    symbol: "Rs",
  },
  draftId: false,
  kycAccountStatus: 0,
};
export default class CreateProjectNew extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }
  componentDidMount() {
    const defaultArtist = getLocalDataObject("defaultArtist");
    localUserData = getUserData();
    this.getKycAccountStatus();
    fetch(API_GET_CATEGORIES, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          this.setState({
            categoriesList: data.output.data,
          });
        }
      });
    this.setState({
      defaultArtist: defaultArtist,
    });
    if (defaultArtist) {
      if (isArtist(getLocalDataObject("defaultArtist"))) {
        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            artist_ids: [defaultArtist.id],
          },
          defaultCurrencyCode: defaultArtist.user.currency,
        }));
      } else {
        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            band_ids: [defaultArtist.band.id],
            artist_ids: [],
          },
          defaultCurrencyCode: defaultArtist.user.currency,
        }));
      }
    }
    window.addEventListener("storage", (e) => {
      console.log("updated", e.key);
      if (e.key === "defaultArtist") {
        this.setState({
          defaultArtist: e.newValue,
        });
      }
    });
  }

  onOpenCropModal = () => {
    this.setState({ cropModel: true });
  };

  onCloseCropModal = () => {
    this.setState({ cropModel: false });
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result, cropModel: true })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  saveCropImage = () => {
    this.setState({ cropModel: false });
    const formData = new FormData();
    var file = new File([this.state.blobCropped], "uploaded_file.jpeg", {
      type: "image/jpeg",
      lastModified: Date.now(),
    });
    formData.append("file", file);

    fetch(API_UPLOAD_IMAGE, {
      method: "POST",
      body: formData,
    })
      .then((r) => r.json())
      .then((data) => {
        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            media_id: data.data[0].id,
          },
        }));
        console.log(this.state);
      });
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const blobCropped = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      const croppedImageUrl = window.URL.createObjectURL(blobCropped);
      this.setState({ croppedImageUrl, blobCropped });
    }
  }

  blobToFile = (theBlob, fileName) => {
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(),
      type: "image/jpeg",
    });
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          console.log(blob);
          resolve(blob);
        },
        "image/jpeg",
        1
      );
    });
  }

  onFilesChange = (files) => {
    console.log(files[0]);
    this.setState({ uploadFile: files[0], filePreview: files[0].preview.url });
    //upload media
    const formData = new FormData();
    var file = new File([this.state.blobCropped], "uploaded_file.jpeg", {
      type: "image/jpeg",
      lastModified: Date.now(),
    });
    console.log(this.state.croppedImageUrl);
    console.log(file);
    // formData.append("file", files[0]);
    formData.append("file", file);

    fetch(API_UPLOAD_IMAGE, {
      method: "POST",
      body: formData,
    })
      .then((r) => r.json())
      .then((data) => {
        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            media_id: data.data[0].id,
          },
        }));
        console.log(this.state);
      });
  };

  handleChangeFile = (files, i) => {
    console.log(files);
    this.setState({ uploadFile: files[0] });
    let rewardValues = this.state.rewardValues;
    rewardValues[i]["mediaPreview"] = files[0].preview.url;
    this.setState({ rewardValues });
    //upload media
    const formData = new FormData();
    formData.append("file", files[0]);

    fetch(API_UPLOAD_IMAGE, {
      method: "POST",
      body: formData,
    })
      .then((r) => r.json())
      .then((data) => {
        let rewardValues = this.state.rewardValues;
        rewardValues[i]["media_id"] = data.data[0].id;
        this.setState({ rewardValues });
        console.log(this.state.rewardValues);
      });
  };

  onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  handleChangeForm(i, e) {
    let rewardValues = this.state.rewardValues;
    rewardValues[i][e.target.name] = e.target.value;
    // if (rewardValues[i][e.target.name] == "noOfRewards") {
    //   rewardValues[i]["no_of_rewards"] = e.target.value;
    // }
    this.setState({ rewardValues });
    console.log(this.state.rewardValues);
  }

  addFormFields() {
    this.setState({
      rewardValues: [
        ...this.state.rewardValues,
        {
          title: "",
          description: "",
          no_of_rewards: "",
          amount: "",
          delivery: "",
          media_id: "",
          mediaPreview: "",
        },
      ],
    });
  }

  removeFormFields(i) {
    let rewardValues = this.state.rewardValues;
    rewardValues.splice(i, 1);
    this.setState({ rewardValues });
  }

  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  handleDescriptionChange = (value, editor) => {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        description: value,
      },
    }));
  };

  handleShortDescriptionChange = (value, editor) => {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        short_description: value,
      },
    }));
  };

  changeStep = (e, tab) => {
    console.log(tab);
    if (tab === "funding") {
      //validate step 1
      if (
        this.validator.fieldValid("name") &&
        this.validator.fieldValid("selected_categories") &&
        this.validator.fieldValid("start_date") &&
        this.validator.fieldValid("end_date")
      ) {
        this.setState({ activeTab: tab });
        console.log(this.state.activeTab);
        this.validator.hideMessages();
        this.saveDraft();
      } else {
        this.validator.showMessages();
      }
    }
    if (tab === "rewards") {
      //validate step 2
      if (this.validator.fieldValid("target_goal")) {
        this.setState({ activeTab: tab });
        console.log(this.state.activeTab);
        this.validator.hideMessages();
        this.saveDraft();
      } else {
        this.validator.showMessages();
      }
    }
    if (tab === "story") {
      //step optional
      this.setState({ activeTab: tab });
      this.validator.hideMessages();
      this.saveDraft();
    }
    if (tab === "payment") {
      //step desc
      if (this.validator.fieldValid("description")) {
        this.setState({ activeTab: tab });
        console.log(this.state.activeTab);
        this.validator.hideMessages();
        this.saveDraft();
      } else {
        this.validator.showMessages();
      }
    }
    if (tab === "basics") {
      this.setState({ activeTab: tab });
      console.log(this.state.activeTab);
      this.validator.hideMessages();
    }
  };

  saveDraftStep = (e, tab) => {
    console.log(tab);
    if (tab === "funding") {
      //validate step 1
      if (
        this.validator.fieldValid("name") &&
        this.validator.fieldValid("selected_categories") &&
        this.validator.fieldValid("start_date") &&
        this.validator.fieldValid("end_date")
      ) {
        // this.setState({ activeTab: tab });
        // console.log(this.state.activeTab);
        this.validator.hideMessages();
        this.saveDraft(e, true);
      } else {
        this.validator.showMessages();
      }
    }
    if (tab === "rewards") {
      //validate step 2
      if (this.validator.fieldValid("target_goal")) {
        // this.setState({ activeTab: tab });
        // console.log(this.state.activeTab);
        this.validator.hideMessages();
        this.saveDraft(e, true);
      } else {
        this.validator.showMessages();
      }
    }
    if (tab === "story") {
      //step optional
      // this.setState({ activeTab: tab });
      this.validator.hideMessages();
      this.saveDraft(e, true);
    }
    if (tab === "payment") {
      //step desc
      if (this.validator.fieldValid("description")) {
        // this.setState({ activeTab: tab });
        // console.log(this.state.activeTab);
        this.validator.hideMessages();
        this.saveDraft(e, true);
      } else {
        this.validator.showMessages();
      }
    }
    if (tab === "basics") {
      // this.setState({ activeTab: tab });
      // console.log(this.state.activeTab);
      this.saveDraft(e, true);
      this.validator.hideMessages();
    }
  };

  getKycLink = (e) => {
    let userData = getUserData();
    // console.log(userData.user.email);
    this.setState((prevState) => ({
      isBtnLoading: true,
    }));
    fetch(API_KYC + "?email=" + userData.user.email, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          console.log(data);
          this.setState((prevState) => ({
            isBtnLoading: false,
          }));
          kycInterval = setInterval(this.checkKycStatus, 3000);
          //   this.checkKycStatus();
          if (data.data.link) {
            window.open(data.data.link, "_blank");
          }
        }
      });
  };

  getKycAccountStatus = (e) => {
    fetch(API_KYC_STATUS + "?user_id=" + userData.user.id, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          console.log(data);
          this.setState((prevState) => ({
            isBtnLoading: false,
            kycAccountStatus: data.data.stripeAccountStatus,
          }));
        }
      });
  };

  checkKycStatus = (e) => {
    console.log("checking");
    fetch(API_USER_PROFILE, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          console.log(data);
          if (data.data.stripe_account_id) {
            this.getKycAccountStatus();
            console.log("KYC Updated");
            //update user
            setLocalDataObject("pkUser", data.data);
            setLocalDataObject("pkUserProfile", data.data.profile);
            clearInterval(kycInterval);
            kycInterval = 0;
            this.setState((prevState) => ({
              kycDone: true,
            }));
          }
        }
      });
  };

  saveDraft = (e, draftBtn) => {
    //
    let startDate = parseInt(
      (new Date(this.state.fields.start_date).getTime() / 1000).toFixed(0)
    );
    let endDate = parseInt(
      (new Date(this.state.fields.end_date).getTime() / 1000).toFixed(0)
    );
    const { fields, rewardValues } = this.state;
    fields.datesStartEnd[0] = startDate + ", " + endDate;
    if (draftBtn) {
      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          status: 4,
        },
        isDraftBtnLoading: true,
      }));
    }
    this.setState(
      (prevState) => ({
        fields: {
          ...prevState.fields,
          datesStartEnd: fields.datesStartEnd,
          rewards: rewardValues,
        },
        // isLoading: true,
      }),
      () => {
        console.log(this.state.fields);
        if (this.state.draftId) {
          //update project
          console.log("update");
          fetch(API_UPDATE_PROJECT, {
            method: "POST",
            body: JSON.stringify(this.state.fields),
          })
            .then((response) => response.json())
            .then((data) => {
              if (!data.success) {
                let errorList = data.error.fieldErrors;
                if (errorList) {
                  errorList.forEach((element) => {
                    ShowToast("error", element.message);
                  });
                  this.setState((prevState) => ({
                    isUpdateLoading: false,
                  }));
                } else {
                  ShowToast("error", "Something went wrong!");
                }
              } else {
                if (draftBtn) {
                  this.setState({
                    isDraftBtnLoading: false,
                    redirect: "/mycampaigns",
                  });
                }
                if (data.data.status === 1) {
                  ShowToast("success", "Project Created");
                  this.setState((prevState) => ({
                    redirect: "/mycampaigns",
                    isLoading: false,
                  }));
                }
              }
            });
        } else {
          //create project
          console.log("create project");
          fetch(API_CREATE_PROJECT, {
            method: "POST",
            body: JSON.stringify(this.state.fields),
          })
            .then((response) => response.json())
            .then((data) => {
              if (!data.success) {
                let errorList = data.error.fieldErrors;
                if (errorList) {
                  errorList.forEach((element) => {
                    ShowToast("error", element.message);
                  });
                  this.setState((prevState) => ({
                    // isLoading: false,
                  }));
                } else {
                  ShowToast("error", "Something went wrong!");
                }
              } else {
                //create event split %
                console.log("create split");
                let event = data.data;
                this.setState((prevState) => ({
                  fields: {
                    ...prevState.fields,
                    id: event.id,
                  },
                  draftId: event.id,
                }));
                let splitData;
                if (isArtist(getLocalDataObject("defaultArtist"))) {
                  splitData = {
                    event_id: event.id,
                    performance_id: event.eventParticipants[0].id,
                    artist_id: defaultArtist.id,
                    split_percentage: 100,
                  };
                } else {
                  splitData = {
                    event_id: event.id,
                    performance_id: event.eventParticipants[0].id,
                    band_id: defaultArtist.band.id,
                    split_percentage: 100,
                  };
                }
                fetch(API_SPLIT_PERFORMANCE_PROJECT, {
                  method: "POST",
                  body: JSON.stringify(splitData),
                })
                  .then((response) => response.json())
                  .then((data) => {
                    if (!data.success) {
                      let errorList = data.error.fieldErrors;
                      if (errorList) {
                        errorList.forEach((element) => {
                          ShowToast("error", element.message);
                        });
                        this.setState((prevState) => ({
                          isLoading: false,
                        }));
                      } else {
                        ShowToast("error", "Something went wrong!");
                      }
                    } else {
                      if (draftBtn) {
                        this.setState({
                          isDraftBtnLoading: false,
                          redirect: "/mycampaigns",
                        });
                      }
                      // ShowToast("success", "Project Created");
                      // this.setState((prevState) => ({
                      //   fields: {
                      //     ...prevState.fields,
                      //     id: event.id,
                      //   },
                      //   draftId: event.id,
                      // }));
                      // this.setState((prevState) => ({
                      //   redirect: "/mycampaigns",
                      // }));
                    }
                  });
              }
            });
        }
      }
    );
  };

  createProject = (e) => {
    e.preventDefault();
    this.setState(
      (prevState) => ({
        fields: {
          ...prevState.fields,
          status: 1,
        },
        isLoading: true,
      }),
      () => {
        this.saveDraft();
      }
    );
  };

  render() {
    const { crop, croppedImageUrl, src } = this.state;
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <>
        <Helmet>
          <title>Pickfunder | Create Project</title>
        </Helmet>

        <section className="header" id="header">
          <div className="back-img">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 offset-lg-1">
                  <div className="img-log-pcf pt-4 pt-lg-0">
                    <Link to="/">
                      <img src="/images/logo-2.png" alt="PickFunder" />
                    </Link>
                    <div className="img-log-pcf-text">
                      <h1>Create Your Project!</h1>
                      <div className="music-text-man">
                        <p>Just complete the form below to get your project live, then let your fans know where to find you by publishing your link on social media and via email!</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <Header homepage={true} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <>
          <section className="highlights bg-grey outer">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div className="outer position-relative">
                    <ul className="nav nav-tabs project-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button className={this.state.activeTab === "basics" ? "nav-link active" : "nav-link"} id="basics-tab" data-bs-target="#basics" type="button" role="tab" aria-controls="basics" aria-selected="true">
                          Basics
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className={this.state.activeTab === "funding" ? "nav-link active" : "nav-link"} id="funding-tab" data-bs-target="#funding" type="button" role="tab" aria-controls="funding" aria-selected="false">
                          Funding
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className={this.state.activeTab === "rewards" ? "nav-link active" : "nav-link"} id="rewards-tab" data-bs-target="#rewards" type="button" role="tab" aria-controls="rewards" aria-selected="false">
                          Rewards
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className={this.state.activeTab === "story" ? "nav-link active" : "nav-link"} id="story-tab" data-bs-target="#story" type="button" role="tab" aria-controls="story" aria-selected="false">
                          Story
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className={this.state.activeTab === "payment" ? "nav-link active" : "nav-link"} id="payment-tab" data-bs-target="#payment" type="button" role="tab" aria-controls="payment" aria-selected="false">
                          Payment
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content mt-4" id="myTabContent">
                      <div
                        className={
                          this.state.activeTab === "basics"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="basics"
                        role="tabpanel"
                        aria-labelledby="basics-tab"
                      >
                        <div className="row">
                          <div className="form-frame">
                            <label htmlFor="title">Title</label>
                            <input
                              type="text"
                              name="name"
                              value={this.state.fields.name}
                              onChange={this.handleChange}
                            />
                          </div>
                          <span className="error">
                            {this.validator.message(
                              "name",
                              this.state.fields.name,
                              "required"
                            )}
                          </span>
                          <div className="form-frame">
                            <label htmlFor="">Category</label>
                            <select
                              name="selected_categories"
                              onChange={this.handleChange}
                            >
                              <option>Select Cateogry</option>
                              {this.state.categoriesList.map((category, i) => (
                                <option key={i} value={category.id}>
                                  {category.name}
                                </option>
                              ))}
                            </select>
                            <span className="error">
                              {this.validator.message(
                                "selected_categories",
                                this.state.fields.selected_categories,
                                "required"
                              )}
                            </span>
                            <div className="text">
                              <span>Select your campaign category</span>
                            </div>
                          </div>
                          <div className="form-frame">
                            <label htmlFor="tog">Tags</label>
                            <input
                              type="text"
                              placeholder="Tag"
                              name="tags"
                              value={this.state.fields.tags}
                              onChange={this.handleChange}
                            />
                            <div className="text">
                              <span>Separate tags with commas eg: tag1,tag2</span>
                            </div>
                          </div>
                          <div className="form-frame">
                            <div className="row">
                              <label htmlFor="images">Feature Image</label>
                              <div className="col-md-12">
                                <div>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    onChange={this.onSelectFile}
                                    style={{ padding: "8px" }}
                                  />
                                </div>
                                {croppedImageUrl && (
                                  <img
                                    alt="Croppped Image"
                                    style={{ maxWidth: "200px" }}
                                    src={croppedImageUrl}
                                  />
                                )}
                                <Modal
                                  open={this.state.cropModel}
                                  onClose={this.onCloseCropModal}
                                  closeOnOverlayClick={false}
                                >
                                  {this.state.src && (
                                    <>
                                      <ReactCrop
                                        src={this.state.src}
                                        crop={this.state.crop}
                                        onImageLoaded={this.onImageLoaded}
                                        onComplete={this.onCropComplete}
                                        onChange={this.onCropChange}
                                      />
                                    </>
                                  )}
                                  <div className="text-center file-form ">
                                    <a className="theme-btn" onClick={this.saveCropImage}>
                                      Crop Image
                                    </a>
                                  </div>
                                </Modal>

                                {/* <Files
                      className="files-dropzone"
                      onChange={this.onFilesChange}
                      onError={this.onFilesError}
                      accepts={["image/png", "image/jpeg", "image/jpg"]}
                      maxFileSize={10000000}
                      minFileSize={0}
                      clickable
                    >
                      Select Featured Image
                    </Files> */}
                              </div>
                              <div className="col-md-12">
                                {this.state.filePreview ? (
                                  <>
                                    <img
                                      style={{ width: 200 }}
                                      src={this.state.filePreview}
                                    ></img>
                                  </>
                                ) : null}
                              </div>

                              <div className="form-frame">
                                <span>Upload a campaign feature image</span>
                              </div>
                            </div>
                          </div>
                          <div className="form-frame">
                            <label htmlFor="">Video</label>
                            <input
                              type="text"
                              name="video"
                              placeholder="https://"
                              value={this.state.fields.video}
                              onChange={this.handleChange}
                            />
                            <span>Put the campaign video URL here</span>
                          </div>
                          <div className="form-frame">
                            <div className="row">
                              <div className="col-md-6">
                                <label htmlFor="datemax">Start Date</label>
                                <input
                                  type="date"
                                  id="datemax"
                                  name="start_date"
                                  max=""
                                  value={this.state.fields.start_date}
                                  onChange={this.handleChange}
                                />
                                <span className="error">
                                  {this.validator.message(
                                    "start_date",
                                    this.state.fields.start_date,
                                    "required"
                                  )}
                                </span>
                                <span>Campaign start date (dd/mm/yyyy)</span>
                              </div>

                              <div className="col-md-6">
                                <label htmlFor="datemax">End Date</label>
                                <input
                                  type="date"
                                  id="datemax"
                                  name="end_date"
                                  value={this.state.fields.end_date}
                                  onChange={this.handleChange}
                                />
                                <span className="error">
                                  {this.validator.message(
                                    "end_date",
                                    this.state.fields.end_date,
                                    "required"
                                  )}
                                </span>
                                <span>Campaign start date (dd/mm/yyyy)</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-12 action-btns justify-content-end d-flex">
                            <a
                              className="btn btn-blue"
                              onClick={(e) => this.saveDraftStep(e, "funding")}
                            >
                              Save Draft
                              {this.state.isDraftBtnLoading ? (
                                <>
                                  &nbsp;
                                  <i className="fa-solid fa-spinner fa-spin"></i>
                                </>
                              ) : (
                                <></>
                              )}
                            </a>
                            <a
                              className="theme-btn"
                              onClick={(e) => this.changeStep(e, "funding")}
                            >
                              Next
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          this.state.activeTab === "funding"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="funding"
                        role="tabpanel"
                        aria-labelledby="funding-tab"
                      >
                        <div className="form-frame">
                          <div className="row">
                            <div className="col-lg-6">
                              <label>
                                Funding Goal ({this.state.defaultCurrencyCode.symbol})
                              </label>
                              <input
                                type="number"
                                min=""
                                max=""
                                name="target_goal"
                                value={this.state.fields.target_goal}
                                onChange={this.handleChange}
                              />
                              <span className="error">
                                {this.validator.message(
                                  "target_goal",
                                  this.state.fields.target_goal,
                                  "required"
                                )}
                              </span>
                              <span>Recommended campaign funding amount</span>
                            </div>
                            <div className="col-lg-6">
                              <label htmlFor="">
                                Predefined Pledge Amount (
                                {this.state.defaultCurrencyCode.symbol})
                              </label>
                              <input
                                type="text"
                                name="predefined_amounts"
                                value={this.state.fields.predefined_amounts}
                                onChange={this.handleChange}
                              />
                              <div className="text">
                                <span>
                                  Predefined amount allow you to place the amount in
                                  donate box by click, price should separated by comma
                                  (,), example: <small> 25,50,100</small>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-12 action-btns justify-content-end d-flex">
                            <a
                              className="btn btn-blue"
                              onClick={(e) => this.saveDraftStep(e, "basics")}
                            >
                              Save Draft
                              {this.state.isDraftBtnLoading ? (
                                <>
                                  &nbsp;
                                  <i className="fa-solid fa-spinner fa-spin"></i>
                                </>
                              ) : (
                                <></>
                              )}
                            </a>
                            <a
                              className="btn btn-grey"
                              onClick={(e) => this.changeStep(e, "basics")}
                            >
                              Back
                            </a>
                            <a
                              className="btn btn-primary"
                              onClick={(e) => this.changeStep(e, "rewards")}
                            >
                              Next
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          this.state.activeTab === "rewards"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="rewards"
                        role="tabpanel"
                        aria-labelledby="rewards-tab"
                      >
                        {this.state.rewardValues.map((element, index) => (
                          <div className="row" key={index}>
                            <div className="col-md-12">
                              <div className="form-frame">
                                <label htmlFor="title">Reward Title</label>
                                <input
                                  type="text"
                                  name="title"
                                  value={element.title || ""}
                                  onChange={(e) => this.handleChangeForm(index, e)}
                                />
                              </div>
                              <span className="error">
                                {this.validator.message(
                                  "title",
                                  this.state.rewardValues[index].title,
                                  "required"
                                )}
                              </span>
                            </div>
                            <div className="col-md-12">
                              <div className="form-frame">
                                <label htmlFor="title">Description</label>
                                <textarea
                                  name="description"
                                  value={element.description || ""}
                                  onChange={(e) => this.handleChangeForm(index, e)}
                                ></textarea>
                              </div>
                              <span className="error">
                                {this.validator.message(
                                  "description",
                                  this.state.rewardValues[index].description,
                                  "required"
                                )}
                              </span>
                            </div>
                            <div className="col-md-6">
                              <div className="form-frame">
                                <label htmlFor="title">No of Rewards</label>
                                <input
                                  type="number"
                                  name="no_of_rewards"
                                  value={element.no_of_rewards || ""}
                                  onChange={(e) => this.handleChangeForm(index, e)}
                                />
                              </div>
                              <span className="error">
                                {this.validator.message(
                                  "no_of_rewards",
                                  this.state.rewardValues[index].no_of_rewards,
                                  "required"
                                )}
                              </span>
                            </div>
                            <div className="col-md-6">
                              <div className="form-frame">
                                <label htmlFor="title">
                                  Amount ({this.state.defaultCurrencyCode.symbol})
                                </label>
                                <input
                                  type="number"
                                  name="amount"
                                  value={element.amount || ""}
                                  onChange={(e) => this.handleChangeForm(index, e)}
                                />
                              </div>
                              <span className="error">
                                {this.validator.message(
                                  "amount",
                                  this.state.rewardValues[index].amount,
                                  "required"
                                )}
                              </span>
                            </div>
                            <div className="col-md-6">
                              <div className="form-frame">
                                <label htmlFor="title">Delivery</label>
                                <input
                                  type="date"
                                  name="delivery"
                                  value={element.delivery || ""}
                                  onChange={(e) => this.handleChangeForm(index, e)}
                                />
                                <span>Expected Delivery date (dd/mm/yyyy)</span>
                              </div>
                              <span className="error">
                                {this.validator.message(
                                  "delivery",
                                  this.state.rewardValues[index].delivery,
                                  "required"
                                )}
                              </span>
                            </div>
                            <div className="col-md-6">
                              <div className="form-frame">
                                <label htmlFor="title">Media</label>
                                <Files
                                  className="files-dropzone"
                                  onChange={(e) => this.handleChangeFile(e, index)}
                                  onError={this.onFilesError}
                                  accepts={["image/png", "image/jpeg", "image/jpg"]}
                                  maxFileSize={10000000}
                                  minFileSize={0}
                                  value={element.media_id || ""}
                                  clickable
                                >
                                  Select Featured Image
                                </Files>
                                {element.mediaPreview != "" ? (
                                  <>
                                    <img
                                      style={{ width: 200 }}
                                      src={element.mediaPreview}
                                    ></img>
                                  </>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6">
                              {/* {index ? ( */}
                              <button
                                type="button"
                                className="btn btn-danger remove mt-3"
                                onClick={() => this.removeFormFields(index)}
                              >
                                Remove
                              </button>
                              {/* ) : null} */}
                            </div>
                          </div>
                        ))}
                        <div className="button-section">
                          <button
                            className="btn btn-primary add mt-4"
                            type="button"
                            onClick={() => this.addFormFields()}
                          >
                            Add Rewards
                          </button>
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-12 action-btns justify-content-end d-flex">
                            <a
                              className="btn btn-blue"
                              onClick={(e) => this.saveDraftStep(e, "funding")}
                            >
                              Save Draft
                              {this.state.isDraftBtnLoading ? (
                                <>
                                  &nbsp;
                                  <i className="fa-solid fa-spinner fa-spin"></i>
                                </>
                              ) : (
                                <></>
                              )}
                            </a>
                            <a
                              className="btn btn-grey"
                              onClick={(e) => this.changeStep(e, "funding")}
                            >
                              Back
                            </a>
                            <a
                              className="btn btn-primary"
                              onClick={(e) => this.changeStep(e, "story")}
                            >
                              Next
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          this.state.activeTab === "story"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="story"
                        role="tabpanel"
                        aria-labelledby="story-tab"
                      >
                        <div className="row">
                          <div className="form-frame">
                            <label>Description</label>
                            <Editor
                              initialValue="<p></p>"
                              init={{
                                height: 200,
                                menubar: false,
                                plugins: [
                                  "advlist autolink lists link image charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste code help wordcount",
                                ],
                                toolbar:
                                  "undo redo | formatselect | " +
                                  "bold italic backcolor | alignleft aligncenter " +
                                  "alignright alignjustify | bullist numlist outdent indent | " +
                                  "removeformat | help",
                                content_style:
                                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                              }}
                              onEditorChange={this.handleDescriptionChange}
                            />
                            {/* <textarea
                  name="description"
                  id=""
                  cols="30"
                  rows="10"
                  value={this.state.fields.description}
                  onChange={this.handleChange}
                ></textarea> */}
                          </div>
                          <span className="error">
                            {this.validator.message(
                              "description",
                              this.state.fields.description,
                              "required"
                            )}
                          </span>
                          <div className="form-frame">
                            <label>Short-Description</label>
                            <Editor
                              initialValue="<p></p>"
                              init={{
                                height: 200,
                                menubar: false,
                                plugins: [
                                  "advlist autolink lists link image charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste code help wordcount",
                                ],
                                toolbar:
                                  "undo redo | formatselect | " +
                                  "bold italic backcolor | alignleft aligncenter " +
                                  "alignright alignjustify | bullist numlist outdent indent | " +
                                  "removeformat | help",
                                content_style:
                                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                              }}
                              onEditorChange={this.handleShortDescriptionChange}
                            />
                            {/* <textarea
                  name="short_description"
                  id=""
                  cols="30"
                  rows="5"
                  value={this.state.fields.short_description}
                  onChange={this.handleChange}
                ></textarea> */}
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-12 action-btns justify-content-end d-flex">
                            <a
                              className="btn btn-blue"
                              onClick={(e) => this.saveDraftStep(e, "rewards")}
                            >
                              Save Draft
                              {this.state.isDraftBtnLoading ? (
                                <>
                                  &nbsp;
                                  <i className="fa-solid fa-spinner fa-spin"></i>
                                </>
                              ) : (
                                <></>
                              )}
                            </a>
                            <a
                              className="btn btn-grey"
                              onClick={(e) => this.changeStep(e, "rewards")}
                            >
                              Back
                            </a>
                            <a
                              className="btn btn-primary"
                              onClick={(e) => this.changeStep(e, "payment")}
                            >
                              Next
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          this.state.activeTab === "payment"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="payment"
                        role="tabpanel"
                        aria-labelledby="payment-tab"
                      >
                        {this.state.kycAccountStatus == 2 ||
                          this.state.kycAccountStatus == 3 ? (
                          <>
                            <h3 className="mt-4">KYC is completed</h3>
                          </>
                        ) : (
                          <>
                            <button
                              className="btn btn-primary mt-4"
                              onClick={this.getKycLink}
                            >
                              Complete KYC
                              {this.state.isBtnLoading ? (
                                <>
                                  {" "}
                                  <i class="fa-solid fa-spinner fa-spin"></i>
                                </>
                              ) : (
                                <></>
                              )}
                            </button>
                          </>
                        )}
                        <div className="row mt-4">
                          <div className="col-md-12 action-btns justify-content-end d-flex">
                            <a className="btn text-danger" onClick={(e) => this.saveDraftStep(e, "story")}>
                              Save Draft
                              {this.state.isDraftBtnLoading ? (
                                <>
                                  &nbsp;
                                  <i className="fa-solid fa-spinner fa-spin"></i>
                                </>
                              ) : (
                                <></>
                              )}
                            </a>
                            <a
                              className="btn btn-grey"
                              onClick={(e) => this.changeStep(e, "story")}
                            >
                              Back
                            </a>
                            {this.state.kycAccountStatus == 2 ||
                              this.state.kycAccountStatus == 3 ? (
                              <button
                                className="btn btn-primary"
                                disabled={this.state.isLoading}
                                onClick={this.createProject}
                              >
                                Create Project
                                {this.state.isLoading ? (
                                  <>
                                    &nbsp;
                                    <i className="fa-solid fa-spinner fa-spin"></i>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </button>
                            ) : (
                              <button className="btn btn-primary" disabled>
                                Create Project
                              </button>
                            )}
                          </div>
                        </div>
                        {/* <form id="from-back">
                      <div className="file-form d-flex justify-content-between">
                        <a href="#!">Cancel</a>
                        <button className="btn" onClick={this.createProject}>
                          Create Project
                        </button>
                      </div>
                    </form> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </section>
        </>

        <Footer />
      </>
    );
  }
}
