var baseUrl = process.env.REACT_APP_API_ENDPOINT + "api/web/";

export const API_LOGIN_STEP_1 =
  baseUrl + "v1/new-login-flow/generate-login-process";
export const API_LOGIN_STEP_2 = baseUrl + "v1/new-login-flow/new-login";

export const API_SIGNUP_STEP_1 =
  baseUrl + "v1/new-login-flow/generate-login-process";
export const API_SIGNUP_STEP_2 = baseUrl + "v1/new-login-flow/new-login";

export const API_GET_MY_ARTISTS = baseUrl + "v1/artist/my-artist-registrations";
export const API_GET_MY_BANDS = baseUrl + "v1/band/my-band-registrations";
export const API_SEARCH_PROJECTS =
  baseUrl + "v1/projects/search-projects-public";
export const API_MY_PROJECTS = baseUrl + "v1/projects/my-projects";
export const API_MY_CAMPAIGN_DASHBOARD =
  baseUrl + "v1/user/my-campaign-dashboard";
export const API_CREATE_PROJECT = baseUrl + "v1/projects/new-project";
export const API_UPDATE_EVENT = baseUrl + "v1/events/update-event";
export const API_UPDATE_PROJECT = baseUrl + "v1/projects/update-project";
export const API_SPLIT_PERFORMANCE_PROJECT =
  baseUrl + "v1/events/create-event-performance-split";
export const API_VIEW_PROJECT = baseUrl + "v1/projects/view-public";
export const API_MY_RAISED = baseUrl + "v1/projects/my-raised";
export const API_MY_PLEDGED = baseUrl + "v1/projects/my-pledged";
export const API_USER_CARD_ADD = baseUrl + "v1/user/add-card";
export const API_USER_PROFILE = baseUrl + "v1/user/profile";
export const API_USER_UPDATE_PROFILE = baseUrl + "v1/auth/update-profile";
export const API_SEND_TIP = baseUrl + "v1/tip/send-tip";
export const API_SAVE_REWARD_ORDER = baseUrl + "v1/projects/save-reward-order";
export const API_GET_REWARD = baseUrl + "v1/projects/get-reward";
export const API_GET_SHIPPING_ADDRESS =
  baseUrl + "v1/shipping-address/get-all-address";
export const API_CREATE_SHIPPING_ADDRESS =
  baseUrl + "v1/shipping-address/save-address";
export const API_UPDATE_SHIPPING_ADDRESS =
  baseUrl + "v1/shipping-address/update-address";
export const API_DELETE_SHIPPING_ADDRESS =
  baseUrl + "v1/shipping-address/delete-address";
export const API_UPLOAD_IMAGE = baseUrl + "media/manage/upload";
export const API_FOLLOW_PROJECT = baseUrl + "v1/follow-people/follow-people";
export const API_GET_CATEGORIES = baseUrl + "v2/categories";
export const API_UNFOLLOW_PROJECT =
  baseUrl + "v1/follow-people/unfollow-people";
export const API_CHECK_FOLLOW_PROJECT =
  baseUrl + "v1/follow-people/am-i-following";
export const API_CONTACT = baseUrl + "v1/projects/contact";
export const API_PROJECT_POST_UPDATE = baseUrl + "v1/projects/create-update";
export const API_PROJECT_DELETE_UPDATE = baseUrl + "v1/projects/delete-update";
export const API_ARTIST_CREATE = baseUrl + "v1/artist/artist-registration";
export const API_GET_ARTIST = baseUrl + "v1/artist/view-public";
export const API_GET_BAND = baseUrl + "v1/band/view";
export const API_GET_SOCIAL_LINKS = baseUrl + "v1/social-link/";
export const API_CREATE_SOCIAL_LINK =
  baseUrl + "v1/handle-has-social-link/create-link";
export const API_KYC = baseUrl + "v1/user/get-kyc-link";
export const API_KYC_STATUS = baseUrl + "v1/user/connected-account-status";
export const API_STRIPE_PAYMENT_METHODS =
  "https://api.stripe.com/v1/payment_methods";
export const API_GET_DEFAULT_CURRENCY =
  baseUrl + "v1/currency/get-default-currency";
export const API_GET_ALL_CURRENCY_CONVERSION =
  baseUrl + "v1/currency/get-all-currency-conversion";
