import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { ShowToast } from "../helpers/Helpers";
import { setUserData, setLocalData, getLocalData } from "../helpers/Storage";
import {
  API_LOGIN_STEP_1,
  API_LOGIN_STEP_2,
  API_USER_UPDATE_PROFILE,
} from "../config/endpoints";

const initialState = {
  fields: {
    phone_number: "",
    country: "US",
    timezone: "America/New_York",
    language: "en-US",
    confirmation_token: "",
    confirmation_numbers: "",
  },
  isLoading: false,
  redirect: false,
  currentStep: 1,
  tempToken: null,
};
let redirectUrl = getLocalData("pk_redirect_login");
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    redirectUrl = getLocalData("pk_redirect_login");
    console.log("url", redirectUrl);
  }

  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  handleChangePhone = (value, data, event, formattedValue) => {
    console.log("ok", data);
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        phone_number: value,
      },
    }));
  };

  loginStepOne = (e) => {
    e.preventDefault();

    if (this.validator.allValid()) {
      this.setState({
        isLoading: true,
      });
      fetch(API_LOGIN_STEP_1, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.state.fields),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            let errorList = data.error.fieldErrors;
            errorList.forEach((element) => {
              ShowToast("error", element.message);
            });
            this.setState((prevState) => ({
              isLoading: false,
            }));
          } else {
            ShowToast("success", "OTP sent to your phone number.");
            this.setState((prevState) => ({
              fields: {
                ...prevState.fields,
                confirmation_token: data.data.confirmationToken,
              },
              isLoading: false,
              currentStep: 2,
            }));
          }
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  loginStepTwo = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({
        isLoading: true,
      });
      fetch(API_LOGIN_STEP_2, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.state.fields),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            let errorList = data.error.fieldErrors;
            if (errorList.confirmation_numbers) {
              ShowToast("error", errorList.confirmation_numbers);
            }
            // errorList.forEach((element) => {
            //   ShowToast("error", element.message);
            // });
            this.setState((prevState) => ({
              isLoading: false,
            }));
          } else {
            //save user here
            if (data.data.phone1Confirmed && data.data.status == "active") {
              setUserData(data);
              ShowToast("success", "Logged in.");
              if (redirectUrl) {
                this.setState((prevState) => ({
                  currentStep: 1,
                  redirect: redirectUrl,
                  // isLoading: false,
                }));
              } else {
                this.setState((prevState) => ({
                  currentStep: 1,
                  redirect: "/projects",
                  // isLoading: false,
                }));
              }

              console.log("user", data);
            } else {
              // setLocalData("tempUser", data.data.token.accessToken);
              this.setState((prevState) => ({
                currentStep: 3,
                isLoading: false,
                tempToken: data.data.token.accessToken,
              }));
            }
          }
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  loginStepThree = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({
        isLoading: true,
      });
      console.log(this.state);
      fetch(API_USER_UPDATE_PROFILE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: this.state.tempToken,
          Authorization: "Bearer " + this.state.tempToken,
        },
        body: JSON.stringify(this.state.fields),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            let errorList = data.error.fieldErrors;
            errorList.forEach((element) => {
              ShowToast("error", element.message);
            });
            this.setState((prevState) => ({
              isLoading: false,
            }));
          } else {
            //save user here
            if (data.data.phone1Confirmed && data.data.status == "active") {
              setUserData(data);
              ShowToast("success", "Logged in.");
              if (redirectUrl) {
                this.setState((prevState) => ({
                  currentStep: 1,
                  redirect: redirectUrl,
                  // isLoading: false,
                }));
              } else {
                this.setState((prevState) => ({
                  currentStep: 1,
                  redirect: "/projects",
                  // isLoading: false,
                }));
              }
              console.log("user", data);
            } else {
              this.setState((prevState) => ({
                currentStep: 3,
                isLoading: false,
              }));
            }
          }
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    if (this.state.redirect) {
      window.location = this.state.redirect;
    }
    return (
      <>
        <Helmet>
          <title>Pickfunder - Login</title>
        </Helmet>

        <>
          <section className="login-form">
            <div className="text-titie-back">
              <Link to={"/"}>
                <span>back</span>
              </Link>
            </div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="design d-flex align-items-center justify-content-center flex-column animate__animated animate__bounce animate__backInDown">
                    <div className="logo">
                      <Link to="/">
                        <img
                          src="images/logo1.png"
                          alt=""
                          className="img-fluid"
                          width="330px"
                        />
                      </Link>
                    </div>
                    {this.state.currentStep === 1 ? (
                      <form id="loginForm">
                        <label htmlFor="phone_number">Mobile Number</label>
                        <div className="phone-number mt-1 mb-3 d-flex">
                          <PhoneInput
                            name="phone_number"
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="US"
                            value={this.state.fields.phone_number}
                            onChange={this.handleChangePhone}
                          />
                        </div>

                        <label className="error">
                          {this.validator.message(
                            "phone_number",
                            this.state.fields.phone_number,
                            "required"
                          )}
                        </label>

                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="remember"
                        />
                        <label className="form-check-label" htmlFor="remember">
                          {" "}
                          &nbsp; Remember Me
                        </label>
                        <button
                          type="submit"
                          form="loginForm"
                          className="submit-button"
                          onClick={this.loginStepOne}
                        >
                          LOGIN WITH OTP
                        </button>

                        {/* <label className="label">
                            Don't have an account?
                          </label>
                          <Link to="/signup" className="signup-button">
                            SIGNUP
                          </Link> */}
                      </form>
                    ) : this.state.currentStep === 2 ? (
                      <form id="loginForm">
                        <label
                          htmlFor="phone_number"
                          className="text-center w-100"
                        >
                          Enter OTP
                        </label>
                        <div className="phone-number mt-1 mb-3 d-flex">
                          <input
                            name="confirmation_numbers"
                            onChange={this.handleChange}
                            value={this.state.fields.confirmation_numbers}
                          />
                        </div>
                        <label className="error">
                          {this.validator.message(
                            "OTP",
                            this.state.fields.confirmation_numbers,
                            "required"
                          )}
                        </label>
                        <button
                          type="submit"
                          form="loginForm"
                          className="submit-button"
                          onClick={this.loginStepTwo}
                        >
                          LOGIN
                        </button>

                        {/* <label className="label">
                            Don't have an account?
                          </label>
                          <Link to="/signup" className="signup-button">
                            SIGNUP
                          </Link> */}
                      </form>
                    ) : (
                      <>
                        <form id="loginForm">
                          <label htmlFor="phone_number">First Name</label>
                          <div className="phone-number mt-1 mb-3 d-flex">
                            <input
                              name="first_name"
                              onChange={this.handleChange}
                              value={this.state.fields.first_name}
                            />
                          </div>
                          <label className="error">
                            {this.validator.message(
                              "first_name",
                              this.state.fields.first_name,
                              "required"
                            )}
                          </label>
                          <label htmlFor="phone_number">Last Name</label>
                          <div className="phone-number mt-1 mb-3 d-flex">
                            <input
                              name="last_name"
                              onChange={this.handleChange}
                              value={this.state.fields.last_name}
                            />
                          </div>
                          <label className="error">
                            {this.validator.message(
                              "last_name",
                              this.state.fields.last_name,
                              "required"
                            )}
                          </label>
                          <label htmlFor="phone_number">Email</label>
                          <div className="phone-number mt-1 mb-3 d-flex">
                            <input
                              name="email"
                              type="email"
                              onChange={this.handleChange}
                              value={this.state.fields.email}
                            />
                          </div>
                          <label className="error">
                            {this.validator.message(
                              "email",
                              this.state.fields.email,
                              "required"
                            )}
                          </label>
                          <label htmlFor="phone_number">Username</label>
                          <div className="phone-number mt-1 mb-3 d-flex">
                            <input
                              name="username"
                              onChange={this.handleChange}
                              value={this.state.fields.username}
                            />
                          </div>
                          <label className="error">
                            {this.validator.message(
                              "username",
                              this.state.fields.username,
                              "required"
                            )}
                          </label>
                          <button
                            type="submit"
                            form="loginForm"
                            className="submit-button"
                            onClick={this.loginStepThree}
                          >
                            LOGIN
                          </button>

                          {/* <label className="label">
                              Don't have an account?
                            </label>
                            <Link to="/signup" className="signup-button">
                              SIGNUP
                            </Link> */}
                        </form>
                      </>
                    )}
                    {this.state.isLoading ? (
                      <>
                        <div className="coutom-spinner d-flex justify-content-center align-items-center">
                          <div className="spinner-border text-light">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      </>
    );
  }
}
