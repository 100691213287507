import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Footer from "../components/sections/Footer";
import Header from "../components/sections/Header";
import { API_SEARCH_PROJECTS, API_GET_CATEGORIES } from "../config/endpoints";
import { convertToSlug } from "../helpers/Helpers";
import { getLocalDataObject, getUserData } from "../helpers/Storage";
import ProjectCard from "../components/cards/ProjectCard";
import ContentLoader from "react-content-loader";

export default class Project extends Component {
  constructor(props) {
    super(props);
    let userData = getUserData();
    let defaultArtist = getLocalDataObject("defaultArtist");
    this.state = {
      redirect: false,
      userProfile: userData.userProfile,
      isLoggedIn: userData.isLoggedIn,
      defaultArtist: defaultArtist,
      allProjectsList: [],
      categoriesList: [],
      isLoadingCategory: true,
      isLoading: false,
      currentPage: 1,
      pageSize: 9,
      hidePageLoadMore: false,
      isLoadMoreLoading: false,
      pageDetails: {
        currentPage: 1,
        pageCount: 0,
        perPage: 10,
        totalCount: 0,
      },
    };
  }
  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.fetchCategoriesList();
    this.fetchProjects();
  }

  fetchCategoriesList() {
    fetch(API_GET_CATEGORIES, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          this.setState({
            categoriesList: data.output.data,
            isLoadingCategory: false,
          });
        }
      });
  }

  fetchProjects() {
    this.setState({ isLoadMoreLoading: true });
    fetch(
      API_SEARCH_PROJECTS +
        "?event_type=project&sort_by=goal&page_size=" +
        this.state.pageSize +
        "&page=" +
        this.state.currentPage +
        "&start_date=" +
        Date.now(),
      {
        method: "GET",
      }
    )
      .then((response) => {
        this.setState((state) => ({
          pageDetails: {
            currentPage: parseInt(
              response.headers.get("x-pagination-current-page")
            ),
            pageCount: parseInt(
              response.headers.get("x-pagination-page-count")
            ),
            perPage: parseInt(response.headers.get("x-pagination-per-page")),
            totalCount: parseInt(
              response.headers.get("x-pagination-total-count")
            ),
          },
        }));
        return response.json();
      })
      .then((data) => {
        if (!data.success) {
        } else {
          let newData = data.data;

          this.setState({
            isLoading: false,
            isLoadMoreLoading: false,
            // currentPage: this.state.currentPage + 1,
            currentPage: this.state.pageDetails.currentPage + 1,
          });
          console.log(this.state.pageDetails);
          newData.forEach((dataIndex) => {
            this.setState((state) => ({
              allProjectsList: [...state.allProjectsList, dataIndex],
            }));
          });
          if (this.state.currentPage >= this.state.pageDetails.pageCount + 1) {
            this.setState({
              hidePageLoadMore: true,
            });
          }

          // this.setState((state) => ({
          //   allProjectsList: { ...state.allProjectsList, newData },
          // }));

          // this.setState((prevState) => ({
          //   allProjectsList: {
          //     ...prevState.allProjectsList,
          //     allProjectsList: [newData],
          //   },
          // }));
          // console.log(merged);
        }
        console.log(this.state.allProjectsList);
      });
  }
  changePage = (e) => {
    this.fetchProjects();
  };
  render() {
    return (
      <>
        <Helmet>
          <title>Pickfunder | Project</title>
        </Helmet>

        <section className="header" id="header">
          <div className="back-img pb-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 offset-lg-1">
                  <div className="img-log-pcf pt-4 pt-lg-0">
                    <Link to="/">
                      <img src="/images/logo-2.png" alt="PickFunder" />
                    </Link>
                    <div className="img-log-pcf-text">
                      <h1>Creators you can back!</h1>
                      <div className="music-text-man">
                        <p>Explore Projects</p>
                      </div>
                      <ul className="mt-4 categories d-flex m-0 p-0 list-unstyled justify-content-center flex-wrap justify-content-md-start">
                        {this.state.categoriesList.map((category, index) => (
                          <li key={index}>
                            <a
                              href={
                                `/projectsfind/${category.id}-` +
                                convertToSlug(category.name)
                              }
                            >
                              {category.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <Header homepage={true} />
                </div>
              </div>
            </div>
          </div>
        </section>

        {this.state.isLoading ? (
          <section className="project">
            <div className="container">
              <div className="row mt-4 mb-5">
                <ContentLoader
                  speed={2}
                  width={800}
                  height={500}
                  viewBox="0 0 800 500"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
                  <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
                  <rect x="0" y="56" rx="3" ry="3" width="607" height="9" />
                  <rect x="0" y="80" rx="3" ry="3" width="562" height="9" />
                  <circle cx="20" cy="20" r="20" />
                  <rect x="608" y="202" rx="3" ry="3" width="607" height="9" />
                  <rect x="608" y="178" rx="3" ry="3" width="562" height="9" />
                  <rect x="608" y="155" rx="3" ry="3" width="263" height="9" />
                  <rect x="-5" y="128" rx="3" ry="3" width="607" height="9" />
                  <rect x="-5" y="152" rx="3" ry="3" width="502" height="9" />
                  <rect x="-5" y="175" rx="3" ry="3" width="600" height="9" />
                  <rect x="1" y="105" rx="3" ry="3" width="450" height="9" />
                  <rect x="-1" y="204" rx="3" ry="3" width="607" height="9" />
                  <rect x="-1" y="228" rx="3" ry="3" width="562" height="9" />
                  <rect x="-6" y="276" rx="3" ry="3" width="607" height="9" />
                  <rect x="-6" y="300" rx="3" ry="3" width="502" height="9" />
                  <rect x="-6" y="323" rx="3" ry="3" width="600" height="9" />
                  <rect x="0" y="253" rx="3" ry="3" width="450" height="9" />
                </ContentLoader>
              </div>
            </div>
          </section>
        ) : (
          <section className="explore-project">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div className="row">
                    {this.state.allProjectsList.length > 0 ? (
                      this.state.allProjectsList.map((project, index) => (
                        <div className="col-md-4 mb-3">
                          <ProjectCard key={index} project={project} />
                        </div>
                      ))
                    ) : (
                      <>
                        <h4 className="text-center">
                          No projects! Check back soon!
                        </h4>
                        {this.state.isLoggedIn ? (
                          <>
                            <Link
                              className="btn createproject"
                              to={"/createproject"}
                            >
                              CREATE A PROJECT
                            </Link>
                          </>
                        ) : (
                          <>
                            <Link className="btn createproject" to={"/login"}>
                              CREATE A PROJECT
                            </Link>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12 text-center">
                      {this.state.hidePageLoadMore ? (
                        <></>
                      ) : (
                        <>
                          <a
                            className="btn btn-orange"
                            onClick={(e) => this.changePage()}
                          >
                            Load more
                            {this.state.isLoadMoreLoading ? (
                              <>
                                &nbsp;
                                <i className="fa-solid fa-spinner fa-spin"></i>
                              </>
                            ) : (
                              <></>
                            )}
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        <Footer />
      </>
    );
  }
}
