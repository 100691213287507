import fetchIntercept from "fetch-intercept";
import { getUserData, getLocalData } from "./Storage";

export const AuthInterceptor = () => {
  let debug = process.env.REACT_APP_DEBUG;
  let userData = getUserData();
  let localData = getLocalData("tempUser");
  console.log("user", userData);
  fetchIntercept.register({
    request: function (url, config) {
      // Modify the url or config here
      // console.log("headers1", config.headers);
      // console.log("url", url);
      if (config) {
        if (userData.isLoggedIn) {
          // console.log("islogin");
          // let formData = new FormData();
          if (config.body instanceof FormData) {
            if (config.headers && config.headers.token) {
              config.headers = {
                "Content-Type": "application/json",
                Authorization: "Bearer " + config.headers.token,
              };
            } else {
              config.headers = {
                Authorization: "Bearer " + userData.token,
              };
            }
          } else {
            if (config.headers && config.headers.token) {
              config.headers = {
                "Content-Type":
                  "application/x-www-form-urlencoded;charset=UTF-8", //for stripe
                Authorization: "Bearer " + config.headers.token,
              };
            } else {
              config.headers = {
                Authorization: "Bearer " + userData.token,
                "Content-Type": "application/json",
              };
            }
          }
        } else {
          // console.log("data", localData);
          if (config.headers && config.headers.token) {
            config.headers = {
              "Content-Type": "application/json",
              Authorization: "Bearer " + config.headers.token,
            };
          } else {
            config.headers = {
              "Content-Type": "application/json",
            };
          }
        }
      }

      if (debug) {
        // console.log("Request: ", config);
      }

      return [url, config];
    },

    requestError: function (error) {
      // Called when an error occured during another 'request' interceptor call
      if (debug) {
        console.log("Error: ", error);
      }

      return Promise.reject(error);
    },

    response: function (response) {
      // Modify the reponse object
      if (debug) {
        response
          .clone()
          .json()
          .then((data) => {
            // console.log("Response: ", data);
          });
      }

      return response;
    },

    responseError: function (error) {
      // Handle an fetch error
      if (debug) {
        console.log("Error: ", error);
      }

      return Promise.reject(error);
    },
  });
};
