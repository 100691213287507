import React, { Component } from "react";
import { Helmet } from "react-helmet";
import CreateArtist from "../components/CreateArtist";
import Footer from "../components/sections/Footer";
import Header from "../components/sections/Header";
import { API_MY_RAISED } from "../config/endpoints";
import { Link } from "react-router-dom";
import { getLocalDataObject, getUserData } from "../helpers/Storage";

export default class ArtistPage extends Component {
  constructor(props) {
    super(props);
    let userData = getUserData();
    let defaultArtist = getLocalDataObject("defaultArtist");
    this.state = {
      redirect: false,
      userProfile: userData.userProfile,
      isLoggedIn: userData.isLoggedIn,
      defaultArtist: defaultArtist,
      isLoading: false,
      myProjectsList: [],
      myStats: [],
      myRaised: [],
      amountByDays: [],
    };
  }
  componentDidMount() {}

  render() {
    return (
      <>
        <Helmet>
          <title>Pickfunder | Setup Profile</title>
        </Helmet>

        <section className="header" id="header">
          <div className="back-img">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 offset-lg-1">
                  <div className="img-log-pcf pt-4 pt-lg-0">
                    <Link to="/">
                      <img src="/images/logo-2.png" alt="PickFunder" />
                    </Link>
                    <div className="img-log-pcf-text">
                      <h1>Complete your profile.</h1>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <Header homepage={true} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="highlights bg-grey">
          <div className="container">
            <div className="row">
              <div className="offset-lg-1 col-lg-10">
                <div className="outer position-relative">
                  {this.state.isLoading ? (
                    <>
                      <div className="coutom-spinner d-flex justify-content-center align-items-center">
                        <div className="spinner-border text-light">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <CreateArtist />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        
        <Footer />
      </>
    );
  }
}
