import fetchIntercept from "fetch-intercept";
import { getUserData, getLocalData, logoutUser } from "./Storage";

export const CheckSession = () => {
  let debug = process.env.REACT_APP_DEBUG;
  let userData = getUserData();
  let localData = getLocalData("tempUser");
  let session = getLocalData("pkLoginSession");
  if (session) {
    var currentDate = new Date().getTime() / 1000;
    console.log("currentDate", currentDate);
    if (currentDate > session) {
      logoutUser();
    }
  } else {
    logoutUser();
  }
};
