import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/sections/Footer";
import Header from "../components/sections/Header";
import { Link, useParams } from "react-router-dom";
import ContentLoader from "react-content-loader";

import {
  getLocalDataObject,
  getUserData,
  setUserData,
  setLocalData,
  setProjectReward,
} from "../helpers/Storage";
import { API_USER_PROFILE } from "../config/endpoints";
import { ShowToast } from "../helpers/Helpers";
import ProjectCard from "../components/cards/ProjectCard";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { socialIcon } from "../helpers/Helpers";

let userData = getUserData();

function AutoLoginBand() {
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  let { bandid, token } = useParams();

  useEffect(() => {
    console.log(token);
    setIsLoading(true);
    fetch(API_USER_PROFILE, {
      method: "GET",
      headers: {
        token: token,
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          setIsLoading(false);
          ShowToast("error", "Unable to login please try again.");
        } else {
          console.log(data);
          setUserData(data);
          setIsLoading(false);
          window.location = "/band/" + bandid;
        }
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Pickfunder | Artist Profile</title>
      </Helmet>
      {isLoading ? (
        <>
          <div className="coutom-spinner d-flex justify-content-center align-items-center">
            <div className="spinner-border text-light">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
export default AutoLoginBand;
