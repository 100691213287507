import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/sections/Footer";
import Header from "../components/sections/Header";
import { API_MY_RAISED } from "../config/endpoints";
import { getLocalDataObject, getUserData } from "../helpers/Storage";
import { Link} from "react-router-dom";

export default class MyRaised extends Component {
  constructor(props) {
    super(props);
    let userData = getUserData();
    let defaultArtist = getLocalDataObject("defaultArtist");
    this.state = {
      redirect: false,
      userProfile: userData.userProfile,
      isLoggedIn: userData.isLoggedIn,
      defaultArtist: defaultArtist,
      isLoading: false,
      myProjectsList: [],
      myStats: [],
      myRaised: [],
      amountByDays: [],
      selectedRanges: {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
        key: "selection",
      }, //range
    };
  }
  componentDidMount() {
    if (this.state.defaultArtist) {
      this.setState({
        isLoading: true,
      });
      fetch(API_MY_RAISED + "?artist_id=" + this.state.defaultArtist.id, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
          } else {
            this.setState({
              myRaised: data.data,
              isLoading: false,
            });
          }
        });
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Pickfunder | MyCampaigns</title>
        </Helmet>
        <section className="header" id="header">
          <div className="back-img">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 offset-lg-1">
                  <div className="img-log-pcf pt-4 pt-lg-0">
                    <Link to="/">
                      <img src="/images/logo-2.png" alt="PickFunder" />
                    </Link>
                    <div className="img-log-pcf-text">
                      <h1>My Raised</h1>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <Header homepage={true} />
                </div>
              </div>
            </div>
          </div>
        </section>

        {this.state.isLoading ? (
          <>
            <div className="coutom-spinner d-flex justify-content-center align-items-center">
              <div className="spinner-border text-light">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10">
                <table className="table table-striped table-hover table-bordered  mt-5 mb-5">
                  <tr>
                    <th>Title</th>
                    <th>Sender Username</th>
                    <th>Amount</th>
                    <th>Date</th>
                  </tr>
                  {this.state.myRaised.map((project, index) => (
                    <tr key={index}>
                      <td style={{ width: "60%" }}>
                        <div className="row">
                          <div className="col-md-2">
                            {project.event.avatar.originalImage ? (
                              <>
                                <img
                                  src={project.event.avatar.originalImage}
                                  className="d-block w-100 ui-bordered mr-4"
                                  alt="..."
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src="/images/banner.jpg"
                                  alt="reward-1"
                                  className="d-block w-100 ui-bordered mr-4"
                                />
                              </>
                            )}
                          </div>
                          <div className="col-md-6"> {project.event.name}</div>
                        </div>
                      </td>
                      <td>@{project.sender.userName}</td>
                      <td>${project.amountTipped}</td>
                      <td>{project.createdAt}</td>
                    </tr>
                  ))}
                </table>
                </div>
              </div>
            </div>
          </>
        )}
        <Footer />
      </>
    );
  }
}
