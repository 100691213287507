import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/sections/Footer";
import Header from "../components/sections/Header";
import { Link, useParams } from "react-router-dom";
import ContentLoader from "react-content-loader";

import {
  getLocalDataObject,
  getUserData,
  setLocalData,
  setProjectReward,
} from "../helpers/Storage";
import {
  API_GET_BAND,
  API_MY_PROJECTS,
  API_FOLLOW_PROJECT,
  API_UNFOLLOW_PROJECT,
  API_CHECK_FOLLOW_PROJECT,
  API_SEARCH_PROJECTS,
} from "../config/endpoints";
import { ShowToast } from "../helpers/Helpers";
import ProjectCard from "../components/cards/ProjectCard";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { socialIcon } from "../helpers/Helpers";

let userData = getUserData();

function BandProfile() {
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [isLoadingProjects, setIsLoadingProjects] = useState(false);
  const [followColor, setFollowColor] = useState("white");
  const [artistProjects, setArtistProjects] = useState([]);
  const [artistDetails, setArtistDetails] = useState({
    avatar: { originalImage: null },
    socialLinks: [],
  });
  let { id } = useParams();

  useEffect(() => {
    setIsLoading(true);
    getArtistDetails();
    getArtistProjects();
    if (userData.isLoggedIn) {
      fetch(
        API_CHECK_FOLLOW_PROJECT +
          "?user_id=" +
          userData.user.id +
          "&band_id=" +
          id,
        {
          method: "POST",
          body: JSON.stringify({
            user_id: userData.user.id,
            band_id: id,
            notify_me: 1,
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            let errorList = data.error.fieldErrors;
            if (errorList) {
              errorList.forEach((element) => {
                ShowToast("error", element.message);
              });
            } else {
              ShowToast("error", "Something went wrong!");
            }
          } else {
            console.log("followed", data.data);
            let obj = data.data;
            if (data.data.length === 0) {
              setFollowColor("white");
            } else {
              setFollowColor("red");
            }
          }
        });
    }
  }, []);

  function getArtistDetails() {
    fetch(API_GET_BAND + "?id=" + id, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          setArtistDetails(data.data);
          setIsLoading(false);
        }
      });
  }

  function getArtistProjects() {
    setIsLoadingProjects(true);
    fetch(
      API_SEARCH_PROJECTS +
        "?band_id=" +
        id +
        "&event_type=project&sort_by=goal",
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          setArtistProjects(data.data);
          setIsLoadingProjects(false);
        }
      });
  }
  const handleFollow = () => {
    if (userData.isLoggedIn) {
      setIsLoading(true);
      if (followColor == "red") {
        //unfollow
        fetch(
          API_UNFOLLOW_PROJECT +
            "?user_id=" +
            userData.user.id +
            "&band_id=" +
            id,
          {
            method: "POST",
            body: JSON.stringify({
              user_id: userData.user.id,
              band_id: id,
              notify_me: 1,
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (!data.success) {
              let errorList = data.error.fieldErrors;
              if (errorList) {
                errorList.forEach((element) => {
                  ShowToast("error", element.message);
                });
              } else {
                ShowToast("error", "Something went wrong!");
              }
              setIsLoading(false);
            } else {
              console.log("followed", data);
              ShowToast("success", "You unfollowed this band.");
              setIsLoading(false);
              setFollowColor("white");
            }
          });
      } else {
        fetch(API_FOLLOW_PROJECT, {
          method: "POST",
          body: JSON.stringify({
            user_id: userData.user.id,
            band_id: id,
            notify_me: 1,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (!data.success) {
              let errorList = data.error.fieldErrors;
              if (errorList) {
                errorList.forEach((element) => {
                  ShowToast("error", element.message);
                });
              } else {
                ShowToast("error", "Something went wrong!");
              }
              setIsLoading(false);
            } else {
              console.log("followed", data);
              ShowToast("success", "Great! You are now following this band.");
              setIsLoading(false);
              setFollowColor("red");
            }
          });
      }
    } else {
      setLocalData("pk_redirect_login", window.location.href);
      window.location = "/login";
    }
  };
  return (
    <>
      <Helmet>
        <title>Pickfunder | Band</title>
      </Helmet>
      <Header />
      <section className="container artist-profile">
        <div className="row">
          <div className="col-md-4">
            <div className="images-user-profile-user">
              {artistDetails.avatar.originalImage ? (
                <>
                  <img
                    src={artistDetails.avatar.originalImage}
                    className="img-fluid rounded-circle"
                    alt="..."
                  />
                </>
              ) : (
                <>
                  <img
                    src={"/images/PJ_ProfileIcon.png"}
                    className="img-fluid rounded-circle"
                    alt="..."
                  />
                </>
              )}
              {/* <a href="#">Contact</a> */}
              <div className="icon-user-erin-1 d-flex d-block">
                <a href="#" onClick={handleFollow}>
                  <i
                    className={`fa fa-heart hover follow-btn-artist ${followColor}`}
                  ></i>{" "}
                  {/* Follow */}
                  {followColor == "white" ? "Follow" : "Un-follow"}
                  {isLoading ? (
                    <>
                      &nbsp;
                      <i className="fa-solid fa-spinner fa-spin"></i>
                    </>
                  ) : (
                    <></>
                  )}
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="user-text-profile-usee">
              <h3>Hey, I'm {artistDetails.stageName}!</h3>
              <i className="bi bi-geo-alt"></i>{" "}
              <span>
                {artistDetails.hometownCity} {artistDetails.hometownState}
              </span>
              {/* <div className="icon-user-erin-2 ">
                <i className="bi bi-suit-heart"></i>
                <a href="#">Follow</a>
              </div> */}
              {/* <i className={socialIcon("Spotify")}></i>
              <i className={socialIcon("Facebook")}></i>
              <i className={socialIcon("Instagram")}></i>
              <i className={socialIcon("Twitter")}></i>
              <i className={socialIcon("Reddit")}></i>
              <i className={socialIcon("Apple Music")}></i>
              <i className={socialIcon("Twitch")}></i>
              <i className={socialIcon("Tiktok")}></i> */}
              {artistDetails.socialLinks.map((social, index) => (
                <a href={social.userSocialLink} target={"_blank"}>
                  <i
                    className={`social-icon ${socialIcon(social.social.name)}`}
                    key={index}
                  />
                </a>
              ))}
              {/* <div className="social-icon-user-file-all ">
                <a href="#">
                  <img
                    src="/images/facebook.png"
                    className="img-fluid"
                    width="30"
                  />
                </a>
                <a href="#">
                  <img
                    src="/images/instagram.png"
                    className="img-fluid"
                    width="30"
                  />
                </a>
                <a href="#">
                  <img
                    src="/images/twitter.png"
                    className="img-fluid"
                    width="30"
                  />
                </a>
                <a href="#">
                  <img
                    src="/images/web-link.png"
                    className="img-fluid"
                    width="30"
                  />
                </a>
                <a href="#">
                  <img
                    src="/images/tiktok.png"
                    className="img-fluid"
                    width="30"
                  />
                </a>
                <a href="#">
                  <img
                    src="/images/spotify.png"
                    className="img-fluid"
                    width="30"
                  />
                </a>
              </div> */}
              <p>{artistDetails.bio}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="user-card-profile-us">
        <div className="container">
          <div className="project-text-1">
            <div className="heading-text-Supporting">
              <h3>My Projects</h3>
            </div>
          </div>
          <div className="row mb-4">
            {isLoadingProjects ? (
              <>
                <ContentLoader
                  speed={2}
                  width={800}
                  height={500}
                  viewBox="0 0 800 500"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
                  <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
                  <rect x="0" y="56" rx="3" ry="3" width="607" height="9" />
                  <rect x="0" y="80" rx="3" ry="3" width="562" height="9" />
                  <circle cx="20" cy="20" r="20" />
                  <rect x="608" y="202" rx="3" ry="3" width="607" height="9" />
                  <rect x="608" y="178" rx="3" ry="3" width="562" height="9" />
                  <rect x="608" y="155" rx="3" ry="3" width="263" height="9" />
                  <rect x="-5" y="128" rx="3" ry="3" width="607" height="9" />
                  <rect x="-5" y="152" rx="3" ry="3" width="502" height="9" />
                  <rect x="-5" y="175" rx="3" ry="3" width="600" height="9" />
                  <rect x="1" y="105" rx="3" ry="3" width="450" height="9" />
                  <rect x="-1" y="204" rx="3" ry="3" width="607" height="9" />
                  <rect x="-1" y="228" rx="3" ry="3" width="562" height="9" />
                  <rect x="-6" y="276" rx="3" ry="3" width="607" height="9" />
                  <rect x="-6" y="300" rx="3" ry="3" width="502" height="9" />
                  <rect x="-6" y="323" rx="3" ry="3" width="600" height="9" />
                  <rect x="0" y="253" rx="3" ry="3" width="450" height="9" />
                </ContentLoader>
              </>
            ) : (
              <>
                <OwlCarousel
                  className="owl-carousel owl-theme"
                  // loop
                  margin={10}
                  nav
                  responsive={{
                    0: {
                      items: 1,
                    },
                    600: {
                      items: 2,
                    },
                    1000: {
                      items: 3,
                    },
                  }}
                >
                  {artistProjects.map((project, index) => (
                    <ProjectCard project={project} edit={false} key={index} />
                  ))}
                </OwlCarousel>
              </>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
export default BandProfile;
