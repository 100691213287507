import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ProjectCard from "../components/cards/ProjectCard";
import Footer from "../components/sections/Footer";
import Header from "../components/sections/Header";
import {
  API_MY_PROJECTS,
  API_MY_CAMPAIGN_DASHBOARD,
} from "../config/endpoints";
import { getLocalDataObject, getUserData } from "../helpers/Storage";
import { isArtist } from "../helpers/Helpers";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ContentLoader from "react-content-loader";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
};

let interval = 0;

export default class MyCampaigns extends Component {
  constructor(props) {
    super(props);
    let userData = getUserData();
    let defaultArtist = getLocalDataObject("defaultArtist");
    this.state = {
      redirect: false,
      flag: false,
      userProfile: userData.userProfile,
      isLoggedIn: userData.isLoggedIn,
      defaultArtist: defaultArtist,
      isLoading: false,
      myProjectsList: [],
      myStats: [],
      graphLabels: [],
      graphData: [],
      showGraph: false,
      showDatePicker: false,
      amountByDays: [],
      isLoadingData: false,
      isLoadingProjects: false,
      selectedRanges: {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
        key: "selection",
      },
    };
  }
  componentDidMount() {
    this.checkDefaultArtist();
  }

  checkDefaultArtist = () => {
    console.log("in checkDefaultArtist");
    console.log(this.state);
    console.log(this.state.defaultArtist);
    if (this.state.defaultArtist) {
      console.log("in checkDefaultArtist IF");
      this.getDashboardData();
      // return false;
    } else {
      console.log("in checkDefaultArtist ELSE");
      let defaultArtist = getLocalDataObject("defaultArtist");
      if (defaultArtist) {
        this.setState({ defaultArtist: defaultArtist });
        this.getDashboardData();
        clearInterval(interval);
      } else {
        interval = window.setInterval(this.checkDefaultArtist, 2000);
      }
    }
  };

  handleSelect = (ranges) => {
    this.setState(
      (prevState) => ({
        selectedRanges: ranges.selection,
      }),
      () => {
        if (this.state.defaultArtist) {
          this.getDashboardData();
        }
      }
    );

    if (ranges.selection.startDate != ranges.selection.endDate) {
      this.setState((prevState) => ({
        showDatePicker: false,
      }));
    }
  };

  getDashboardData = () => {
    this.setState({
      isLoadingProjects: true,
    });
    let projectUrl;
    if (this.state.defaultArtist) {
      if (isArtist(getLocalDataObject("defaultArtist"))) {
        projectUrl =
          API_MY_PROJECTS +
          "?artist_id=" +
          this.state.defaultArtist.id +
          "&event_type=project";
      } else {
        projectUrl =
          API_MY_PROJECTS +
          "?band_id=" +
          this.state.defaultArtist.band.id +
          "&event_type=project";
      }
      fetch(projectUrl, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
          } else {
            this.setState({
              myProjectsList: data.data,
              isLoadingProjects: false,
            });
          }
        });

      // console.log("sta", this.selectedRanges.startDate);
      const start_date = Math.floor(this.state.selectedRanges.startDate / 1000);
      const end_date = Math.floor(this.state.selectedRanges.endDate / 1000);
      this.setState({
        isLoadingData: true,
      });
      let dashboardUrl;
      if (isArtist(getLocalDataObject("defaultArtist"))) {
        dashboardUrl =
          API_MY_CAMPAIGN_DASHBOARD +
          "?artist_id=" +
          this.state.defaultArtist.id +
          "&start_date=" +
          start_date +
          "&end_date=" +
          end_date;
      } else {
        dashboardUrl =
          API_MY_CAMPAIGN_DASHBOARD +
          "?band_id=" +
          this.state.defaultArtist.band.id +
          "&start_date=" +
          start_date +
          "&end_date=" +
          end_date;
      }
      fetch(dashboardUrl, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
          } else {
            this.setState({
              myStats: data.data,
              amountByDays: data.data.amountByDays,
              isLoadingData: false,
            });
          }
          this.changeGraphData();
        });
    }
  };
  changeGraphData = () => {
    let amountByDays = this.state.amountByDays;

    let allAmountDates = Object.keys(amountByDays).map(function (key, value) {
      return amountByDays[key].created_at;
    });

    let allAmountTip = Object.keys(amountByDays).map(function (key, value) {
      return amountByDays[key].amount_tipped;
    });
    // labels = allAmountDates;
    // console.log("amount", allAmountTip);
    // console.log("amountdates", allAmountDates);

    const labels = allAmountDates;
    const data = {
      labels,
      datasets: [
        {
          label: "Fund Raised",
          data: allAmountTip,
          backgroundColor: "rgba(255, 153, 0, 0.7)",
        },
      ],
    };
    this.setState({ graphData: data, showGraph: true });
    // console.log("graphData", this.state.graphData);
  };
  render() {
    let graphDataStartDate = this.state.selectedRanges.startDate.toDateString();
    let graphDataEndDate = this.state.selectedRanges.endDate.toDateString();
    return (
      <>
        <Helmet>
          <title>Pickfunder | MyCampaigns</title>
        </Helmet>
        
        <section className="header" id="header">
          <div className="back-img">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 offset-lg-1">
                  <div className="img-log-pcf pt-4 pt-lg-0">
                    <Link to="/">
                      <img src="/images/logo-2.png" alt="PickFunder" />
                    </Link>
                    <div className="img-log-pcf-text">
                      <h1>Dashboard</h1>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <Header homepage={true} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <>

        <section className="highlights dasboard bg-grey">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="outer position-relative">
                  <div className="row">
                    <div className="col-md-8">
                      <b>Showing results from:</b>
                      <br/>
                      {" "}{graphDataStartDate} -{" "} {graphDataEndDate}{" "}&nbsp;&nbsp;&nbsp; 
                      <a className="theme-btn mt-3 mt-md-0" onClick={() => this.setState({ showDatePicker: true })}>
                        Change Dates
                      </a>
                      {this.state.showDatePicker ? (
                        <>
                          &nbsp;&nbsp;&nbsp;
                          <a className="text-danger btn" onClick={() => this.setState({ showDatePicker: false })}>close</a>
                          <div className="my-4">
                            <DateRangePicker className="shadow-sm border" ranges={[this.state.selectedRanges]} onChange={this.handleSelect} direction="horizontal" months={2}/>
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div className="align-items-end col-md-4 d-flex justify-content-center">
                      <Link to={"/createproject"} className="theme-btn mt-4 mt-md-0">Add new Project</Link>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-md-4">
                      <div className="bg-grey mt-3 mt-md-0">
                        <Link to={"/myraised"} className="d-flex flex-column py-4 px-5 fs-1 text-decoration-none text-dark">
                          ${this.state.myStats.totalFundRaised}
                          <label className="text-muted fs-6">Funds Raised</label>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="bg-grey mt-3 mt-md-0">
                        <Link to={"/myraised"} className="d-flex flex-column py-4 px-5 fs-1 text-decoration-none text-dark">
                          {this.state.myStats.totalBacked}
                          <label className="text-muted fs-6">Projects Backed</label>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="bg-grey mt-3 mt-md-0">
                        <Link to={"/myraised"} className="d-flex flex-column py-4 px-5 fs-1 text-decoration-none text-dark">
                          ${this.state.myStats.totalPledged}
                          <label className="text-muted fs-6">Total Amount Pledged</label>
                        </Link>
                      </div>
                    </div>
                    <div className="col-12">
                      {this.state.showGraph ? (
                        <div className="mt-4">
                          <Bar options={options} data={this.state.graphData} />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

          <section className="highlights bg-grey my-campaigns pb-5">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="outer position-relative">
                    <div className="row">
                      <div className="col-12">
                        <h5>My Campaigns</h5>
                        <hr/>
                      </div>
                      <div className="col-12">
                        {this.state.isLoadingProjects ? (
                          <>
                            <ContentLoader speed={2} width={800} height={500} viewBox="0 0 800 500" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
                              <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
                              <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
                              <rect x="0" y="56" rx="3" ry="3" width="607" height="9" />
                              <rect x="0" y="80" rx="3" ry="3" width="562" height="9" />
                              <circle cx="20" cy="20" r="20" />
                            </ContentLoader>
                          </>
                        ) : (
                          <>
                            <div className="row">
                              {this.state.myProjectsList.map((project, index) => (
                                <div className="col-md-4">
                                  <ProjectCard project={project.event} edit={true} key={index} />
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </>
        <Footer />
      </>
    );
  }
}
