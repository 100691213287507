import "./App.css";
import React, { Component } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { AuthInterceptor } from "./helpers/AuthInterceptor";
import { CheckSession } from "./helpers/CheckSession";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getUserData,
  getLocalDataObject,
  asyncLocalStorage,
} from "./helpers/Storage";

import Index from "./pages/Index";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import MyCampaigns from "./pages/MyCampaigns";
import CreateProject from "./pages/CreateProject";
import Project from "./pages/Project";
import ProjectView from "./pages/ProjectView";
import Contact from "./pages/Contact";
import About from "./pages/About";
import MyProfile from "./pages/MyProfile";
import ProjectCategory from "./pages/ProjectCategory";
import MyRaised from "./pages/MyRaised";
import MyPledged from "./pages/MyPledged";
import Rewards from "./pages/Rewards";
import UpdateProject from "./pages/UpdateProject";
import CreateProjectNew from "./pages/CreateProjectNew";
import UpdateProjectNew from "./pages/UpdateProjectNew";
import NotFound from "./pages/NotFound";
import ArtistPage from "./pages/ArtistPage";
import ProjectSearch from "./pages/ProjectSearch";
import ArtistProfile from "./pages/ArtistProfile";
import BandProfile from "./pages/BandProfile";
import AutoLogin from "./pages/AutoLogin";
import AutoLoginArtist from "./pages/AutoLoginArtist";
import AutoLoginBand from "./pages/AutoLoginBand";
import ScrollToTop from "./components/ScrollToTop";

const userData = getUserData();
let defaultArtist = getLocalDataObject("defaultArtist");

export const PrivateRoute = ({ component: Component, ...rest }) => {
  let userData = getUserData();
  let isLoggedIn = userData.isLoggedIn;
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component key={props.match.params.id || "empty"} {...props} />
        ) : (
          <Navigate
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

function RequireAuth({ children, redirectTo }) {
  let userData = getUserData();
  let isLoggedIn = userData.isLoggedIn;
  return isLoggedIn ? children : <Navigate to={redirectTo} />;
}

function LoggedIn({ children, redirectTo }) {
  let userData = getUserData();
  let isLoggedIn = userData.isLoggedIn;
  return isLoggedIn ? <Navigate to={redirectTo} /> : children;
}
AuthInterceptor();
CheckSession();
export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = { artist: null };
  }
  componentDidMount() {
    window.addEventListener("storage", (e) => {
      // console.log(e);
      defaultArtist = getLocalDataObject("defaultArtist");
      this.setState({ artist: defaultArtist });
    });
  }
  render() {
    return (
      <>
        <BrowserRouter basename={"/"}>
          <ScrollToTop />
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route index element={<Index />} />
            <Route path="/projects" element={<Project />} />
            <Route path="/projectsfind/:id" element={<ProjectCategory />} />
            <Route path="/artist/:id" element={<ArtistProfile />} />
            <Route path="/autologin/:token" element={<AutoLogin />} />
            <Route
              path="/autologin/:artistid/:token"
              element={<AutoLoginArtist />}
            />
            <Route
              path="/autologinband/:bandid/:token"
              element={<AutoLoginBand />}
            />
            <Route path="/band/:id" element={<BandProfile />} />
            <Route path="/projectssearch/:key" element={<ProjectSearch />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route exact path="/project/:id" element={<ProjectView />} />
            <Route
              path="/login"
              element={
                <LoggedIn redirectTo="/mycampaigns">
                  <Login />
                </LoggedIn>
              }
            />
            <Route
              path="/signup"
              element={
                <LoggedIn redirectTo="/mycampaigns">
                  <Signup />
                </LoggedIn>
              }
            />
            <Route
              path="/mycampaigns"
              element={
                <RequireAuth redirectTo="/login">
                  <MyCampaigns />
                </RequireAuth>
              }
            />
            <Route
              path="/myraised"
              element={
                <RequireAuth redirectTo="/login">
                  <MyRaised />
                </RequireAuth>
              }
            />
            <Route
              path="/mypledged"
              element={
                <RequireAuth redirectTo="/login">
                  <MyPledged />
                </RequireAuth>
              }
            />
            <Route
              path="/editProject"
              element={
                <RequireAuth redirectTo="/login">
                  <UpdateProjectNew />
                </RequireAuth>
              }
            />
            <Route
              path="/rewards/:id"
              element={
                <RequireAuth redirectTo="/login">
                  <Rewards />
                </RequireAuth>
              }
            />
            <Route
              path="/myprofile"
              element={
                <RequireAuth redirectTo="/login">
                  <MyProfile />
                </RequireAuth>
              }
            />
            <Route
              path="/createproject"
              element={
                <RequireAuth redirectTo="/login">
                  {this.state.artist ? <CreateProjectNew /> : <ArtistPage />}
                  {/* {defaultArtist ? <ArtistPage /> : <CreateProjectNew />} */}
                </RequireAuth>
              }
            />
            <Route
              path="/createprojectnew"
              element={
                <RequireAuth redirectTo="/login">
                  <CreateProjectNew />
                </RequireAuth>
              }
            />
          </Routes>
        </BrowserRouter>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </>
    );
  }
}
