import React, { Component, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

import { Helmet } from "react-helmet";
import Footer from "../components/sections/Footer";
import Header from "../components/sections/Header";
import SimpleReactValidator from "simple-react-validator";
import {Link,  Navigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import {
  API_CREATE_PROJECT,
  API_GET_CATEGORIES,
  API_UPLOAD_IMAGE,
  API_USER_PROFILE,
  API_ARTIST_CREATE,
} from "../config/endpoints";
import { getLocalDataObject, getUserData } from "../helpers/Storage";
import { ShowToast } from "../helpers/Helpers";
import Autocomplete from "react-google-autocomplete";
import Files from "react-files";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const userData = getUserData();
const defaultArtist = getLocalDataObject("defaultArtist");
Date.prototype.toDateInputValue = function () {
  var local = new Date(this);
  local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
};
Date.prototype.toDateInputValue30Days = function () {
  // var local = new Date().setDate(new Date().getDate() + 30);
  var local = new Date(this);
  local.setDate(local.getDate() + 30);
  local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
};
const initialState = {
  redirect: false,
  fields: {
    name: "",
    description: "",
    short_description: "",
    category: "",
    tags: "",
    video: "",
    campaign_end_method: "",
    start_date: new Date().toDateInputValue(),
    end_date: new Date().toDateInputValue30Days(),
    min_amount: "",
    max_amount: "",
    target_goal: "",
    predefined_amounts: 25,
    contributor_anonymous: "",
    datesStartEnd: [],
    lat: "",
    lng: "",
    address: "",
    selected_categories: "",
    street_address: "",
    city: "",
    state: "",
    zip: "",
    artist_ids: defaultArtist ? [defaultArtist.id] : null,
    event_type: "project",
    media_id: "",
  },
  profileFields: {
    fullname: "",
    avatar: "",
    hometown: "",
    about: "",
    stagename: "",
  },
  uploadFile: false,
  filePreview: false,
  userProfile: userData.userProfile,
  isLoggedIn: userData.isLoggedIn,
  defaultArtist: defaultArtist,
  myProjectsList: [],
  categoriesList: [],
  rewardValues: [],
  src: null,
  crop: {
    unit: "%",
    width: 30,
    aspect: 16 / 9,
  },
  cropModel: false,
};
export default class CreateProject extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }
  componentDidMount() {
    const defaultArtist = getLocalDataObject("defaultArtist");
    fetch(API_GET_CATEGORIES, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          this.setState({
            categoriesList: data.output.data,
          });
        }
      });
    this.setState({
      defaultArtist: defaultArtist,
    });
    if (defaultArtist) {
      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          artist_ids: [defaultArtist.id],
        },
      }));
    }

    fetch(API_USER_PROFILE, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          this.setState((prevState) => ({
            profileFields: {
              ...prevState.fields,
              first_name: data.data.profile.firstName,
              last_name: data.data.profile.lastName,
              username: data.data.userName,
              handle: data.data.userName,
              stage_name: data.data.userName,
              phone_1: data.data.profile.phone1,
              email: data.data.email,
              bio: "",
              hometown: "",
              user_id: data.data.id,
            },
            isLoading: false,
          }));
        }
      });
  }

  onOpenCropModal = () => {
    this.setState({ cropModel: true });
  };

  onCloseCropModal = () => {
    this.setState({ cropModel: false });
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result, cropModel: true })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  saveCropImage = () => {
    this.setState({ cropModel: false });
    const formData = new FormData();
    var file = new File([this.state.blobCropped], "uploaded_file.jpeg", {
      type: "image/jpeg",
      lastModified: Date.now(),
    });
    formData.append("file", file);

    fetch(API_UPLOAD_IMAGE, {
      method: "POST",
      body: formData,
    })
      .then((r) => r.json())
      .then((data) => {
        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            media_id: data.data[0].id,
          },
        }));
        console.log(this.state);
      });
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const blobCropped = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      const croppedImageUrl = window.URL.createObjectURL(blobCropped);
      this.setState({ croppedImageUrl, blobCropped });
    }
  }

  blobToFile = (theBlob, fileName) => {
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(),
      type: "image/jpeg",
    });
  };
  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          console.log(blob);
          resolve(blob);
        },
        "image/jpeg",
        1
      );
    });
  }

  onFilesChange = (files) => {
    console.log(files[0]);
    this.setState({ uploadFile: files[0], filePreview: files[0].preview.url });
    //upload media
    const formData = new FormData();
    var file = new File([this.state.blobCropped], "uploaded_file.jpeg", {
      type: "image/jpeg",
      lastModified: Date.now(),
    });
    console.log(this.state.croppedImageUrl);
    console.log(file);
    // formData.append("file", files[0]);
    formData.append("file", file);

    fetch(API_UPLOAD_IMAGE, {
      method: "POST",
      body: formData,
    })
      .then((r) => r.json())
      .then((data) => {
        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            media_id: data.data[0].id,
          },
        }));
        console.log(this.state);
      });
  };

  handleChangeFile = (files, i) => {
    console.log(files);
    this.setState({ uploadFile: files[0] });
    let rewardValues = this.state.rewardValues;
    rewardValues[i]["mediaPreview"] = files[0].preview.url;
    this.setState({ rewardValues });
    //upload media
    const formData = new FormData();
    formData.append("file", files[0]);

    fetch(API_UPLOAD_IMAGE, {
      method: "POST",
      body: formData,
    })
      .then((r) => r.json())
      .then((data) => {
        let rewardValues = this.state.rewardValues;
        rewardValues[i]["media_id"] = data.data[0].id;
        this.setState({ rewardValues });
        console.log(this.state.rewardValues);
      });
  };

  onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  handleChangeForm(i, e) {
    let rewardValues = this.state.rewardValues;
    rewardValues[i][e.target.name] = e.target.value;
    this.setState({ rewardValues });
    console.log(this.state.rewardValues);
  }
  addFormFields() {
    this.setState({
      rewardValues: [
        ...this.state.rewardValues,
        {
          title: "",
          description: "",
          noOfRewards: "",
          amount: "",
          delivery: "",
          media_id: "",
          mediaPreview: "",
        },
      ],
    });
  }
  removeFormFields(i) {
    let rewardValues = this.state.rewardValues;
    rewardValues.splice(i, 1);
    this.setState({ rewardValues });
  }

  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  handleChangeProfile = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      profileFields: {
        ...prevState.profileFields,
        [e.target.name]: value,
      },
    }));
  };

  handlePlaceChange = (place) => {
    console.log(place);
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        street_address: place.formatted_address,
        city: place.address_components[0].short_name,
        state: place.address_components[2].long_name,
        country: place.address_components[3].long_name,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      },
    }));
    console.log(this.state);
  };

  handleDescriptionChange = (value, editor) => {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        description: value,
      },
    }));
  };

  handleShortDescriptionChange = (value, editor) => {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        short_description: value,
      },
    }));
  };

  createProject = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      //
      let startDate = parseInt(
        (new Date(this.state.fields.start_date).getTime() / 1000).toFixed(0)
      );
      let endDate = parseInt(
        (new Date(this.state.fields.end_date).getTime() / 1000).toFixed(0)
      );
      const { fields, rewardValues } = this.state;
      fields.datesStartEnd[0] = startDate + ", " + endDate;
      this.setState(
        (prevState) => ({
          fields: {
            ...prevState.fields,
            datesStartEnd: fields.datesStartEnd,
            rewards: rewardValues,
          },
        }),
        () => {
          console.log(this.state.fields);
          fetch(API_CREATE_PROJECT, {
            method: "POST",
            body: JSON.stringify(this.state.fields),
          })
            .then((response) => response.json())
            .then((data) => {
              if (!data.success) {
                let errorList = data.error.fieldErrors;
                if (errorList) {
                  errorList.forEach((element) => {
                    ShowToast("error", element.message);
                  });
                  this.setState((prevState) => ({
                    isLoading: false,
                  }));
                } else {
                  ShowToast("error", "Something went wrong!");
                }
              } else {
                ShowToast("success", "Project Created");
                this.setState((prevState) => ({ initialState }));
                this.setState((prevState) => ({
                  redirect: "/mycampaigns",
                }));
              }
            });
        }
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  createArtist = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      //
      fetch(API_ARTIST_CREATE, {
        method: "POST",
        body: JSON.stringify(this.state.profileFields),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            let errorList = data.error.fieldErrors;
            if (errorList) {
              errorList.forEach((element) => {
                ShowToast("error", element.message);
              });
              this.setState((prevState) => ({
                isLoading: false,
              }));
            } else {
              ShowToast("error", "Something went wrong!");
            }
          } else {
            ShowToast("success", "Profile Completed");
            this.setState((prevState) => ({ initialState }));
            this.setState((prevState) => ({
              redirect: "/createproject",
            }));
          }
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const { crop, croppedImageUrl, src } = this.state;
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <>
        <Helmet>
          <title>Pickfunder | Create Project</title>
        </Helmet>

        <section className="header" id="header">
          <div className="back-img">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 offset-lg-1">
                  <div className="img-log-pcf pt-4 pt-lg-0">
                    <Link to="/">
                      <img src="/images/logo-2.png" alt="PickFunder" />
                    </Link>
                    <div className="img-log-pcf-text">
                      <h1>Create Your Project!</h1>
                      <div className="music-text-man">
                        <p>Just complete the form below to get your project live, then let your fans know where to find you by publishing your link on social media and via email!</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <Header homepage={true} />
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <div className="project">
          <div className="container">
            <div className="row">
              <div className="content">
                <h1>Create Your Project!</h1>
                <p>
                  Just complete the form below to get your project live, then
                  let your fans know where to find you by publishing your link
                  on social media and via email!
                </p>
              </div>
            </div>
          </div>
        </div>
        {this.state.defaultArtist ? (
          <section className="container">
            <form id="from-back">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-frame">
                    <label htmlFor="title">Title</label>
                    <input
                      type="text"
                      name="name"
                      value={this.state.fields.name}
                      onChange={this.handleChange}
                    />
                  </div>
                  <span className="error">
                    {this.validator.message(
                      "name",
                      this.state.fields.name,
                      "required"
                    )}
                  </span>
                </div>
              </div>
              <div className="form-frame">
                <label>Description</label>
                <Editor
                  initialValue="<p></p>"
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                  onEditorChange={this.handleDescriptionChange}
                />
                {/* <textarea
                  name="description"
                  id=""
                  cols="30"
                  rows="10"
                  value={this.state.fields.description}
                  onChange={this.handleChange}
                ></textarea> */}
              </div>
              <span className="error">
                {this.validator.message(
                  "description",
                  this.state.fields.description,
                  "required"
                )}
              </span>
              <div className="form-frame">
                <label>Short-Description</label>
                <Editor
                  initialValue="<p></p>"
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                  onEditorChange={this.handleShortDescriptionChange}
                />
                {/* <textarea
                  name="short_description"
                  id=""
                  cols="30"
                  rows="5"
                  value={this.state.fields.short_description}
                  onChange={this.handleChange}
                ></textarea> */}
              </div>
              <span className="error">
                {this.validator.message(
                  "short_description",
                  this.state.fields.short_description,
                  "required"
                )}
              </span>
              <div className="form-frame">
                <label htmlFor="">Category</label>
                <select name="selected_categories" onChange={this.handleChange}>
                  <option>Select Cateogry</option>
                  {this.state.categoriesList.map((category, i) => (
                    <option key={i} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
                <span className="error">
                  {this.validator.message(
                    "selected_categories",
                    this.state.fields.selected_categories,
                    "required"
                  )}
                </span>
                <div className="text">
                  <span>Select your campaign category</span>
                </div>
              </div>
              <div className="form-frame">
                <label htmlFor="tog">Tags</label>
                <input
                  type="text"
                  placeholder="Tag"
                  name="tags"
                  value={this.state.fields.tags}
                  onChange={this.handleChange}
                />
                <div className="text">
                  <span>Separate tags with commas eg: tag1,tag2</span>
                </div>
              </div>
              <div className="form-frame">
                <div className="row">
                  <label htmlFor="images">Feature Image</label>
                  <div className="col-md-12">
                    <div>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={this.onSelectFile}
                        style={{ padding: "8px" }}
                      />
                    </div>
                    {croppedImageUrl && (
                      <img
                        alt="Croppped Image"
                        style={{ maxWidth: "200px" }}
                        src={croppedImageUrl}
                      />
                    )}
                    <Modal
                      open={this.state.cropModel}
                      onClose={this.onCloseCropModal}
                    >
                      {this.state.src && (
                        <>
                          <ReactCrop
                            src={this.state.src}
                            crop={this.state.crop}
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                          />
                        </>
                      )}
                      <div className="text-center file-form ">
                        <a className="btn" onClick={this.saveCropImage}>
                          Crop Image
                        </a>
                      </div>
                    </Modal>

                    {/* <Files
                      className="files-dropzone"
                      onChange={this.onFilesChange}
                      onError={this.onFilesError}
                      accepts={["image/png", "image/jpeg", "image/jpg"]}
                      maxFileSize={10000000}
                      minFileSize={0}
                      clickable
                    >
                      Select Featured Image
                    </Files> */}
                  </div>
                  <div className="col-md-12">
                    {this.state.filePreview ? (
                      <>
                        <img
                          style={{ width: 200 }}
                          src={this.state.filePreview}
                        ></img>
                      </>
                    ) : null}
                  </div>

                  <div className="form-frame">
                    <span>Upload a campaign feature image</span>
                  </div>
                </div>
              </div>
              <div className="form-frame">
                <label htmlFor="">Video</label>
                <input
                  type="text"
                  name="video"
                  placeholder="https://"
                  value={this.state.fields.video}
                  onChange={this.handleChange}
                />
                <span>Put the campaign video URL here</span>
              </div>
              {/* <div className="form-frame">
                <label htmlFor="">Campaign End Method</label>
                <select
                  name="campaign_end_method"
                  id=""
                  value={this.state.fields.campaign_end_method}
                  onChange={this.handleChange}
                >
                  <option value={""}>Select Campaign End Method</option>
                  <option value="Target Goal" selected>
                    Target Goal
                  </option>
                  <option value="Target Data">Target Data</option>
                  <option value="Target Goal & Data">Target Goal & Data</option>
                  <option value="Campaign Never Emds">
                    Campaign Never Emds
                  </option>
                </select>
                <span>Choose the stage when campaign will end</span>
              </div>
              <span className="error">
                {this.validator.message(
                  "campaign_end_method",
                  this.state.fields.campaign_end_method,
                  "required"
                )}
              </span> */}
              <div className="form-frame">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="datemax">Start Date</label>
                    <input
                      type="date"
                      id="datemax"
                      name="start_date"
                      max=""
                      value={this.state.fields.start_date}
                      onChange={this.handleChange}
                    />
                    <span className="error">
                      {this.validator.message(
                        "start_date",
                        this.state.fields.start_date,
                        "required"
                      )}
                    </span>
                    <span>Campaign start date (dd/mm/yyyy)</span>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="datemax">End Date</label>
                    <input
                      type="date"
                      id="datemax"
                      name="end_date"
                      value={this.state.fields.end_date}
                      onChange={this.handleChange}
                    />
                    <span className="error">
                      {this.validator.message(
                        "end_date",
                        this.state.fields.end_date,
                        "required"
                      )}
                    </span>
                    <span>Campaign start date (dd/mm/yyyy)</span>
                  </div>
                </div>
              </div>
              {/* <div className="form-frame">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlF or="">Minimum Amount</label>
                    <input
                      type="number"
                      min=""
                      max=""
                      name="min_amount"
                      value={this.state.fields.min_amount}
                      onChange={this.handleChange}
                    />
                    <span className="error">
                      {this.validator.message(
                        "min_amount",
                        this.state.fields.min_amount,
                        "required"
                      )}
                    </span>
                    <span>Minimum campaign funding amount</span>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="">Maximum Amount</label>
                    <input
                      type="number"
                      min=""
                      max=""
                      name="max_amount"
                      value={this.state.fields.max_amount}
                      onChange={this.handleChange}
                    />
                    <span className="error">
                      {this.validator.message(
                        "max_amount",
                        this.state.fields.max_amount,
                        "required"
                      )}
                    </span>
                    <span>Maximum campaign funding amount</span>
                  </div>
                </div>
              </div> */}
              <div className="form-frame">
                <div className="row">
                  <div className="col-lg-6">
                    <label>Funding Goal</label>
                    <input
                      type="number"
                      min=""
                      max=""
                      name="target_goal"
                      value={this.state.fields.target_goal}
                      onChange={this.handleChange}
                    />
                    <span>Recommended campaign funding amount</span>
                  </div>
                  <div className="col-lg-6">
                    <label htmlFor="">Predefined Pledge Amount</label>
                    <input
                      type="text"
                      name="predefined_amounts"
                      value={this.state.fields.predefined_amounts}
                      onChange={this.handleChange}
                    />
                    <div className="text">
                      <span>
                        Predefined amount allow you to place the amount in
                        donate box by click, price should separated by comma
                        (,), example: <small> 25,50,100</small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="form-frame">
                <label>Location</label>
                <Autocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_API}
                  onPlaceSelected={(place) => this.handlePlaceChange(place)}
                />
                <span>Put the campaign location here</span>
              </div> */}
              <div className="checkbox">
                <h3>Contributor Anonymity</h3>
                <input
                  type="checkbox"
                  id="contributor_anonymous"
                  name="contributor_anonymous"
                  value={this.state.fields.contributor_anonymous}
                  onChange={this.handleChange}
                />{" "}
                <label htmlFor="contributor_anonymous">
                  Make contributors anonymous on the contributor table
                </label>
              </div>
              <h3>Rewards</h3>
              <hr />
              {this.state.rewardValues.map((element, index) => (
                <div className="row" key={index}>
                  <div className="col-md-12">
                    <div className="form-frame">
                      <label htmlFor="title">Reward Title</label>
                      <input
                        type="text"
                        name="title"
                        value={element.title || ""}
                        onChange={(e) => this.handleChangeForm(index, e)}
                      />
                    </div>
                    <span className="error">
                      {this.validator.message(
                        "title",
                        this.state.rewardValues[index].title,
                        "required"
                      )}
                    </span>
                  </div>
                  <div className="col-md-12">
                    <div className="form-frame">
                      <label htmlFor="title">Description</label>
                      <textarea
                        name="description"
                        value={element.description || ""}
                        onChange={(e) => this.handleChangeForm(index, e)}
                      ></textarea>
                    </div>
                    <span className="error">
                      {this.validator.message(
                        "description",
                        this.state.rewardValues[index].description,
                        "required"
                      )}
                    </span>
                  </div>
                  <div className="col-md-6">
                    <div className="form-frame">
                      <label htmlFor="title">No of Rewards</label>
                      <input
                        type="number"
                        name="noOfRewards"
                        value={element.noOfRewards || ""}
                        onChange={(e) => this.handleChangeForm(index, e)}
                      />
                    </div>
                    <span className="error">
                      {this.validator.message(
                        "noOfRewards",
                        this.state.rewardValues[index].noOfRewards,
                        "required"
                      )}
                    </span>
                  </div>
                  <div className="col-md-6">
                    <div className="form-frame">
                      <label htmlFor="title">Amount</label>
                      <input
                        type="number"
                        name="amount"
                        value={element.amount || ""}
                        onChange={(e) => this.handleChangeForm(index, e)}
                      />
                    </div>
                    <span className="error">
                      {this.validator.message(
                        "amount",
                        this.state.rewardValues[index].amount,
                        "required"
                      )}
                    </span>
                  </div>
                  <div className="col-md-6">
                    <div className="form-frame">
                      <label htmlFor="title">Delivery</label>
                      <input
                        type="date"
                        name="delivery"
                        value={element.delivery || ""}
                        onChange={(e) => this.handleChangeForm(index, e)}
                      />
                    </div>
                    <span className="error">
                      {this.validator.message(
                        "delivery",
                        this.state.rewardValues[index].delivery,
                        "required"
                      )}
                    </span>
                  </div>
                  <div className="col-md-6">
                    <div className="form-frame">
                      <label htmlFor="title">Media</label>
                      <Files
                        className="files-dropzone"
                        onChange={(e) => this.handleChangeFile(e, index)}
                        onError={this.onFilesError}
                        accepts={["image/png", "image/jpeg", "image/jpg"]}
                        maxFileSize={10000000}
                        minFileSize={0}
                        value={element.media_id || ""}
                        clickable
                      >
                        Select Featured Image
                      </Files>
                      {element.mediaPreview != "" ? (
                        <>
                          <img
                            style={{ width: 200 }}
                            src={element.mediaPreview}
                          ></img>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/* {index ? ( */}
                    <button
                      type="button"
                      className="btn btn-danger remove"
                      onClick={() => this.removeFormFields(index)}
                    >
                      Remove
                    </button>
                    {/* ) : null} */}
                  </div>
                </div>
              ))}
              <div className="button-section">
                <button
                  className="btn btn-primary add mt-4"
                  type="button"
                  onClick={() => this.addFormFields()}
                >
                  Add Rewards
                </button>
              </div>
              <div className="checkbox">
                <input type="checkbox" name="" />{" "}
                <label htmlFor="">
                  {" "}
                  I agree with the terms and conditions.
                </label>
              </div>
              <div className="file-form d-flex justify-content-between">
                <a href="#!">Cancel</a>
                <button className="btn" onClick={this.createProject}>
                  Create Project
                </button>
              </div>
            </form>
          </section>
        ) : (
          // Complete profile
          <section className="container">
            <h2 className="text-center">Complete your profile.</h2>
            <div className="row">
              <div className="col-md-12">
                <div className="form-frame">
                  <label htmlFor="title">First Name</label>
                  <input
                    type="text"
                    name="first_name"
                    value={this.state.profileFields.first_name}
                    onChange={(e) => this.handleChangeProfile(e)}
                  />
                </div>
                <span className="error">
                  {this.validator.message(
                    "first_name",
                    this.state.profileFields.first_name,
                    "required"
                  )}
                </span>
              </div>
              <div className="col-md-12">
                <div className="form-frame">
                  <label htmlFor="title">Last Name</label>
                  <input
                    type="text"
                    name="last_name"
                    value={this.state.profileFields.last_name}
                    onChange={(e) => this.handleChangeProfile(e)}
                  />
                </div>
                <span className="error">
                  {this.validator.message(
                    "last_name",
                    this.state.profileFields.last_name,
                    "required"
                  )}
                </span>
              </div>
              <div className="col-md-12">
                <div className="form-frame">
                  <label htmlFor="title">Handle</label>
                  <input
                    type="text"
                    name="handle"
                    readOnly
                    value={this.state.profileFields.username}
                    onChange={(e) => this.handleChangeProfile(e)}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-frame">
                  <label htmlFor="title">About</label>
                  <input
                    type="text"
                    name="bio"
                    value={this.state.profileFields.bio}
                    onChange={(e) => this.handleChangeProfile(e)}
                  />
                </div>
                <span className="error">
                  {this.validator.message(
                    "bio",
                    this.state.profileFields.bio,
                    "required"
                  )}
                </span>
              </div>
              <div className="col-md-12">
                <div className="form-frame">
                  <label htmlFor="title">Hometown</label>
                  <input
                    type="text"
                    name="hometown"
                    value={this.state.profileFields.hometown}
                    onChange={(e) => this.handleChangeProfile(e)}
                  />
                </div>
                <span className="error">
                  {this.validator.message(
                    "hometown",
                    this.state.profileFields.hometown,
                    "required"
                  )}
                </span>
              </div>
            </div>
            <div className="file-form d-flex justify-content-between">
              <a href="#!">Cancel</a>
              <button className="theme-btn" onClick={this.createArtist}>
                Complete Profile
              </button>
            </div>
          </section>
        )}

        <Footer />
      </>
    );
  }
}
