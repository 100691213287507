import React, { useState, useEffect, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { getUserData } from "../helpers/Storage";
import ContentLoader from "react-content-loader";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_KEY);
let userData = getUserData();

const GetCardList = (props) => {
  const { onCardClick } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [savedCards, setSavedCards] = useState([]);

  const getSavedCards = (e) => {
    console.log("getsavedcards");
    setIsLoading(true);
    fetch(
      "https://api.stripe.com/v1/customers/" +
        userData.user.customer_stripe_id +
        "/sources" +
        "?object=card",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: process.env.REACT_APP_STRIPE_SK_KEY,
          Authorization: "Bearer " + process.env.REACT_APP_STRIPE_SK_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("cards", data);
        if (!data.error) {
          setSavedCards(data.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
  };
  const selectCard = (e, id) => {
    console.log("select card", id);
    onCardClick(id);
  };
  useEffect(() => {
    getSavedCards();
  }, []);
  return (
    <div className="row">
      {isLoading ? (
        <>
          <>
            <ContentLoader
              speed={2}
              width={800}
              height={500}
              viewBox="0 0 800 500"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
              <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
              <rect x="0" y="56" rx="3" ry="3" width="607" height="9" />
              <rect x="0" y="80" rx="3" ry="3" width="562" height="9" />
              <circle cx="20" cy="20" r="20" />
              <rect x="608" y="202" rx="3" ry="3" width="607" height="9" />
              <rect x="608" y="178" rx="3" ry="3" width="562" height="9" />
              <rect x="608" y="155" rx="3" ry="3" width="263" height="9" />
              <rect x="-5" y="128" rx="3" ry="3" width="607" height="9" />
              <rect x="-5" y="152" rx="3" ry="3" width="502" height="9" />
              <rect x="-5" y="175" rx="3" ry="3" width="600" height="9" />
              <rect x="1" y="105" rx="3" ry="3" width="450" height="9" />
              <rect x="-1" y="204" rx="3" ry="3" width="607" height="9" />
              <rect x="-1" y="228" rx="3" ry="3" width="562" height="9" />
              <rect x="-6" y="276" rx="3" ry="3" width="607" height="9" />
              <rect x="-6" y="300" rx="3" ry="3" width="502" height="9" />
              <rect x="-6" y="323" rx="3" ry="3" width="600" height="9" />
              <rect x="0" y="253" rx="3" ry="3" width="450" height="9" />
            </ContentLoader>
          </>
        </>
      ) : (
        savedCards.map((card, index) => (
          <div className="col-md-12">
            <div
              className={`credit-card-inline selectable ${card.brand.replace(
                /\s+/g,
                "-"
              )}`}
              key={index}
              onClick={(e) => selectCard(e, card.id)}
            >
              <div className="credit-card-inline-last4">{card.last4}</div>
              <div className="credit-card-inline-expiry">
                {card.exp_month}/{card.exp_year}
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};
export default GetCardList;
